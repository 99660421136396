import React, { useEffect, useState } from 'react';
import { getAllDBDetails } from '../../../models/DatabaseAccess'
import IMLoader from '../../common/IMLoader'

// import PGUsersAccess from './PGUsersAccess'
// import PGUserAccess from './PGUserAccess'
import DBConfigs from './DBConfigs'
import MyDBConfigs from './MyDBConfigs'
import LimitedPermissionPage from '../../common/LimitedPermissionPage'
import Paper from '@mui/material/Paper';
// import DBUser from './DBUser'


import AllDBUsers from './AllDBUsers'
import PGUser from './PGUser'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';

export default function MainContainer(props) {


  const [ dbs, setDbs ] = useState(null)
  const [ allDbs, setAllDbs ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ tab, setTab ] = useState('1')
  const [ read, setRead ] = useState(true)
  const [ readAll, setReadAll ] = useState(true)

  const [ editDB, setEditDB ] = useState(false)
  const [ dbDetails , setDbDetails ] = useState({})
  const [ userDbDetailsAttr, setUserDbDetailsAttr ] = useState([])
  const [ userRows, setUserRows ] = useState(null)
  const [ dbRoles, setDbRoles ] = useState([])
  const [ dbUsername, setDbUsername ] = useState(null)
  const [ refresh, setRefresh ] = useState(false)
  const [ dbsOptions, setDbsOptions ] = useState([])

  const [ allRoles, setAllRoles ]= useState(null);
  const [ dbPGRoles, setDbPGRoles ]= useState(null);
  const onFetchDB = ({ updatedDbs, dbsOptions }) => {
    setAllDbs(updatedDbs)
    setRefresh(!refresh)
    setDbsOptions(dbsOptions)
  }


  const handleChangeTab = ( event, newValue ) => {
    setTab(newValue);
  };


  const processDbsServerResponse = (serverDbs) => {

    let dbsProcessed = []
    let name = ''
    let roles = []
    setAllDbs(serverDbs)


    let allRoles2 = []
    let dbPGRoles2 = {}

    serverDbs.forEach((db) => {
      name = db.dbName
      roles = db.dbRoles
      if (!dbPGRoles2[name]) {
        dbPGRoles2[name] = []
      }
      if (!dbsProcessed.includes(name)){
        dbsProcessed.push(name)
      }
      roles.forEach((role) => {
        if (!allRoles2.includes(role)) {
          allRoles2.push(role)
        }
        dbPGRoles2[name].push(role)
      })

    })
    setDbs(dbsProcessed)
    setDbPGRoles(dbPGRoles2)
    setAllRoles(allRoles2)
    setDbsOptions((dbsProcessed).map((db) => ({id: `${db}Id`, value: `${db}`, groupBy: '' , option: db })))
  }

  const fetchDBs = async () => {
    if (!read) {
      return
    }
    setLoading(true)
    try {
      const response = await getAllDBDetails({ detailed: false })
      if (response && response.status === 200 && response.dbs) {
        console.log('response', response)
        processDbsServerResponse(response.dbs)

        setRead(response.read)
        setDbRoles(response.roles)
        setEditDB(response.edit)
        setUserDbDetailsAttr(response.userDbDetailsAttr)

      } else {
        setRead(false)
        if (response.status === 401) {
          setRead(false)
        } else {
          props.unAuthCallback(response)

        }
      }
      setLoading(false)
    } catch (e) {
      setRead(false)
      setLoading(false)

    }

  }

  useEffect(() => {
    if (!dbs ) {
      fetchDBs()
    }
  })

  return (
    <>
     {loading
      ? <IMLoader message={'Loading...'} />
      : dbs && read && !props.currentUser.disabled
        ?
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="permissions tabs">
                <Tab label="MY DB PERMISSIONS" value="1" sx={{fontWeight: 900}}/>

                {editDB && <Tab label="DB CONFIGS" value="4"sx={{fontWeight: 900}} />}
                {readAll && <Tab label="PERMISSIONS BY DB" value="5" sx={{fontWeight: 900}} />}
                {readAll && <Tab label="PERMISSIONS BY USER" value="6" sx={{fontWeight: 900}} />}

              </TabList>
            </Box>
            <TabPanel value="1">
            <PGUser
                env={props.env}
                dbs={dbs}
                dbsOptions={dbsOptions}
                users={props.users}
                allDbs={allDbs}
                unAuthCallback={props.unAuthCallback}
                refresh={refresh}
                currentUser={props.currentUser}
                allRoles={allRoles}
                dbRoles={dbPGRoles}
                myConfigs
                userDbDetailsAttr={userDbDetailsAttr}
                me
              />
            </TabPanel>


          {editDB &&   <TabPanel value="4">
              <DBConfigs
                env={props.env}
                dbDetails={dbDetails}
                dbs={dbs}
                allDbs={allDbs}
                dbRoles={dbRoles}
                onFetchDB={onFetchDB}
                onUpdateDb={processDbsServerResponse}
                unAuthCallback={props.unAuthCallback}
                refresh={refresh}
                dbsOptions={dbsOptions}
              />
            </TabPanel>}
            {<TabPanel value="5">
              <AllDBUsers
                  env={props.env}
                  dbs={dbs}
                  dbsOptions={dbsOptions}
                  users={props.users}
                  allDbs={allDbs}
                  unAuthCallback={props.unAuthCallback}
                  refresh={refresh}
                  currentUser={props.currentUser}
                />
            </TabPanel>}
            {<TabPanel value="6">
              <PGUser
                  env={props.env}
                  dbs={dbs}
                  dbsOptions={dbsOptions}
                  users={props.users}
                  allDbs={allDbs}
                  unAuthCallback={props.unAuthCallback}
                  refresh={refresh}
                  currentUser={props.currentUser}
                  allRoles={allRoles}
                  dbRoles={dbPGRoles}
                  userDbDetailsAttr={userDbDetailsAttr}
                />
            </TabPanel>}
          </TabContext>
        </Box>

      : <Box sx={{ '& .MuiPaper-root': {
                      backgroundColor: 'white'} }}>
            <LimitedPermissionPage
              user={props.currentUser.name}/>
          </Box>
    }


    </>
  )

}
