import React, { useState } from 'react';
// import Alert from '@material-ui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';


export default function IMAlerts(props) {
  const [ message, setMessage ] = useState(props.message);

  const onCloseAlert = () => {
    setMessage('');
    if (props.onClose) {
      props.onClose();
    }
  }


  return (
    <>{message &&  <Snackbar
      open={true}
      key={`transition${props.severity}`}
      onClose={onCloseAlert}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{marginTop: 10}}>
           <Alert  severity={props.severity}>{props.message}</Alert>
      </Snackbar>}</>
  );
}
