import axios from 'axios';
import { getHeaders, baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export async function getApplicationsCall() {
  try {
    const response = await axios.get(`${baseUrl}/im_apps/applications`,{
       method: "GET",
       withCredentials: true,
       headers: getHeaders(),
       });
    return response;
  } catch (err) {
    return { err };

  }
}

export async function getApplicationUsersCall(applicationName) {
  try {
    const response = await fetch(`${baseUrl}/im_apps/applications_users/${applicationName}`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify({
         internal_permissions: true
       })
       });
       const responseBack = await response.json()
       return responseBack
  } catch (err) {
    return { err };

  }
}

export async function editAppUserCall(data) {
  try {
    const response  = await fetch(`${baseUrl}/im_apps/edit_application_user`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function addAppUserCall(data) {
  try {
    const response  = await fetch(`${baseUrl}/im_apps/new_application_user`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
