export function toCamelCase(word) {
	return word.toLowerCase().replace(/(_\w)/g, m => m.toUpperCase().substr(1))
}


export function camelToUnderscore(key) {
	return key.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase())
}

export function processParamsToServer(params) {
  let paramsToServer = {}
  Object.keys(params).forEach((key) => {
    paramsToServer[camelToUnderscore(key)] = params[key]
  })
  return paramsToServer;
}

export function processResponse(pgUserResponse) {

  let user = {}
  try {
    if (!pgUserResponse || pgUserResponse == null ||
			typeof pgUserResponse === 'number' ||
					 typeof pgUserResponse === 'string' ||
						 typeof pgUserResponse === 'boolean') {
      return pgUserResponse
    }
    if (Array.isArray(pgUserResponse)) {
      user = []
      pgUserResponse.forEach((item) => {
        if (typeof item === 'object') {
          user.push(processResponse(item))
        } else {
          user.push(item)
        }
      })
    } else {
			if (!pgUserResponse || !Object.keys(pgUserResponse) || Object.keys(pgUserResponse).length === 0 ) {
				return pgUserResponse
			} else {

				Object.keys(pgUserResponse).forEach((key) => {

					if ( typeof pgUserResponse[key] === 'number' ||
								typeof pgUserResponse[key] === 'string' ||
									typeof pgUserResponse[key] === 'boolean') {
							if (typeof key !== 'number' && isNaN(key)) {
								user[toCamelCase(key)] = pgUserResponse[key]
							}
						} else {
							if (key) {
								user[toCamelCase(key)] = processResponse(pgUserResponse[key])

							}
						}
				})
			}

    }
    return user;
  } catch (err) {
    console.log('ERROR ???? ', err)
     return user;
  }
}

export function processComplexResponse(pgUserResponse) {
  let user = {}
  try {
    if (!pgUserResponse || pgUserResponse == null ||
			typeof pgUserResponse === 'number' ||
					 typeof pgUserResponse === 'string' ||
						 typeof pgUserResponse === 'boolean') {
      return pgUserResponse
    }
    if (Array.isArray(pgUserResponse)) {
      user = []
      pgUserResponse.forEach((item) => {
        if (typeof item === 'object') {
          user.push(processComplexResponse(item))
        } else {
          user.push(item)
        }
      })
    } else {
			if (!Object.keys(pgUserResponse) || Object.keys(pgUserResponse).length === 0 ) {
				return pgUserResponse
			} else {
				Object.keys(pgUserResponse).forEach((key) => {

					if ( typeof pgUserResponse[key] === 'number' ||
								typeof pgUserResponse[key] === 'string' ||
									typeof pgUserResponse[key] === 'boolean') {
							if (typeof key !== 'number' && isNaN(key)) {
								user[toCamelCase(key)] = pgUserResponse[key]
							}
						} else {
							if (key) {
								user[toCamelCase(key)] = processComplexResponse(pgUserResponse[key])

							}
						}
				})
			}

    }
    return user;
  } catch (err) {
    console.log('ERROR ???? ', err)
     return user;
  }
}
