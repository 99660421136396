import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function IMConfirmDialog(props) {
  const [open, setOpen] = React.useState(props.openDialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth, setMaxWidth] = React.useState('lg');

  const handleClickOpen = () => {
    setOpen(true);

  };

  const handleConfirm = () => {
    setOpen(false);
    if (props.handleConfirm) {
      props.handleConfirm()
    }
  }

  const handleCancel = () => {
    setOpen(false);
    if (props.handleCancel) {
      props.handleCancel()
    }
  };

  return (
    <div>
      {props.showOpenDialogBtn && <Button variant="outlined" onClick={handleClickOpen}>
        {props.openDialogBtnTitle || 'Confirm'}
      </Button>}
      <Dialog
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
        sx={{
          backgroundColor: '#24292e',
          color: 'white'

        }}
        id="responsive-dialog-title">
          {props.title || 'Are you sure?'}
        </DialogTitle>
        <DialogContent sx={{margin: 'inherit'}} dividers>
          <DialogContentText>
            {props.description || ''}
          </DialogContentText>
          {props.content || ''}
        </DialogContent>
        <DialogActions>
          <Button variant={props.cancelBtnVariant || 'contained'}
            color={props.cancelBtnColor || 'secondary'} autoFocus onClick={handleCancel}>
            {props.cancelText || 'Cancel'}
          </Button>
          <Button
          variant={props.confirmBtnVariant || 'contained'}
              color={props.confirmBtnColor || 'primary'}
              sx={{height: '42px'}}
              onClick={handleConfirm} autoFocus>
            {props.confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
