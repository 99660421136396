

export const getHeaders = (csrfToken) => {

  const headers = {
    // 'X-CSRF-Token': csrfToken,
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
    "Content-Security-Policy": "frame-ancestors 'self';",
    'X-Frame-Options': 'sameorigin',
    'X-Content-Type-Options': 'nosniff',
    'credentials': 'include',
    // 'Authorization': localStorage.getItem("token"),
    'Authorization': sessionStorage.getItem("token"),

  }

  return headers
}


export const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005'
