import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { grey, orange, green, blueGrey, teal, red, yellow, purple, pink, deepPurple, indigo, amber, lightBlue, cyan, blue } from '@mui/material/colors';

import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    paperScrollPaper: {
      background: 'transparent',
       boxShadow: 'none'
    },
    messageStyled: {
      fontWeight: '700',
      color: indigo[900],
      fontSize: '20px'
    }
}));
export default function IMLoader(props) {



const classes = useStyles();
  return (
    <>
  { props.mini
    ?  <div className={classes.root}>
         <CircularProgress color={indigo[200]}/>
         <div className={classes.messageStyled}>{props.message || 'Loading...'}</div>
     </div>
    : <Dialog
      open={true}
      disableBackdropClick
      aria-labelledby="simple-dialog-title"
      classes={ { paperScrollPaper: classes.paperScrollPaper }}
      >
        <DialogContent>
         <div className={classes.root}>
           <CircularProgress color={indigo[200]}/>
           <div className={classes.messageStyled}>{props.message}</div>
         </div>
       </DialogContent>
     </Dialog>}
    </>

  )
}
