import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { AWS_ENVS } from '../../common/constants'
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

export default function NewEditDBForm(props) {

  const [ keyName, setKeyName ] = useState('')
  const [ env, setEnv ] = useState(props.env)
  const [ dbName, setDbName ] = useState('')
  const [ roles, setRoles ] = useState([])
  const [ defaultRoles, setDefaultRoles ] = useState([])
  const [ refresh, setRefresh ] = useState(false)

  const ENV_SECRET_MAPPING = {
    development: 'dev',
    staging: 'stage',
    production: 'prod',
    cloudly: 'cloudly'
  }

  const generateSecret = ({currentDbName}) => {

    const validKeyName = `${ENV_SECRET_MAPPING[env]}/database/${currentDbName}/dbuser/im_permissions`
    setKeyName(validKeyName)
    props.dbNameChange(validKeyName)

  }

  const roleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const filtered = props.dbRoles.filter((role) =>  value.includes(role.userRole) )
    props.newRolesSync(filtered)
  };
  const dbNameChange = (event) => {
    const val = event.target.value
    setDbName(val)
    generateSecret({ currentDbName: val })

  }

  const defaultRole = (role) => () => {
    let newDefRoles = defaultRoles
    if (newDefRoles.includes(role)) {
      newDefRoles = newDefRoles.filter((roleDef) => roleDef !== role)
    } else {
      newDefRoles.push(role)
    }
    setRefresh(!refresh)
    setDefaultRoles(newDefRoles)
    props.defaultRoles(newDefRoles)
  }


  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={5}
      sx={{width: 500, height: 500}}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{display: 'flex', alignItems: 'end'}}>
          <FormControl variant="standard">
            <TextField
               disabled
               key={'keyNameEnv'}
               id="keyName-required-env"

               value={env}
               variant="standard"

             />
           <FormHelperText id="component-helper-text">
           Environment
         </FormHelperText>
         </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
              variant="standard">
            <InputLabel id="env-id-dd">PG Roles</InputLabel>
            <Select
              labelId="pgroles-id-label"
              id="pgroles-id-label-helper"
              key="pgroles-key-label-helper"
              value={roles}
              label="PG Roles"
              onChange={roleChanged}
              sx={{minWidth: '100%'}}
              multiple
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {roles.map((value) => (
                    <Chip key={value} label={value} onClick={defaultRole(value)}/>
                  ))}
                </Box>
          )}
            >
              {
               props.dbRoles.map((role) =>
                 <MenuItem
                   key={role.id}
                   value={role.userRole}
                   name={role.id}
                 >
                 {`${role.userRole} - ${role.description}`}
                 </MenuItem>
               )
              }
            </Select>
            <FormHelperText id="component-helper-text1">
            At least one pg role must be assigned
          </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>


       <TextField
          required
          key={'dbname'}
          id="dbname-required"
          label="DB Name"
          variant="standard"
          onChange={dbNameChange}
        />
        <FormControl variant="standard">
        <TextField
           disabled
           key={'keyName'}
           id="keyName-required"

           value={keyName}
           variant="standard"
           sx={{color: "green", fontWeight: 700}}
         />
         <FormHelperText id="component-helper-text">
         AWS Secret Key Name
       </FormHelperText>
       </FormControl>
      <FormControl variant="standard">
       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
         {roles.map((value) => (
           <Chip key={value} label={value} onClick={defaultRole(value)}

           color="primary" variant={defaultRoles.includes(value) ? "" : "outlined"}/>
         ))}
       </Box>
       <FormHelperText id="component-helper-text">
         Mark the default role permissions a new user can be granted
       </FormHelperText>
     </FormControl>
    </Stack>

  )
}
