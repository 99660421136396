import axios from 'axios';
import { getHeaders, baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;


export async function updatePermissions({ newPermissions }) {
  try {
    const data = { updated_permissions: newPermissions }

    const response  = await fetch(`${baseUrl}/user_access/update_users_ip_permissions/`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { err };

  }
}
export async function fetchUserPermissionsCall() {
  try {

    const responseBack = await axios.get(`${baseUrl}/user_access/user_ip_permissions/`,{
       method: "GET",
       withCredentials: true,
         headers: getHeaders(),
       });

    return responseBack
  } catch (err) {

    return { err, status: 401 };

  }
}
export async function assumeRoleCall ({ role }) {
  try {
    const data = { role }

    const response  = await fetch(`${baseUrl}/user_access/users/assume_current_user_role/`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}


export async function addUserCall({ email, first_name, last_name, role }) {
  try {

    const data = { email, first_name, last_name, role }

    const response  = await fetch(`${baseUrl}/user_access/users/new`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()

    return responseBack

  } catch (err) {
    return { err };

  }
}

export async function deleteUserCall({ id }) {
  try {

    const data = { id }

    const response  = await fetch(`${baseUrl}/user_access/users/delete`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()

    return responseBack

  } catch (err) {
    return { err };

  }
}

export async function editUserCall({ id, email, ip_address, first_name, last_name, role, disabled, avatar }) {
  try {

    const data = { id, email, ip_address, first_name, last_name, role, disabled }
    const formData = new FormData();
    formData.append("avatar", avatar);
    formData.append("user_id", id);
    const formHeaders = {
      "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
      "Content-Security-Policy": "frame-ancestors 'self';",
      'X-Frame-Options': 'sameorigin',
      'X-Content-Type-Options': 'nosniff',
      'credentials': 'include',
    }
    const response  = await fetch(`${baseUrl}/user_access/users/edit/${id}`,{
       method: "POST",
       credentials: 'include',
       headers: avatar ? formHeaders : getHeaders(),
       body: avatar ? formData : JSON.stringify(data)
      }
    )
    const responseBack = await response.json()
    return responseBack

  } catch (err) {
    return { err };

  }
}

export async function getUsersCall() {
  try {
    const response = await fetch(`${baseUrl}/user_access/users`, {

      credentials: 'include',
      headers: getHeaders()

       });
       const responseBack = await response.json()

    return responseBack;
  } catch (err) {
    return { err };

  }
}
