import React, { useEffect, useState } from 'react';
import PGUser from './PGUser'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import { AwesomeButton } from 'react-awesome-button';
import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import ReactiveButton from 'reactive-button';

const STRONG_PASS = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const MEDIUM_PASS = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

const STRONG_PASS_DESCRIPTION = {
  '^(?=.*[a-z])': 	"The string must contain at least 1 lowercase alphabetical character",
  '(?=.*[A-Z])':	"The string must contain at least 1 uppercase alphabetical character",
  '(?=.*[0-9])':	"The string must contain at least 1 numeric character",
  '(?=.*[!@#$%^&*])':	"The string must contain at least one special character",
  '(?=.{8,})':	"The string must be eight characters or longer"
}
export default function MyDBConfigs(props) {

  const [ validPassword, setValidPassword ] = useState(Object.keys(STRONG_PASS_DESCRIPTION).map((key) => STRONG_PASS_DESCRIPTION[key]).join(','))
  const [ validConfirmPassword, setValidConfirmPassword ] = useState(false)
  const [ validPasswordHelperText, setValidPasswordHelperText ] = useState('')
  const [ confirmPassValue, setConfirmPassValue ] = useState('')
  const [ confirmPasswordHelperText, setConfirmPasswordHelperText ] = useState('')

  const [ newPassword, setNewPassword ] = useState('')

  useEffect(() => {


  })


  const updatePassword = async () => {
    if (!validConfirmPassword) {
      return;
    }

    setNewPassword('')
    setConfirmPassValue('')
    setValidConfirmPassword(false)
    setValidPasswordHelperText('')
    setConfirmPasswordHelperText('')

    props.onUpdatePassword({ user: props.currentUser, password: confirmPassValue })
  }

  const confirmPasswordChanged = (newConfirmedPassEvent) => {
    const newConfirmedPasswordUpdate = newConfirmedPassEvent.target.value
    const valid = newPassword && newConfirmedPasswordUpdate === newPassword

    setValidConfirmPassword(valid)
    setConfirmPassValue(newConfirmedPasswordUpdate)
    setConfirmPasswordHelperText(valid ? '' : 'Must Match a Valid Password')
  }

  const passwordChanged = (newPasswordEvent) => {
    const newPasswordUpdate = newPasswordEvent.target.value
    let validHelperText = []


    Object.keys(STRONG_PASS_DESCRIPTION).forEach((condition, i) => {
      if (new RegExp(condition).test(newPasswordUpdate)) {
        if (validHelperText.includes(STRONG_PASS_DESCRIPTION[condition])) {
          validHelperText.remove(STRONG_PASS_DESCRIPTION[condition])
        }
      } else {
        if (!validHelperText.includes(STRONG_PASS_DESCRIPTION[condition])) {
          validHelperText.push(STRONG_PASS_DESCRIPTION[condition])
        }
      }

      setNewPassword(newPasswordUpdate)
      setValidPasswordHelperText(validHelperText.join(', '))
      setConfirmPassValue('')
    });

  }



  return (
    <>
     {<Box sx={{
            '& .MuiPaper-root': {
            backgroundColor: 'white'}
          }}>
          <Paper elevation={3} sx={{width: '550px', display: 'flex'}}>

          <Box sx={{  }}>

            <Grid container spacing={8} sx={{alignItems: 'left', margin: '5px'}}>
             <Grid item xs={11}>
              <Stack spacing={6}>
                <Stack spacing={6}>
                {props.currentUser.dbPasswordExpirationDate &&
                  <FormControl variant="standard" sx={{ m: 8, minWidth: 120 }}>
                   <Typography variant="span" noWrap component="div" sx={{ flexGrow: 1 }}>
                       {`${new Date(props.currentUser.dbPasswordExpirationDate) < new Date() ? 'Password Expired on' : 'Password Expiration Date'}: `}
                     </Typography>
                     <Typography variant="span" noWrap component="div" sx={{ flexGrow: 1, width: '440px', textAlign: 'right' }}>
                       {`${new Date(props.currentUser.dbPasswordExpirationDate).toDateString()}`}
                     </Typography>
                  </FormControl>
               }
                  <FormControl variant="standard" sx={{ m: 8, minWidth: 120 }}>
                     <InputLabel
                     sx={{
                        display: 'flex',
                        position: 'inherit',
                        justifyContent: 'center',
                     }}
                     variant="standard"
                     id="role-action-label"></InputLabel>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 2, maxWidth: 250 }}>

                   <TextField
                       required
                       key={'password'}
                       id="Password-required"
                       label="Password"
                       variant="standard"
                       onChange={passwordChanged}
                       error={validPassword !== ''}
                       type="password"
                       helperText={validPasswordHelperText}
                     />
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 2, maxWidth: 250 }}>

                     <TextField
                         required
                         key={'confirmPassowrd'}
                         id="Confirm-Password-required"
                         label="Confirm Password"
                         variant="standard"
                         onChange={confirmPasswordChanged}
                         error={validConfirmPassword !== ''}
                         value={confirmPassValue}
                         type="password"
                         helperText={confirmPasswordHelperText}
                       />
                  </FormControl>

                </Stack>
                <FormControl variant="standard" sx={{ m: 8, alignItems: 'end', minHeight: 200, justifyContent: 'center' }}>

                 <ReactiveButton
                  key={`updatePasswordBtn`}
                  buttonState={'idle'}
                  onClick={updatePassword}
                  idleText={
                    'Update Password'
                   }
                  type={'button'}
                  style={{
                    borderRadius: '5px',
                    width: 'fit-content',

                  }}

                  shadow={true}
                  rounded={false}
                  size={'normal'}
                  block={false}
                  messageDuration={2000}
                  disabled={!validConfirmPassword || !validPassword}
                  buttonRef={null}
                  width={null}
                  height={null}
                  animation={true}
                  outline={!validConfirmPassword || !validPassword}
                  color={'teal'}
                />
               </FormControl>
              </Stack>
             </Grid>

          </Grid>
         </Box>
        </Paper>
      
      </Box>
    }
    </>
  )

}
