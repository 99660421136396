import React, { useEffect, useState } from 'react';
import { editAppUser } from '../../models/ApplicationAccess'
import styled from 'styled-components'
// import ExtendedTable from '../common/ExtendedTableComp/ExtendedTable'
import LimitedPermissionPage from '../common/LimitedPermissionPage'
import IMAlerts from '../common/IMAlerts'
import IMCard from '../common/IMCard'
import IMDataGrid from '../common/IMDataGrid'
import IMConfirmDialog from '../common/IMConfirmDialog'
import IMLoader from '../common/IMLoader'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
} from '@mui/x-data-grid';

const Styles = styled.div`
  padding: 1rem;

  .MuiPaper-root {
    background-color: #fff !important
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

`

export default function UsersApps(props) {

  const [ currentUser, setCurrentUser ] = useState(props.currentUser)
  const [ refreshDialog, setRefreshDialog ] = useState(false)
  const [ edit, setEdit ] = useState(props.edit)
  const [ editAll, setEditAll ] = useState(props.editAll)
  const [ users, setUsers ] = useState(props.users)
  const [ applicationId, setApplicationId ] = useState(props.applicationId)
  const [ applicationName, setApplicationName ] = useState(props.applicationName)
  const [ errorMessage, setErrorMessage ] = useState(false)
  const [ refreshToggle, setRefreshToggle ] = useState(false)
  const [ confirmModal, setConfirmModal ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ userFormDialogDescription, setUserFormDialogDescription ] = useState({})
  const [ forceClose, setForceClose ]  = useState(false)
  const [ editCurrentUser, setEditCurrentUser ] = useState(null)


  const [ rowModesModel, setRowModesModel ] = useState({});
  const [ rows, setRows ] = useState(props.users);

  const userFields = () => {
    return ['role', 'userName', 'email', 'countryRoleAccess']
  }

  const newUserDefaults = () => {
    return {
      role: '',
      userName: '',
      email: '',
      countryRoleAccess: []
    }
  }



  const onFieldChange = (field, value, rowEdit) => {
    let rowEditValuesUpdated = Object.assign({}, rowEdit)

    rowEditValuesUpdated[field] = value
    return rowEditValuesUpdated
  }

  const getAppRoles = () => {
    let roles =  !props.appRoles ? [] : props.appRoles.map((role) => role.role )
    return roles;
  }

  const labelOption = (roles) => roles.map((role) => role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() ).join(' ')

  const lookupOptions = () => {
    return {
      role: props.appRoles.map((role) => ({ label: labelOption(role.role.split('_')), value: role.role})),
      countryRoleAccess: [
      { value: 'us', label: 'United States', disabled: false, icon: '🇺🇸' },
      { value: 'ca', label: 'Canada', disabled: false, icon: '🇨🇦' },
      { value: 'au', label: 'Austrelia', disabled: false, icon: '🇦🇺' } ]
    }
  }


  useEffect(() => {
    if (props.applicationId !== applicationId || applicationName !== props.applicationName || props.refresh !== refreshToggle && (props.users !== undefined)) {
      setRefreshToggle(props.refresh)
      setUsers(props.users)
      setApplicationId(props.applicationId)
      setApplicationName(props.applicationName)

    }
  })

  const openUserDialog = async (row) => {

    const { email } = row
    if (!users) {
      setUsers(props.users)
    }
    const user = props.users.filter((u) => u.email === email)[0]
    return user
  }

  const onAddRow = async (params) => {
    if (props.onAddAppUser) {
      console.log('ON NEW ', params)
      props.onAddAppUser(params)
    }
  }
  const onDelete = (user) => {

    // const handleConfirmDeleteUser = async () => {
    //   setLoading(true)
    //
    //   const response = await deleteUser({ id: user.id, token })
    //
    //   const message = response.error ? response.error : response.success
    //
    //   if (response.success && response.users) {
    //     setUsers(response.users)
    //   }
    //
    //   setAlertMessage(message)
    //   setErrorMessage(response.error)
    //   setRefreshToggle(!refreshToggle)
    //   setConfirmModal({})
    //   setLoading(false)
    // }
    // setConfirmModal({
    //    title: `Delete user ${user.firstName} ${user.lastName}`,
    //    description: `Are you sure?`,
    //    handleConfirm: handleConfirmDeleteUser
    // })

  }

  const editUserRow =  async (editUserAppParams) => {
// { id, email, name, role, disabled, restrictedRole, countryRoleAccess, applicationId, applicationName, personalInformationRestriction }
    editUserAppParams['applicationName'] = applicationName

    if (props.oEditAppUser) {
      const response = await props.oEditAppUser(editUserAppParams)
      const message = response.error ? response.error : response.success
      return { response, message }
    }
    // const response = await editAppUser(editUserAppParams)
    //
    // const message = response.error ? response.error : 'Edit User successfully'
    //
    // return { message, ...response  }
  }



  const handleCancelConfirm = () => {
    setConfirmModal({})
  }
  const ignoreRowColumns = () => {
    return ['id']
  }


  const handleEditClick = (id) => () => {
    const current = props.users.filter((user) => user.id === id)[0]
    setEditCurrentUser(current)
    // setEditDialogOpen(true)

    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const columns = [
    { field: 'userName', align: 'left',headerName: 'Name', width: 180, editable: false },
    { field: 'role', align: 'left',headerName: 'Role', width: 150, editable: false },
    { field: 'disabled', align: 'left',headerName: 'Disabled', width: 100, editable: false },
    { field: 'email', align: 'left',headerName: 'Email', width: 250, editable: false },
  ];
  return (
    <Styles >

      <Box
        sx={{ display: 'block', height: '100%', width: '100%',
        display: 'block',
        float: 'left' }}
      >

      {confirmModal && Object.keys(confirmModal).length > 0 &&
        <IMConfirmDialog
        openDialog={true}
        title={confirmModal.title}
        description={confirmModal.description}
        handleConfirm={confirmModal.handleConfirm}
        handleCancel={handleCancelConfirm}
        confirmText={'Yes'}
        cancelText={'Dismiss'}
      />}
      <React.StrictMode>
        {
          loading ? <></>
          : <IMDataGrid
              rows={users}
              currentUser={props.currentUser}
              users={users}
              editAll={editAll}
              columns={columns}
              actions={true}
              ipermRoles={getAppRoles()}
              refresh={refreshToggle}
              color={props.color}
              userFields={userFields}
              editUser={editUserRow}
              lookupOptions={lookupOptions()}
              lookupOptionsMultiple={['countryRoleAccess']}
              addNew={true}
              newRowDialogConfirm={onAddRow}
              newRowDefaults={newUserDefaults()}
            />

        }
        { currentUser && users && users.length === 0 && (!edit || !editAll) &&
          <LimitedPermissionPage
              user={currentUser.name}
          />}
        </React.StrictMode>
      </Box>
    </Styles>
  );
}
