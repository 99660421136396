import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import jaris from '../../assets/jaris.png';

import styledComp from 'styled-components'
import InputBase from '@mui/material/InputBase';
import ReactiveButton from 'reactive-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faThumbsUp, faBomb } from '@fortawesome/free-solid-svg-icons'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { styled as muiStyled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import AsyncSearch from '../common/AsyncSearch'
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts'
import LimitedPermissionPage from '../common/LimitedPermissionPage'
import IMReactiveBtn from '../common//IMReactiveBtn'
import AppAccessList from './AppAccessList'
import UsersApps from './UsersApps'

import { getApplications, getApplicationUsers, addAppUser, editAppUser } from '../../models/ApplicationAccess'


const APPS_ICONS = {
  // jaris,
  // playmax,
};
const BTNS_TYPES = [
    {'red': 'rgb(244, 81, 108)'},
    {'primary': 'rgb(88, 103, 221)'},
    {'teal': 'rgb(0, 181, 173)'},
    {'yellow': 'rgb(255, 193, 7)'},
    {'dark': 'rgb(66, 78, 106)'},
  ]
const Styles = styledComp.div`
  padding: 1rem;

  .MuiPaper-root {

    background-color: transparent !important
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

`
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'text-transform': 'capitalize',
  'font-weight': '600 !important',
  fontSize: '16px !important',

  'label + &': {
    marginTop: theme.spacing(3),
  },
}))

export default function UsersAccess(props) {
  const [ mount, setMount ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState(props.currentUser)
  const [ token, setToken ] = useState(props.token)
  const [ edit, setEdit ] = useState(props.edit)
  const [ editAll, setEditAll ] = useState(props.editAll)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(false)

  const [ loading, setLoading ] = useState(false)
  const [ refresh, setRefresh ] = useState(false);

  const [ applications, setApplications ] = useState([])
  const [ selectedApplication, setSelectedApplication ] = useState({})
  const [ usersAppAccess, setUsersAppAccess ] = useState({})
  const [ appRoles, setAppRoles ] = useState({})
  const [ currentAppUsers, setCurrentAppUsers ] = useState(null)
  const [ color, setColor ] = useState(null)
  useEffect(() => {
    if (!mount) {
      setMount(true)
      setupApplications()

    }
  })

  const setupApplications = async () => {
    setLoading(true)
    const applicationsResponse = await getApplications()
    if (applicationsResponse.success && applicationsResponse.status === 200) {
      setCurrentUser(applicationsResponse.user)
      setApplications(applicationsResponse.applications)

      let colorsByApp = {}

      applicationsResponse.applications.forEach((app, i) => {
        colorsByApp[app.name] = BTNS_TYPES[i]
      });

      setColor(colorsByApp)
      const response = await getApplicationUsers(applicationsResponse.applications[0].name)

      let usersAppAccessUpdated = Object.assign({}, usersAppAccess)
      let appRolesUpdated = Object.assign({}, appRoles)

      if (response.success) {
        usersAppAccessUpdated[applicationsResponse.applications[0].name] = response.imAppUsers
        appRolesUpdated[applicationsResponse.applications[0].name] = response.appRoles
        setUsersAppAccess(usersAppAccessUpdated)
        setAppRoles(appRolesUpdated)
        setCurrentAppUsers(usersAppAccessUpdated[applicationsResponse.applications[0].name])
        setSelectedApplication(applicationsResponse.applications[0])
      }
        setRefresh(!refresh)
    } else {

    }
    setLoading(false)
  }

  const selectApplication = async (application) => {
    setLoading(true)
    let success = 'error'

    // if (!usersAppAccess[application] || !appRoles[application]) {
      const response = await getApplicationUsers(application)
      let usersAppAccessUpdated = Object.assign({}, usersAppAccess)
      let appRolesUpdated = Object.assign({}, appRoles)

      if (response.success) {
        console.log('reposne ', response)
        usersAppAccessUpdated[application] = response.imAppUsers
        appRolesUpdated[application] = response.appRoles
        setUsersAppAccess(response.imAppUser)
        setSelectedApplication(applications.filter((app) => app.name === application)[0])

        setAppRoles(appRolesUpdated)
        setCurrentAppUsers(response.imAppUsers)

      }
      success = response.success ? 'success' : 'error'
    // }
    setRefresh(!refresh)
    setLoading(false)
    return success
  }

  const onSelectApplication = (applicationName) => {
    // applicationName = applicationName
    const app = applications.filter((appItem) => appItem.name === applicationName)[0]
    setSelectedApplication(app)
    setCurrentAppUsers(usersAppAccess[app.name])
    setRefresh(!refresh)
    selectApplication(app)
    return 'success'
  }

  const onAddAppUser = async (params) => {
    setLoading(true)
    const response = await addAppUser({ applicationName: selectedApplication.name, ...params })
    const message = response.error ? response.error : 'New APP user added'

    if (response.success && response.imAppUsers) {
        let usersAppAccessUpdated = Object.assign({}, usersAppAccess)
        let appRolesUpdated = Object.assign({}, appRoles)
        usersAppAccessUpdated[selectedApplication.name] = response.imAppUsers
        setCurrentAppUsers(usersAppAccessUpdated[selectedApplication.name])
    }

    setAlertMessage(message)
    setErrorMessage(response.error)

    setRefresh(!refresh)
    setLoading(false)
  }

  const oEditAppUser = async (editUserAppParams) => {
    setLoading(true)

    const response = await editAppUser(editUserAppParams)
    const message = response.error ? response.error : 'APP user updated'

    if (response.success && response.imAppUsers) {
        let usersAppAccessUpdated = Object.assign({}, usersAppAccess)
        let appRolesUpdated = Object.assign({}, appRoles)
        usersAppAccessUpdated[selectedApplication.name] = response.imAppUsers
        setCurrentAppUsers(usersAppAccessUpdated[selectedApplication.name])
    }
    setAlertMessage(message)
    setErrorMessage(response.error)

    setRefresh(!refresh)
    setLoading(false)
    return response
  }

  const onCloseAlert = () => {
    setAlertMessage('')
    setErrorMessage(false)
  }

  const getApplicationsDD = () => {

    return   (<FormControl  >
        <Select
          labelId="appUserAccessLabelIdSelect"
          id="appUserAccessLabelSelect"

          value={selectedApplication.name}
          input={<BootstrapInput />}
          label="IM App"

          onChange={onSelectApplication}
        >{
          applications.map((app) =>
            <MenuItem value={app.name}>{app.name}</MenuItem>
          )
        }
        </Select>
      </FormControl>)
  }
  return (

    <Box
      sx={{ display: 'block', height: '100%', width: '100%',
      display: 'block',
      float: 'left' }}
    >
    { currentUser && !props.read
      ? <LimitedPermissionPage
          user={currentUser.name}
      />


    : <Box >
    {alertMessage &&
      <IMAlerts
        message={alertMessage}
        onClose={onCloseAlert}
        success={!errorMessage}
        severity={errorMessage ? 'error' : 'success'}

        error={errorMessage}/>}
      {loading && <IMLoader message={'Loading APP Users...'} mini/>}
      {!loading && applications && applications.length > 0
        &&

         <Box>
          <Stack direction="row" spacing={2} sx={{margin: '15px'}}>

           {selectedApplication && applications.map((app, i) =>
              <IMReactiveBtn
                title={app.name}
                filter={app.name}
                onClick={selectApplication}
                colorBtnType={Object.keys(color[app.name])[0]}
                icon={APPS_ICONS[app.name]}
                currentGroupFilter={selectedApplication.name}
                />
              )
            }
             </Stack>

            { selectedApplication && <Box sx={{ p: 1 }}>
               <Typography sx={{
                 color: color[selectedApplication.name][Object.keys(color[selectedApplication.name])[0]],
                 textAlign: 'right',
                  marginRight: '45px'}}>
                  {selectedApplication.name.toUpperCase()}
                 </Typography>
             </Box>}

            {currentAppUsers && selectedApplication &&
             <UsersApps
              onAddAppUser={onAddAppUser}
              oEditAppUser={oEditAppUser}
              currentUser={currentUser}
              edit={edit}
              loading={loading}
              applicationId={selectedApplication.id}
              applicationName={selectedApplication.name}
              editAll={editAll}
              appRoles={appRoles[selectedApplication.name]}
              users={currentAppUsers}
              color={color[selectedApplication.name] ? color[selectedApplication.name][Object.keys(color[selectedApplication.name])[0]] : 'rgb(183, 186, 191)'}
              refresh={refresh}/>
          }
          </Box>
      }
    </Box>}

  </Box>
);

}
