import React, { useEffect, useState } from 'react';
import { formatDate, formatTime } from '../../common/utils'

import { editUser, addUser, deleteUser, fetchUserPermissions } from '../../../models/UserAccess'

import IMDataGrid from '../../common/IMDataGrid'

import IMLoader from '../../common/IMLoader'
import IMAlerts from '../../common/IMAlerts';
import IMCard from '../../common/IMCard'
import UserForm from '../UserForm'
import LimitedPermissionPage from '../../common/LimitedPermissionPage'
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function TabPanel(props) {
  const { children, tabView, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabView !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabView === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabView: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const columns = [
  { field: 'firstName', align: 'left',headerName: 'First Name', width: 180, editable: false },
  { field: 'lastName', align: 'left',headerName: 'Last Name', width: 180, editable: false },
  { field: 'role', align: 'left',headerName: 'Role', width: 150, editable: false },
  { field: 'ipAddress', align: 'left',headerName: 'IP Address', width: 150, editable: false },
  { field: 'disabled', align: 'left',headerName: 'Disabled', width: 100, editable: false },
  { field: 'email', align: 'left',headerName: 'Email', width: 250, editable: false },

];
export default function AccessLevels(props) {

  const [ rows, setRows ] = useState(props.users);
  const [ users, setUsers ]= useState(props.users);

  // const [ rowModesModel, setRowModesModel ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ loadingMessage, setLoadingMessage ] = useState('');
  const [ alert, setAlert ] = useState(null)

  const [ currentUser, setCurrentUser ] = useState(props.currentUser)
  // const [ forceClose, setForceClose ]  = useState(false)
  const [ loggedInIp, setLoggedInIp ] = useState(props.loggedInIp)
  const [ editCurrentUser, setEditCurrentUser ] = useState(null)
  // const [ confirmModal, setConfirmModal ] = useState(null)
  const [ editDialogOpen, setEditDialogOpen ] = useState(false)
  const [ tabView, setTabView ] = React.useState(0)

  const newUserDefaults = () => {
    return {
      role: '',
      disabled: false,
      email: '',
      firstName: '',
      lastName: '',
    }
  }
  const onAddUser = async (params) => {
    setLoading(true)
    const response = await addUser(params)
    const message = response.error ? response.error : 'New user added'

    if (response.success && response.users) {
        setUsers(response.users)
        setRows(response.users)
    }

    setAlert({message, severity: response.error ? 'error' : 'success', display: true})

    setLoading(false)
  }

  const editUserRow =  async ({ id, email, ipAddress, firstName, lastName, role, disabled }) => {
    setLoading(true)

    const response = await editUser({ id, email, ipAddress, firstName, lastName, role, disabled })
    const message = response.error ? response.error : response.success
    return { response, message }
  }

  const handleConfirmChangeIpAddress = async (loggedInIpResponse, currentUser, users) => {
    const { id, email, ipAddress, firstName, lastName, role, disabled } = currentUser
    handleEditUser({ id, email, ipAddress: loggedInIpResponse, firstName, lastName, role, disabled })
  }


  const getUserIpChangeDesc = (id) => {
    return props.currentUser.id === id && props.loggedInIp && props.loggedInIp !== props.currentUser.ipAddress
      ?  <IMCard
        descriptionBody={`Your current IP address,  ${props.loggedInIp}, is different than what is configured for you. ${props.currentUser.firstName}`}
        descriptionBody2={`Do you want to update your account with this address?`}
        onButtonClick={() => handleConfirmChangeIpAddress(props.loggedInIp, props.currentUser, users)}
        buttonTitle={'Confirm'}

        />
      : <></>
  }

  const onCloseAlert = () => {
    setAlert(null)
  }


  useEffect(() => {
    if (props.users.length === 0 || loading) {
      return
    } else {
      if (!editCurrentUser && props.users.length === 1) {
        setEditCurrentUser(props.currentUser)
      }
    }
  })



  // const handleDeleteClick = (id) => () => {
  //   setLoading(true)
  //   const user = props.users.filter((propUser) => propUser.id == id)[0]
  //   const handleConfirmDeleteUser = async () => {
  //       const response = await deleteUser({ id: user.id })
  //
  //       const message = response.error ? response.error : response.success
  //
  //       if (response.success && response.users) {
  //         setUsers(response.users)
  //         props.onUpdatingUsers(response.users)
  //       }
  //
  //       setAlert({message, severity: response.error ? 'error' : 'success', display: true})
  //       setConfirmModal(null)
  //     }
  //
  //   setLoading(false)
  //   setConfirmModal({
  //      title: `Delete user ${user.firstName} ${user.lastName}`,
  //      description: `Are you sure?`,
  //      handleConfirm: handleConfirmDeleteUser
  //   })
  //
  // };
  //
  // const handleCancelClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });
  //
  //   const editedRow = rows.find((row) => row.id === id);
  //   if (editedRow.isNew) {
  //     setRows(rows.filter((row) => row.id !== id));
  //   }
  // };
  //
  // const processRowUpdate = (newRow) => {
  //   const updatedRow = { ...newRow, isNew: false };
  //   setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  //   return updatedRow;
  // };
  //
  // const handleRowModesModelChange = (newRowModesModel) => {
  //   setRowModesModel(newRowModesModel);
  // };
  // const handleCancelConfirm = () => {
  //   setConfirmModal(null)
  // }
  // function DateCell(props) {
  //   if (props.value == null) {
  //     return null;
  //   }
  //   return `${formatDate(new Date(props.value))} - ${formatTime(new Date(props.value))}`
  // }
  // const columns = [
  //   { field: 'firstName', align: 'left',headerName: 'First Name', width: 180, editable: false },
  //   { field: 'lastName', align: 'left',headerName: 'Last Name', width: 180, editable: false },
  //   { field: 'role', align: 'left',headerName: 'Role', width: 150, editable: false },
  //   { field: 'ipAddress', align: 'left',headerName: 'IP Address', width: 200, editable: false },
  //   { field: 'disabled', align: 'left',headerName: 'Disabled', width: 100, editable: false },
  //   { field: 'email', align: 'left',headerName: 'Email', width: 250, editable: false },
  //   {
  //     field: 'actions',
  //     type: 'actions',
  //     headerName: 'Actions',
  //     width: 100,
  //     cellClassName: 'actions',
  //     getActions: ({ id }) => {
  //       const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  //
  //       if (isInEditMode) {
  //         return [
  //           <GridActionsCellItem
  //             icon={<SaveIcon />}
  //             label="Save"
  //             sx={{
  //               color: 'primary.main',
  //             }}
  //             onClick={handleSaveClick(id)}
  //           />,
  //           <GridActionsCellItem
  //             icon={<CancelIcon />}
  //             label="Cancel"
  //             className="textPrimary"
  //             onClick={handleCancelClick(id)}
  //             color="inherit"
  //           />,
  //         ];
  //       }
  //
  //       return [
  //         <GridActionsCellItem
  //           icon={<EditIcon />}
  //           label="Edit"
  //           className="textPrimary"
  //           onClick={handleEditClick(id)}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<DeleteIcon />}
  //           label="Delete"
  //           onClick={handleDeleteClick(id)}
  //           color="inherit"
  //         />,
  //       ];
  //     },
  //   },
  // ];
  const labelOption = (roles) => roles.map((role) => role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() ).join(' ')

  const iPermRolesOptions = props.ipermRoles.map((role) => ({ label: labelOption(role.split('_')), value: role}))
  const userFields = (rowEdit, self = false) => {
    let excludedFields = ['id', 'updatedAt', 'createdAt', 'name', "dbPasswordExpirationDate", "avatar"]
    if (self) {
      excludedFields.push('disabled')
    }

    return rowEdit && Object.keys(rowEdit).length > 0
     ? Object.keys(rowEdit).filter((field) => (typeof rowEdit[field] !== 'object' && !excludedFields.includes(field) && !(currentUser.hiddenFields).includes(field) || rowEdit[field] === null ) )
     : []
  }

  const handleEditUser = async ({ id, email, ipAddress, firstName, lastName, role, disabled }) => {
    setLoading(true)
    const {response, message} = await editUserRow({ id, email, ipAddress, firstName, lastName, role, disabled })

    const setupUser = (updateUser) => {
      updateUser.ipAddress = ipAddress
      updateUser.firstName = firstName
      updateUser.lastName = lastName
      updateUser.role = role
      updateUser.disabled = disabled
      return updateUser
    }
    if (response.success) {
      users.forEach((user) => user = user.email === email ? setupUser(user) : user )
      setUsers(users)
      props.refreshUsers()
    }

    setAlert({message, severity: response.error ? 'error' : 'success', display: true})
    setEditDialogOpen(false)
    setLoading(false)
  }
  const handleChangeTab = (event, newValue) => {
    setTabView(newValue);
  };
  return (
    currentUser && users.length === 0 && (!props.edit || !props.editAll)
    ? <LimitedPermissionPage
          user={currentUser.name}/>
    : <Box
      sx={{
        height: 500,
        margin: 5,

      }}
    >
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
    { !loading && <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={tabView} onChange={handleChangeTab} aria-label="basic tabs example">
           <Tab label={`${props.currentUser.firstName}`} {...a11yProps(0)} />
           {props.readAll && <Tab label="All Users access" {...a11yProps(1)} />}
         </Tabs>
       </Box>}


       <TabPanel tabView={tabView} index={0}>
       {loading
        ? <IMLoader message={loadingMessage} mini/>
        : <UserForm
           fields={userFields(props.currentUser, true)}
           nonEditable={props.currentUser.selfNonEditableAttributes}
           fieldsValues={props.currentUser}
           fieldsDefaultValues={props.currentUser}
           onSubmit={handleEditUser}
           description={getUserIpChangeDesc(props.currentUser.id)}
           refresh={props.refreshToggle}
           />}
       </TabPanel>
       <TabPanel tabView={tabView} index={1}>
          <Box sx={{margin: 1
          }}>{ loading
           ? <IMLoader message={loadingMessage} mini/>
           : <IMDataGrid
               rows={rows}
               loggedInIp={props.loggedInIp}
               currentUser={props.currentUser}
               editUser={editUser}
               users={props.users}
               onUpdatingUsers={props.onUpdatingUsers}
               ipermRoles={props.ipermRoles}
               refreshUsers={props.refreshUsers}
               editAll={props.editAll}
               dialogDescription={getUserIpChangeDesc}
               columns={columns}
               actions={true}
               userFields={userFields}
               lookupOptions={{role: iPermRolesOptions}}
               addNew={props.editAll}
               newRowDialogConfirm={onAddUser}
               newRowDefaults={newUserDefaults()}

             />}
         </Box>
       </TabPanel>
    </Box>
  );
}
