import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SideTabs from './SideTabs'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import {
  MemoryRouter,
  Route,
  Routes,
  Link,
  matchPath,
  useLocation,
} from 'react-router-dom';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const IMTab = styled(Tab)`
  color: rgb(255,255,255);
  font-family: IBM Plex Sans, sans-serif;
  // color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    background-color: ${blue[50]};
    color: "${blue[600]} !important";


  }
  &.selected {
    background-color: ${blue[50]};
    color: "${blue[600]} !important";
  }

`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export default function SideExpandedTabs(props) {

  const [ value, setValue ] = React.useState(0);
  const [ activeTab, setActiveTab ] = React.useState(null);
  const [ expandedTabs, setExpandedTabs ] = React.useState([]);

  const setTab = (path) => {
    if (!props.tabsTitlesContent[path]) {
      window.location.href = '/'
    }
    setActiveTab(path)
    const active = Object.keys(props.tabsTitles).filter(tab => props.tabsTitles[tab].includes(path))[0]
    if (!expandedTabs.includes(active)) {
      let expandedTabsUpdated = []
      expandedTabs.forEach((tab) => expandedTabsUpdated.push(tab))
      expandedTabsUpdated.push(active)
      setExpandedTabs(expandedTabsUpdated)
    }

  }
  useEffect(() => {
    if (window.location.pathname === '/') {
      const firstTab = Object.keys(props.tabsTitlesContent)[0]
      if (activeTab !== firstTab) {
        setTab(firstTab)
        return
      }

    }
    const path = window.location.pathname

    const pathArr = path.split('/')

    if (path && pathArr.length > 0) {
      let tab = pathArr[pathArr.length - 1].split('-').map(element => {
          return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
        }).join('')
      tab.charAt(0).toLowerCase()
      if (!activeTab) {
        setTab(tab)
      }
    }
  })

  const handleChange = (innerTab) => (event, newValue) => {
    setTab(innerTab)
    newValue = newValue || 0
    setValue(newValue);
    // props.scrollToTop()
  };

  const toggleExpand = (tabExapnd) => (e) => {

    const tabTarget = e.target.innerHTML
    if (props.tabsTitles[tabTarget.replace(/\s+/g, '')]) {
      let expandedTabsUpdated = []
      expandedTabs.forEach((tab) => expandedTabsUpdated.push(tab))

      if (expandedTabs.includes(tabExapnd)) {
        expandedTabsUpdated = expandedTabsUpdated.filter((tab) => tab !== tabExapnd )
        setExpandedTabs(expandedTabsUpdated)
      } else {
        expandedTabsUpdated.push(tabExapnd)
        setExpandedTabs(expandedTabsUpdated)
      }

    }
  }

  const setTitle = (title) => {
    return title.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
  }
  const setUrl = (tabTitle, innerTab) => {
    let url = `/${tabTitle.charAt(0).toLowerCase()}${tabTitle.substring(1).replace(/([A-Z])/g, "-$1").toLowerCase()}/${innerTab.charAt(0).toLowerCase()}${innerTab.substring(1).replace(/([A-Z])/g, "-$1").toLowerCase()}`
    return url
  }

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper',
       display: 'flex', height: '-webkit-fill-available', position: 'absolute',

       width: '100%',
       '.MuiTabs-indicator': { display: 'none'},
     '.MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled': { display: 'none'} }}
    >
    <Box
      sx={{ flexGrow: 1, bgcolor: 'rgb(17, 24, 39)', height: 10000, position: 'fixed'}}
    >
      {Object.keys(props.tabsTitles).map((tabTitle) =>
        props.tabsVisibility[tabTitle] && <Accordion
          key={`${tabTitle}AccordionKey`}
          sx={{ color: 'white !important' }}
          expanded={expandedTabs.includes(tabTitle)}
          onClick={toggleExpand(tabTitle)}
          >
          <AccordionSummary
            key={`${tabTitle}AccordionKeySum`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{width: '-webkit-fill-available', minHeight: '64px'}}>{setTitle(tabTitle)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{width: '-webkit-fill-available'}}>
              {
                props.tabsTitles[tabTitle].map((innerTab, index) =>
                <Tabs
                  key={`${innerTab}KeyTabs`}
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onClick={handleChange(innerTab)}
                  to={setUrl(tabTitle, innerTab)}
                  aria-label="Vertical tabs example"
                  component={Link}
                  scrollButtons={false}
                  sx={{
                   'text-decoration': 'none' }}
                >
                  <IMTab
                    sx={{ 'background-color': activeTab === innerTab ? `${blue[900]}` : 'transparent',
                    color: `${blue[600]} !important`
                      }}
                    key={`${tabTitle}${innerTab}KeyTabsInner`}
                    to="/info"
                    indicator={{display: 'none'}}
                    label={setTitle(innerTab)} {...a11yProps(index)} />
                </Tabs>
                )
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
    {
      activeTab && props.tabsTitlesContent[activeTab] ?
      <Box
        sx={{
          height: '100%',

        marginLeft: '20%',
        'overflow-x': 'hidden',
        bgcolor: 'background.paper',
       textAlign: '-webkit-center' }}
      >
       <TabPanel
          key={`${activeTab}TabPanelKey`}
           sx={{
             height: '100%',
             width: '100%',
             bgcolor: 'background.paper',
            textAlign: '-webkit-center'
           }}
          value={value} index={value}>
          {props.tabsTitlesContent[activeTab]}
        </TabPanel></Box> : <></>
    }

      </Box>
  );
}
