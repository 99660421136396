import axios from 'axios';
import { getHeaders, baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export async function getIpSecurityGroupsCall() {
  try {
    const response = await axios.get(`${baseUrl}/ip_security_groups/group_permissions`,{
       method: "GET",
       withCredentials: true,
       headers: getHeaders(),
       });
    return response;
  } catch (err) {
    return { err };

  }
}


export async function editGroupPermissionCall({ permissionName, newPermissionName }) {
  const data = { rename: newPermissionName }
  try {
    const response = await fetch(`${baseUrl}/ip_security_groups/edit_group_permission/${permissionName}`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify(data)
       }
       );
     const responseBack = await response.json()
     return responseBack
  } catch (err) {
    return { err };

  }
}

export async function editPermissionsGroupsCall({ permissions }) {
  const data = { new_grouped_permission: permissions }
  try {
    const response = await fetch(`${baseUrl}/ip_security_groups/edit_ip_permissions_groups/`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify(data)
       }
       );
     const responseBack = await response.json()
     // console.log('RESPONSE BACK ', responseBack)
     return responseBack
  } catch (err) {
    return { err };

  }
}

export async function newPermissionsGroupCall(data) {
  try {
    const response = await fetch(`${baseUrl}/ip_security_groups/new_permissions_group/`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify(data)
       }
       );
     const responseBack = await response.json()
     // console.log('RESPONSE BACK ', responseBack)
     return responseBack
  } catch (err) {
    return { err };

  }
}

export async function createSecurityGroupCall(data) {
  try {
    const response = await fetch(`${baseUrl}/ip_security_groups/new_security_group/`,{
       method: "POST",
       credentials: 'include',
       headers: getHeaders(),
       body: JSON.stringify(data)
       }
       );
     const responseBack = await response.json()
     // console.log('RESPONSE BACK ', responseBack)
     return responseBack
  } catch (err) {
    return { err };

  }
}
