import axios from 'axios';
import { getHeaders, baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export async function fetchResourcesPermissionsCall() {
  try {
    const response = await axios.get(`${baseUrl}/internal_configs/resources_permissions`,{
       method: "GET",
       withCredentials: true,
         headers: getHeaders(),
       })
    return response;
  } catch (err) {
    return { err };

  }
}

export async function updatePermissionsRolesCall ({ resourcesPermissions }) {
  try {
    const data = { resources: resourcesPermissions }

    const response  = await fetch(`${baseUrl}/internal_configs/update_resources/`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
