import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { yellow } from '@mui/material/colors';
import {
  Link,
} from "@material-ui/core";
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function SSOUnauth(props) {
  return (
    <Card sx={{ minWidth: 275, textAlign: 'center' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
         <WarningRoundedIcon sx={{ color: yellow[500],fontSize: 40 }} />
        </Typography>
        <Typography variant="h5" component="div">
          NOTICE
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          User Session run out.
        </Typography>
        <Typography variant="body2">
          Please sign in again !
          <br />
        </Typography>
        { props.testSaml
          ? <>{ Object.keys(props.testSaml).map((user) =>
            <Typography variant="body2">
              <Link href={`http://localhost:3003/saml?jwt=${props.testSaml[user]}`} variant="body2">
               {user}
              </Link>
            </Typography>
          )}

          </>
          : <Typography variant="body2">
            <Link href="https://influencemobile-sso.awsapps.com/start#/" variant="body2">
            SSO Log In
            </Link>
          </Typography>}
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  );
}
