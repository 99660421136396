
import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Center } from './Center';
import LogoSrc from '../../assets/main-logo.png';
import TextField from '@material-ui/core/TextField';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { styled as muiStyle } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import { editUser } from '../../models/UserAccess'

const Container = styled.div`
    font-size: 16px;
    top: 0;
    position: fixed;
    width: 100%;
/*    z-index: 99999;*/
/* text-align: center; */
/* margin: 40px; */

`;
const HeaderContainer = styled.div`
    position: absolute;
     margin: 15px;

`;
const ProfileContainer = styled.div`
    // position: absolute;
    margin: 15px;
    display: flex;
    justify-content: end;
    display: flex;
`;
const EnvContainer = styled.div`
  // top: -65px;
  // position: absolute;
  display: flex;

  // right: 75px;
  background-color: transparent;
  cursor: pointer;

  label {
    color: white !important;
  }
  input {
    color: white !important;
  }
`
const Content = styled.div`

`;
const Header = styled.div`
  background-color: #24292e;
  height: 80px;
  h2 {
    color: white;
  }
  img {
    height: 40px;
    position: absolute;

    margin-top: 1em;
  }
`;
const Border = styled.div`
    position: absolute;
    top: 10px;
    width: 191px;
    margin-left: 1em;
`;

function LinearIndeterminate() {

  return (
    <Border>
      <LinearProgress />
      <LinearProgress color="secondary" style={{marginTop: '55px'}}/>
    </Border>
  );
}
export default function IMHeader(props) {
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ openNotifications, setOpenNotifications ] = useState(false)
  const [ image, setImage ] = useState(props.user ? props.user.avatar : null);
  const inputFileRef = createRef(null);

  useEffect(() => {
    if (props.user && props.user.avatar && !image) {
      setImage(props.user.avatar)
    }
  })


  const onMenuBtnClick = () => {
    if (props.enableDevMenu) {
      setOpenMenu(!openMenu)
      setOpenNotifications(false);
    }
  }
  const onNotificationBtnClick = () => {
    if (!props.notifications || props.notifications.length === 0) {
      return
    }
    setOpenMenu(false)
    setOpenNotifications(!openNotifications);
  }
  const onSelect = (item, selectFunction) => () => {
    selectFunction(item)
  }
  const StyledBadge = muiStyle(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

  const Menu = () => {
    const handleClickAway = () => {
       setOpenMenu(false);
     };
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper sx={{ width: 320,  zIndex: 9999, position: 'relative',
         '-webkit-box-shadow': '0px 2px 1px #fff inset, 0px 1px 1px #333 inset, 0px 1px 3px',
          marginTop: '90px', marginRight: '14px' }}>
          <MenuList dense>
            {Object.keys(props.headerMenu).map((header) =>
              <>
              <MenuItem>
                <ListItemText>{header}</ListItemText>
              </MenuItem>
              {props.headerMenu[header].items.map((item) =>
                props.selectedHeaderMenu[header].toLowerCase() === item.toLowerCase().replace(' ', '_')
                ? <MenuItem onClick={onSelect(item, props.headerMenu[header].onSelect)}>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    {item}
                  </MenuItem>
                : <MenuItem onClick={onSelect(item, props.headerMenu[header].onSelect)}>
                  <ListItemText inset>{item}</ListItemText>
                </MenuItem>
              )}
              </>
            )
          }

            <Divider />
            <MenuItem>
              <ListItemText>Dev Testing Tools...</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </ClickAwayListener>
    );
  }
  const Notifications = () => {
    const handleClickAway = () => {
       setOpenNotifications(false);
     };
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper sx={{ width: 320, float: 'right',
          zIndex: 9999, '-webkit-box-shadow': '0px 2px 1px #fff inset, 0px 1px 1px #333 inset, 0px 1px 3px',
           marginTop: '90px', marginRight: '14px' }}>
          <MenuList dense>
          <MenuItem>
            <ListItemText sx={{color: deepPurple[500]}}>User Notification Center</ListItemText>
          </MenuItem>
          {props.notifications
            ?  (props.notifications).map((notification) =>
              <MenuItem >
                <ListItemText inset>{notification.message}</ListItemText>
              </MenuItem>
            )
            : <></>}


            <Divider />
            <MenuItem>
            </MenuItem>
          </MenuList>

        </Paper>
      </ClickAwayListener>
    );
  }
  const notificationsLabel = (count) => {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  }

  const handleOnChange = async (event) => {
    const avatar = event.target?.files?.[0];

    if (avatar) {
      setImage(URL.createObjectURL(avatar));
      const params = { avatar }
      const response = await editUser({ id: props.user.id, avatar })
    }
  };

  return (
    <Container>
      <Header>
        <Box sx={{marginLeft: 5}}>
         <img src={LogoSrc} />
        </Box>

        <Grid container >
           <Grid item xs={8}>
            <Box sx={{  textAlign: 'center' }}>
            <h2>
               {props.title}
            </h2>
            </Box>
           </Grid>
           <Grid item xs={4}>
             <Grid container >
               <Grid item xs={6} sx={{display: 'flex', justifyContent: 'start'}}>

                 {props.user && props.allowedTesters.includes(props.user.email) && <EnvContainer onClick={onMenuBtnClick}>
                 {<TextField
                   disabled
                   variant="filled"
                   id="filled-disabled"
                   label="Environment"
                   sx={{cursor: 'pointer', color: 'white'}}
                   defaultValue={props.env.toUpperCase()}
                   style={{ margin: '1em', position: 'absolute' }} />}
                 {props.env && props.env !== 'production' && <LinearIndeterminate color="secondary" />}

                 </EnvContainer>}
                 {openMenu && <Menu setOpenMenu={setOpenMenu}/>}

               </Grid>

                <Grid item xs={3} sx={{display: 'flex', justifyContent: 'end'}}>
                {openNotifications && <Notifications setOpenNotifications={setOpenNotifications}/>}

                  <Box sx={{ position: 'absolute',
                             margin: '15px' }}>
                    <IconButton onClick={onNotificationBtnClick} aria-label={props.notifications ? notificationsLabel(props.notifications.length) : ''} sx={{color: 'white', float: 'right', marginTop: '15px'}}>
                       <Badge badgeContent={props.notifications ? props.notifications.length : null} color="secondary">
                         <MailIcon />
                       </Badge>
                   </IconButton>
                  </Box>

                </Grid>

                <Grid item xs={3}>
                  <ProfileContainer>
                  {props.user &&
                    <Tooltip  title={`${props.user.firstName} ${props.user.lastName}`}>
                  <label htmlFor="upload-avatar-pic" >
                  <IconButton component="span" >

                   <StyledBadge
                       overlap="circular"
                       anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                       variant="dot"
                     >
                     <input accept="image/*" id="upload-avatar-pic" ref={inputFileRef} type="file" hidden onChange={handleOnChange}/>

                     <Avatar
                       src={image}
                       sx={{ bgcolor: deepPurple[500],
                             '& img':{position: 'sticky',
                             marginBottom: '20px'}
                         }}
                       alt={`${props.user.firstName} ${props.user.lastName}`}>{`${props.user.firstName[0].toUpperCase()}${props.user.lastName[0].toUpperCase()}`}</Avatar>
                   </StyledBadge>
                   </IconButton>
                  </label>
                  </Tooltip>}
                  </ProfileContainer>
                </Grid>

             </Grid>
          </Grid>


        </Grid>




      </Header>
  </Container>


)

};
