import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export default function NewSGForm(props) {
  const [ open, setOpen ] = React.useState(true);
  const [ disabled, setDisabled ] = React.useState(true);
  const [ fields, setFields ] = React.useState({})

  const headerTitle = (title) => {
    return title.split(/(?=[A-Z])/).join(' ').toUpperCase()
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onCloseDialog()
  };

   const handleChange = (field) => (event) => {
     const val = event.target.value
     const timer = setTimeout(() => {
       fields[field] = val
       setFields(fields);
       const enable= Object.keys(fields).filter((field) => fields[field] && fields[field].length > 1).length === Object.keys(props.newSgRequiredFields).length
       setDisabled(!enable)
     }, 1000);
     return () => clearTimeout(timer);


   };

   const handleConfirm = () => {
     props.confirmNewSG({ newSecurityGroup: fields, permissionGroup: props.permissionGroup })
   }

  return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
        sx={{
          backgroundColor: '#24292e',
          color: 'white'
        }}
        >{`${headerTitle(props.permissionGroup)} New SG `}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <Stack
            sx={{width: '-webkit-fill-available'}}
            divider={<Divider orientation="horizental" flexItem />}
            spacing={2}
          >
          {

            Object.keys(props.newSgRequiredFields).map((field) =>
              Array.isArray(props.newSgRequiredFields[field])
              ?
              <FormControl variant="standard" fullWidth>
                <InputLabel id={`${field}IdFieldLabelIdLabel`}>{headerTitle(field)}</InputLabel>
                <Select
                  labelId={`${field}IdFieldLabelId`}
                  id={`${field}IdField`}
                  value={fields[field]}
                  label={field.toUpperCase()}
                  onChange={handleChange(field)}
                  >

                  {
                    props.newSgRequiredFields[field].map((value) =>
                      <MenuItem value={value}>{value}</MenuItem>
                    )
                  }

                  </Select>
                 </FormControl>
              : <TextField
                autoFocus
                margin="dense"
                id={`${field}IdField`}
                label={`${headerTitle(field)}`}
                fullWidth
                variant="standard"
                onChange={handleChange(field)}
              />
            )
          }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} disabled={disabled}>Create New SG</Button>
        </DialogActions>
      </Dialog>
  );
}
