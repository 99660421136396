import React, { useEffect, useState } from 'react';
import { formatDate, formatTime } from './utils'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import IMLoader from './IMLoader'
import IMAlerts from './IMAlerts';
import IMCard from './IMCard'
import IMConfirmDialog from './IMConfirmDialog'
import IMDialog from './Dialogs/IMDialog'
import IMReactiveBtn from './IMReactiveBtn'
import Typography from '@mui/material/Typography';
import Chip from '@mui/material-next/Chip';

import PropTypes from 'prop-types';
import { alpha, styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
} from '@mui/x-data-grid';

const ODD_OPACITY = 0.2;
const BTNS_COLOR_MAP = {
    'rgb(244, 81, 108)': 'red',
    'rgb(88, 103, 221)': 'primary',
    'rgb(0, 181, 173)': 'teal',
    'rgb(255, 193, 7)':'yellow',
    'rgb(66, 78, 106)': 'dark',
  }
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100% !important',
    '& .MuiDataGrid-virtualScroller': {
        height: '300px !important',
    },
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>{'No Data Rows'}</Box>
      </StyledGridOverlay>
    );
  }
export default function IMDataGrid(props) {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    // textAlign: 'center',
    width: '100%',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
  }));

  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
    const getHoverBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getSelectedBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getSelectedHoverBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    width: 'fit-content',
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.row}.user`]: {
      backgroundColor: getBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
          ),
        },
      },
    },
    [`& .${gridClasses.row}.custom`]: {
      backgroundColor: getBackgroundColor(
        props.color || theme.palette.grey[200],
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          props.color || theme.palette.grey[200],
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          props.color || theme.palette.grey[200],
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            props.color || theme.palette.grey[200],
            theme.palette.mode,
          ),
        },
      },
    },
  }));

  const [ rows, setRows ] = useState(props.rows);
  const [ users, setUsers ]= useState(props.rows);
  const [ rowModesModel, setRowModesModel ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ loadingMessage, setLoadingMessage ] = useState('');
  const [ alert, setAlert ] = useState(null)

  const [ currentUser, setCurrentUser ] = useState(props.currentUser)
  const [ forceClose, setForceClose ]  = useState(false)
  const [ refresh, setRefresh ] = useState(false)
  const [ editCurrentUser, setEditCurrentUser ] = useState(null)
  const [ confirmModal, setConfirmModal ] = useState(null)
  const [ editDialogOpen, setEditDialogOpen ] = useState(false)
  const [ newDialogOpen, setNewDialogOpen ] = useState(false)

  const [ currentColumns, setCurrentColumns ] = useState(null)
  const [ newRowDefaults, setNewRowDefaults ] = useState(props.rows[0])
  const [ changeRows, setChangeRows ] = useState({})

  const editUserRow =  async (editUserParams) => {
    setLoading(true)

    const response = await props.editUser(editUserParams)

    const message = response.error ? response.error : response.success
    return { response, message }
  }


  const onCloseAlert = () => {
    setAlert(null)
  }


  useEffect(() => {
    if (!currentColumns) {
      if (props.actions) {
        let newColumns = props.columns.filter((col) => col.field !== 'actions')
        newColumns.push(actionsField)
        setCurrentColumns(newColumns)

      } else {
        setCurrentColumns(props.columns)
      }
      setLoading(false)

    }
    if (props.users.length === 0 || loading) {
      return
    } else {

      if (!editCurrentUser && props.users.length === 1) {

        setEditCurrentUser(props.currentUser)
      }
    }
    if (props.refresh !== refresh) {
      setRefresh(props.refresh)
      // setRows(props.users)
      // setUsers(props.users)
      let cols = props.columns.filter((col) => col.field !== 'actions')
      cols.push(actionsField)
      setCurrentColumns(cols)
      setEditCurrentUser(null)
      setConfirmModal(null)
      setNewRowDefaults(props.users[0])
    }
    if (props.changeRow && (Object.keys(changeRows).length === 0
      || props.changeRow[Object.keys(props.changeRow)[0]] !== changeRows[Object.keys(props.changeRow)[0]])) {
      let updateRows = changeRows
      updateRows[Object.keys(props.changeRow)[0]] = props.changeRow[Object.keys(props.changeRow)[0]]
      let newRows = rows
      newRows.forEach((row) => {
        if (row.id === Object.keys(props.changeRow)[0]) {
          let field = Object.keys(props.changeRow[Object.keys(props.changeRow)[0]])[0]
          row[field] = props.changeRow[row.id][field] === 'true'
        }
      })
      // console.log('SET ROWS', newRows)

      setRows(newRows)
      setChangeRows(updateRows)
    }
  })

  const handleRowEditStop = (params, event) => {
    // console.log('handleRowEditStop', params, event)

    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
      const current = users.filter((user) => user.id === id)[0]

    setEditCurrentUser(current)


    if (props.internalEditing) {
      // console.log('EDIT!!! ', id, props.internalEditing)
      setRefresh(!props.refresh)
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    } else {
      setEditDialogOpen(true)
    }
  };

  const handleAddNewClick = () => {
    if (props.addNew) {
      // setEditCurrentUser(newRowDefaults)
      setNewDialogOpen(true)
    }
  }

  const handleNew = async (newRow) => {
    if (props.newRowDialogConfirm) {
      props.newRowDialogConfirm(newRow)
      setNewDialogOpen(false)
    }
  }
  const handleEdit = async (editRow) => {
    if (props.newRowDialogConfirm) {
      props.editUser(editRow)
      setNewDialogOpen(false)
    }
  }

  const handleSaveClick = (id) => () => {
    setRefresh(!props.refresh)

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };


  const handleDeleteClick = (id) => () => {
    setLoading(true)
    const user = users.filter((propUser) => propUser.id == id)[0]
    const handleConfirmDeleteUser = async () => {
        // const response = await deleteUser({ id: user.id })
        //
        // const message = response.error ? response.error : response.success
        //
        // if (response.success && response.users) {
        //   setUsers(response.users)
        //   props.onUpdatingUsers(response.users)
        // }
        //
        // setAlert({message, severity: response.error ? 'error' : 'success', display: true})
        setConfirmModal(null)
      }
    setEditCurrentUser(user)
    setLoading(false)
    setConfirmModal({
       title: user.firstName ? `Delete user ${user.firstName} ${user.lastName}` : `Delete user ${user.userName}`,
       description: `Are you sure?`,
       handleConfirm: handleConfirmDeleteUser
    })

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    setRefresh(!props.refresh)
    let rollbackRows = rows
    rollbackRows.forEach((row) => {
      if (row.id === id) {
        const originRow = props.rows.filter((rowOrigins) => rowOrigins.id === id)[0]
        Object.keys(row).forEach((key) => row[key] = originRow[key])
      }
    })
    setRows(rollbackRows)
  };

  const processRowUpdate = (newRow) => {
    // console.log('UPDATE ROW ', newRow)
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    // console.log('handleRowModesModelChange', newRowModesModel)
    setRowModesModel(newRowModesModel);
  };
  const handleCancelConfirm = () => {
    setConfirmModal(null)
  }
  function DateCell(props) {
    if (props.value == null) {
      return null;
    }
    return `${formatDate(new Date(props.value))} - ${formatTime(new Date(props.value))}`
  }
  const actionsField = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  const labelOption = (roles) => roles.map((role) => role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() ).join(' ')

  const iPermRolesOptions = props.ipermRoles.map((role) => ({ label: labelOption(role.split('_')), value: role}))

  const onFieldChange = (field, value, rowEdit) => {
    // console.log('CHNAGE ', field, value, rowEdit)

  }



  const handleEditUser = async (editUserParams) => {
    setLoading(true)
    const {response, message} = await editUserRow(editUserParams)

    const setupUser = (updatedUser) => {
      Object.keys(updatedUser).forEach((attr) => {
        updatedUser[attr] = editUserParams[attr]
      })
      return updatedUser
    }
    if (response.success) {

      users.forEach((user) => user = user.id === editUserParams.id ? setupUser(user) : user )
      setUsers(users)
      // console.log('SET EDITED USER ', editUserParams)
      setEditCurrentUser(editUserParams)
      if (props.refreshUsers) {
        props.refreshUsers()
      }
    }

    setAlert({message, severity: response.error ? 'error' : 'success', display: true})
    setEditDialogOpen(false)
    setLoading(false)
  }

  return (
    <Box
      sx={{
        height: 500,
        margin: 5,
      }}
    >

    {confirmModal &&
      <IMConfirmDialog
      openDialog={true}
      title={confirmModal.title}
      description={confirmModal.description}
      handleConfirm={confirmModal.handleConfirm}
      handleCancel={handleCancelConfirm}
      confirmText={'Yes'}
      cancelText={'Dismiss'}

    />}
    {  !props.internalEditing && editDialogOpen && editCurrentUser && (props.editAll || (props.edit && editCurrentUser.email === currentUser.email))
      && <IMDialog
            open={editDialogOpen}
            onSubmit={handleEditUser}
            description={props.dialogDescription ? props.dialogDescription(editCurrentUser.id) : null}
            title={editCurrentUser.firstName ? `${editCurrentUser.firstName} ${editCurrentUser.lastName}` : editCurrentUser.userName}
            onClose={() => setEditDialogOpen(false)}
            fields={props.userFields(editCurrentUser)}
            hiddenFields={currentUser.hiddenFields}
            fieldsDefaultValues={editCurrentUser}
            fieldsValues={editCurrentUser}
            onFieldChange={onFieldChange}
            nonEditable={editCurrentUser.id === currentUser.id
              ? currentUser.selfNonEditableAttributes
              : currentUser.nonEditableAttributesAll}
            fieldsOptionsSelect={props.fieldsOptionsSelect}
            lookupOptionsMultiple={props.lookupOptionsMultiple}
            lookupOptions={props.lookupOptions}
        />
      }
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
      <Box sx={{margin: 1
        }}>{ loading
           ? <IMLoader message={loadingMessage} mini/>
           : <Box >
              {props.addNew &&
              <Item key={'gridHeaderKey'} elevation={24} sx={{display: 'flex', marginBottom: 3}}>
                <Stack
                    sx={{width: '100%'}}
                    direction="row"  justifyContent="space-between"
                 >

                  {
                    props.gridTitle &&  <Box>
                          <Chip key={`${props.gridTitle}key`}
                            label={props.gridTitle}
                            sx={{ color: 'black', margin: '15px', float: 'left', fontWeight: 700 }}
                            variant="elevated"/>

                          </Box>
                  }
                  <Box sx={{marginRight: '5px'}}>
                   <IMReactiveBtn
                     colorBtnType={props.color ? BTNS_COLOR_MAP[props.color] : null}
                     rounded={true}
                     title={'Add New'}
                     size={'tiny'}
                     height={10}
                     disableIdle={true}
                     onClick={handleAddNewClick}
                 /></Box>
                 </Stack>

                {  newDialogOpen && newRowDefaults && props.editAll
                && <IMDialog
                      open={newDialogOpen}
                      onSubmit={handleNew}
                      title={'Add New'}
                      onClose={() => setNewDialogOpen(false)}
                      fields={props.userFields(newRowDefaults)}
                      hiddenFields={currentUser.hiddenFields}
                      fieldsDefaultValues={props.newRowDefaults || newRowDefaults}
                      fieldsValues={props.newRowDefaults || newRowDefaults}
                      onFieldChange={onFieldChange}
                      nonEditable={[]}
                      fieldsOptionsSelect={props.fieldsOptionsSelect}
                      lookupOptionsMultiple={props.lookupOptionsMultiple}
                      lookupOptions={props.lookupOptions}
                      newRowCustomDialogField={props.newRowCustomDialogField}
                  />
                }
              </Item>}
              <StripedDataGrid
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
               rows={rows}
               columns={props.editAll ? currentColumns : currentColumns.filter((col) => col.field !== 'actions')}
               editMode="row"
               rowModesModel={rowModesModel}
               onRowModesModelChange={handleRowModesModelChange}
               onRowEditStop={handleRowEditStop}
               processRowUpdate={processRowUpdate}
               slotProps={{
                 toolbar: { setRows, setRowModesModel },
               }}
               initialState={{
                 pagination: {
                   paginationModel: {
                     pageSize: 10,
                   },
                 },
               }}
               getRowClassName={(params) =>
                  params.id === props.currentUser.id ? 'user' : params.indexRelativeToCurrentPage % 2 === 0 ? props.color ? 'custom' : 'even' : 'odd'
                }
               pageSizeOptions={[10]}
               disableRowSelectionOnClick
               loading={loading}
               sx={{'& .MuiDataGrid-virtualScroller': {
                   minHeight: '300px !important',
               },
               '& .MuiDataGrid-overlayWrapperInner': {
                 width: 'fit-content !important'
               }
             }}

             />
             </Box>
           }
         </Box>
    </Box>
  );
}
