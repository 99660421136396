import { getApplicationsCall,
  getApplicationUsersCall,
  editAppUserCall,
  addAppUserCall
} from '../api/ApplicationAccess'

import { toCamelCase, processParamsToServer, processResponse } from './Utils'


export async function getApplications() {
  const response = await getApplicationsCall();
  if (response.status === 200 && response.data ) {
    if (response.data && !response.data.error) {

      return { status: 200, success: true, ...processResponse(response.data) }
    } else {
      return { error: response.data.error, status: 500 };
    }
  } else {
    // console.log('ERROR', response)
    return { error: response.error, status: response.status };
  }
}

export async function getApplicationUsers(applicationName) {
  const response = await getApplicationUsersCall(applicationName);
  if (response.status === 200 && response ) {
    if ( !response.error) {

      return { status: 200, success: true, ...processResponse(response) }
    } else {
      return { error: response.error, status: response.status };
    }
  } else {
    // console.log('ERROR', response)
    return { error: response.error, status: response.status };
  }
}

export async function editAppUser(data) {

  // const applicationName = data.applicationName

  const dataToServer = processParamsToServer(data)
  const response = await editAppUserCall({ internal_permissions: true, ...dataToServer })

  if (response.status === 200  ) {
    return { status: 200, success: true, ...processResponse(response) }
  } else {
    // console.log('ERROR', response)
    return { error: response.error, status: response.status };
  }
}

export async function addAppUser(data)  {

  const dataToServer = processParamsToServer(data)

  const response = await addAppUserCall({ internal_permissions: true, ...dataToServer })
  // console.log('ADD USER RESPONSE ', response)
  if (response.status === 200 ) {

    return { status: 200, success: true, ...processResponse(response) }

  } else {
    // console.log('ERROR', response)
    return { error: response.error, status: response.status };
  }
}
