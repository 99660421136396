import { getIpSecurityGroupsCall,
  editGroupPermissionCall,
  editPermissionsGroupsCall,
  newPermissionsGroupCall,
  createSecurityGroupCall
} from '../api/IpSecurityGroups'

import { toCamelCase, processParamsToServer, camelToUnderscore, processResponse } from './Utils'


export async function getIpSecurityGroups() {
  const response = await getIpSecurityGroupsCall();

  if (response.status === 200 && response.data ) {
    if (response.data && !response.data.error) {

      return { status: 200, success: true, ...processResponse(response.data) }
    } else {
      return response.data;
    }
  } else {
    // console.log('ERROR', response)
    return { ...response, error: response.error, status: response.status };
  }
}


export async function editGroupPermission({ permissionName, newPermissionName }) {
  const serverPermissionsName = camelToUnderscore(permissionName)
  const serverRenamedPermissionsName = newPermissionName.replace(' ', '_').toLowerCase()

  const response = await editGroupPermissionCall({ permissionName: serverPermissionsName, newPermissionName: serverRenamedPermissionsName });

  if (response.status === 200 && response ) {
    if (response && !response.error) {

      return { status: 200, success: true, ...processResponse(response) }
    } else {
      return response;
    }
  } else {
    // console.log('ERROR', response)
    return { ...response, error: response.error, status: response.status };
  }
}

export async function editPermissionsGroups({ permissionsLists }) {
  const data = processParamsToServer(permissionsLists)
  const response = await editPermissionsGroupsCall({ permissions: data });

  if (response.status === 200 && response ) {
    if (response && !response.error) {

      return { status: 200, success: true, ...processResponse(response) }
    } else {
      return response;
    }
  } else {
    // console.log('ERROR', response)
    return { ...response, error: response.error, status: response.status };
  }
}

export async function newPermissionsGroup({ group, sgAttached }) {
  const newGroup = group.replace(' ', '_').toLowerCase()

  const data = { new_group: newGroup, permissions: sgAttached }

  // console.log('DATA TO SERVER ', permissionsLists)
  const response = await newPermissionsGroupCall(data);

  if (response.status === 200 && response ) {
    if (response && !response.error) {

      return { status: 200, success: true, ...processResponse(response) }
    } else {
      return response;
    }
  } else {
    // console.log('ERROR', response)
    return { ...response, error: response.error, status: response.status };
  }
}

export async function createSecurityGroup({ newSecurityGroup, permissionGroup }) {
  let data = processParamsToServer(newSecurityGroup)
  data['name'] = data["name"].toLowerCase().replaceAll(' ', '_')
  const group = camelToUnderscore(permissionGroup)
  data['group'] = group
  const response = await createSecurityGroupCall(data)

  if (response.status === 200 && response ) {
    if (response && !response.error) {

      return { status: 200, success: true, ...processResponse(response) }
    } else {
      return response;
    }
  } else {
    // console.log('ERROR', response)
    return { ...response, error: response.error, status: response.status };
  }
}
