// import { createTheme, extendTheme } from '@mui/material/styles';
import { purple, pink, deepPurple, grey } from '@mui/material/colors';
// import { createTheme, ThemeProvider } from '@mui/material/styles'



const theme = (color) => {
    return {primary: [ color[50], color[100],
    color[200], color[300],
    color[400], color[500],
     color[600], color[700],
     color[800], color[900] ],
    secondary: [ color[100],
     color[100],
    color[200],
    color[200],
    color[200],
    color[200],
    color[300],
    color[400],
    color[400],
    color[400],
     color[500],
     color[500],
     color[600],
     color[600],
     color[700],
     color[800], color[900] ],}
}

export function descendingComparator(a, b, orderBy) {
  const compA = a[orderBy] || ''
  const compB = b[orderBy] || ''

  if (compB < compA) {
    return -1;
  }
  if (compB > compA) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function setHeaderTitle(header) {
  return header.split('_').map((word) => word = word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
}

export function headerColor(color) {

  return theme(color || grey)
}

export function secondHeaderColor(header, i) {
  const mainColor = headerColor(header)

}

export function headCells(row){
  // const row = rows[0]
  return Object.keys(row).map((col) =>
    ({
      id: col,
      numeric: typeof row[col] === 'number',
      disablePadding: true,
      label: setHeaderTitle(col),
    })
  )
}
