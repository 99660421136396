import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { getIpSecurityGroups,
         editGroupPermission,
         editPermissionsGroups,
         newPermissionsGroup,
         createSecurityGroup } from '../../../../models/IpSecurityGroups'
import { headerColor } from '../Utils'
import LimitedPermissionPage from '../../../common/LimitedPermissionPage'

import NewSGForm from './NewSGForm'
import NewGroupForm from './NewGroupForm'
import RenameGroupForm from './RenameGroupForm'
import DragAndDropGroups from './DragAndDropGroups'

import { indigo } from '@mui/material/colors';

const MAIN_COLOR = indigo

const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#1A2027 !important',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 900
}));


const Container =  styled(Box)(({ theme }) => ({
  textAlign: 'left',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const PermissionHeader = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  fontWeight: 800,
  color: 'black'
}));

export default function Settings(props) {

  const [ permissionsGroups, setPermissionsGroups ] = useState(null)

  const [ newSGProps, setNewSGProps ] = useState(null)
  const [ newSgRequiredFields, setNewSgRequiredFields ] = useState(null)
  const [ newGroupProps, setNewGroupProps ] = useState(null)
  const [ newGroups, setNewGroups ] = useState([])
  const [ renameGroupProps, setRenameGroupProps ] = useState(null)
  const [ toggleRefresh, setToggleRefresh ] = useState(false)
  const [ permissionGroups, setPermissionGroups ] = useState([])
  const [ unauthorized, setUnauthorized ] = useState(false)

  const headerTitle = (title) => {
    return title.split(/(?=[A-Z])/).join(' ').toUpperCase()
  }
  const setupGroups = async () => {
    const response = await getIpSecurityGroups();
    if (response.groupsPermissions) {
      setPermissionsGroups(response.groupsPermissions)
      setPermissionGroups(response.permissionGroups)
      if (!newSgRequiredFields) {
        setNewSgRequiredFields(response.newSgRequiredFields)
      }

    } else {
      if (response.error && response.error.status === 401 && response.error.message === "unauthorized") {
        setUnauthorized(true)
      } else {
        props.unAuthCallback(response)
      }
    }
  }

  const onCloseDialog = () => {
    setNewSGProps(null)
    setNewGroupProps(null)
    setRenameGroupProps(null)
  }

  const refresh = () => {
    setupGroups()
    setToggleRefresh(!toggleRefresh)
    props.onEditPermissions()
  }

  const handleNewSGRequest = (permissionGroup) => {
    setNewSGProps({ permissionGroup, onCloseDialog, newSgRequiredFields, confirmNewSG })
  }

  const confirmNewSG = async ({newSecurityGroup, permissionGroup}) => {
    const response = await createSecurityGroup({ newSecurityGroup, permissionGroup })

    if (response.status === 200) {
      refresh()
    }
    setNewSGProps(null)
  }

  const onNewGroupCreated = async ({ group, sgAttached }) => {
    const response  = await newPermissionsGroup({ group, sgAttached })

    if (response.status === 200) {
      refresh()
    }
    setNewGroupProps(null)
  }

  const handleNewGroupRequest = () => {
    setNewGroupProps({ onCloseDialog, onNewGroupCreated, permissionGroups })
  }
  const camelToUnderscore = (key) => {
  	return key.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase())
  }
  const processList = ({ permissionsLists, groups }) => {
    let newGroups = {}

    groups.forEach((group, i) => {
      const lists = permissionsLists[i]
      const groupServerName = camelToUnderscore(group)
      newGroups[groupServerName] = []
      lists.forEach((list) => newGroups[groupServerName].push(camelToUnderscore(list.title)))

    })
    return newGroups
  }

  const updatePermissions = async ({permissionsLists, groups}) => {
    const listsToUpdate = processList({ permissionsLists, groups })

    const response  = await editPermissionsGroups({ permissionsLists: listsToUpdate })

    if (response.status === 200) {
      refresh()
    }
  }

  const renamePermissionGroup = async ({ permissionName, newPermissionName }) => {
    onCloseDialog()
    const response = await editGroupPermission({ permissionName, newPermissionName })
    if (response.groupsPermissions) {
      refresh()
    }
  }

  const handleRenameGroupRequest = (group) => {
    setRenameGroupProps({ permissionGroup: group, onCloseDialog, renamePermissionGroup })
  }

  useEffect(() => {
    if (!permissionsGroups) {
      setupGroups()

    }
  })

  return (
    <>
    { unauthorized
    ?   <LimitedPermissionPage
          user={props.currentUser.name}
        />

    : <><Box sx={{
        display: 'flex',
        alignItems: 'baseline',
        padding: 'inherit',
        width: '100%',
        borderRadius: 5,
        height: '100%', backgroundColor: 'rgb(231, 235, 240)' }}
        >

      { newSGProps && <NewSGForm {...newSGProps}/>}
      { newGroupProps && <NewGroupForm {...newGroupProps}/>}
      { renameGroupProps && <RenameGroupForm {...renameGroupProps}/>}


         <DragAndDropGroups
            permissionsGroups={permissionsGroups}
            handleNewGroupRequest={handleNewGroupRequest}
            handleNewSGRequest={handleNewSGRequest}
            updatePermissions={updatePermissions}
            handleRenameGroupRequest={handleRenameGroupRequest}
            newGroups={newGroups}
            toggleRefresh={toggleRefresh}
            />

      </Box></>}
    </>
  );
}
