import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


export default function IMCard(props) {
  const onButtonClick = () => {
    if (props.onButtonClick) {
      props.onButtonClick();
    }

  }
  return (
    <Card sx={{ minWidth: 275, marginBottom: 5 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {props.descriptionBody}
        </Typography>
        <Typography variant="body2">
          {props.descriptionBody2}
          <br />
        </Typography>
      </CardContent>
      {!props.hideButton && <CardActions>
        <Button
          disabled={props.disableButton}
          size="small"
          onClick={props.onButtonClick}
          variant={props.confirmBtnVariant || 'contained'}
              color={props.confirmBtnColor || 'primary'}
              sx={{height: '42px'}}
          >{props.buttonTitle}</Button>
      </CardActions>}
    </Card>
  );
}
