import React, { useCallback, useState } from 'react';
import debounce from "lodash.debounce";
import { grey, blueGrey, orange, teal, red, yellow, purple, pink, deepPurple, indigo, amber, lightBlue, cyan, blue } from '@mui/material/colors';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function HeaderActions({
  rows, filteredRows, preGlobalFilteredRows,
  disbaleUpdatePermissions,
  setPreGlobalFilteredRows,
  // onSubmitNewPermissions,
  setFilteredRows,
  globalFilter,
  refreshEnable,
  enableUpdate,
  synchEnable,
  onRefreshPermissions,
  onSynchPermissions,
  onUpdatePermissions,
  setGlobalFilter }) {

  // const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
  const [ refresh, setRefresh ] = useState(false);
  const [ synch, setSynch ] = useState(false);
  // const [ dialogProps, setDialogProps ] = useState(null)

  const changeHandler = (event) => {
    const val = event.target.value

    let filtered = []
    let newRows = []
    if (val) {
      preGlobalFilteredRows.forEach((row) =>  {
        if (row.user.toLowerCase().includes(val.toLowerCase())) {
          filtered.push(row.user.toLowerCase())
          newRows.push(row)
        } else {
          filtered = filtered.filter((name) => name !== row.user.toLowerCase())
        }
      })
      setFilteredRows(filtered)
      setPreGlobalFilteredRows(newRows)
    } else {
      if (filteredRows.length > 0) {
        setFilteredRows(filtered)
        setPreGlobalFilteredRows(rows)
      }
    }
    const global = val || undefined
    setGlobalFilter(global);
    if ( global === undefined ) {

      setFilteredRows([])
      setPreGlobalFilteredRows(rows)

    }

  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const submitChangesClick = () => {
    onUpdatePermissions(true)
  }

  const refreshClicked = async () => {
    setRefresh(true)

    await onRefreshPermissions()

    setRefresh(false)
  }

  const synchClicked = async () => {
    setSynch(true)

    await onSynchPermissions()

    setSynch(false)
  }

  return (
    <Paper
         component="form"
         sx={{ p: '2px 4px', display: 'flex', position: 'absolute', marginTop: '-80px', alignItems: 'center', width: '100%' }}
       >
       <Button
         disabled={disbaleUpdatePermissions}
         variant="contained"
         sx={{ display: enableUpdate ? 'flex' : 'none' }}
         onClick={submitChangesClick}>Update Permissions</Button>

         <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
         <Tooltip title={'Synch Users Ip Permissions'}>
            <IconButton
              onClick={synchClicked}
              disabled={!synchEnable}
              sx={{ p: '10px', color: synchEnable ? orange[900] : 'gray' }}
              aria-label="searchIcon">
              {synch
                ?
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress  sx={{ color: ' gray !important', p: '1px', fontSize: '20px !important', width: '20px !important', height: '20px !important' }}/>
                    </Box>

                : <SyncIcon />}
            </IconButton>
          </Tooltip>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <Tooltip title={'Reload Users Ip Permissions'}>
             <IconButton
               onClick={refreshClicked}
               disabled={!refreshEnable}
               sx={{ p: '10px', color: refreshEnable ? blueGrey[700] : 'gray' }}
               aria-label="searchIcon">
               {refresh
                 ?
                     <Box sx={{ display: 'flex' }}>
                       <CircularProgress  sx={{ color: ' gray !important', p: '1px', fontSize: '20px !important', width: '20px !important', height: '20px !important' }}/>
                     </Box>

                 : <RefreshIcon />}
             </IconButton>
           </Tooltip>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton disabled sx={{ p: '10px' }} aria-label="searchIcon">
           <SearchIcon />
         </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
       <TextField
         fullWidth
         sx={{width: '100% !important'}}
         onChange={debouncedChangeHandler}
         label="Search User"
         id="filterTable" />

     </Paper>
  );
}
