import React, { useEffect, useState } from 'react';
import { getPgDbUsersAccess, updateDBPassword, getDBDetails } from '../../../models/DatabaseAccess'
import { setHeaderTitle } from '../../common/utils'

import styled from 'styled-components'
import AsyncSearch from '../../common/AsyncSearch'
import MyDBConfigs from './MyDBConfigs'

import NewPGUser from './NewPGUser'
import IMAlerts from '../../common/IMAlerts'
import IMDataGrid from '../../common/IMDataGrid'
import IMLoader from '../../common/IMLoader'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useGridApiContext } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';

const Styles = styled.div`
  padding: 1rem;

  .MuiPaper-root {
    background-color: #fff !important
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

`

export default function PGUser(props) {

  const [ currentUser, setCurrentUser ] = useState(props.currentUser)

  const [ rows, setRows ] = useState(null);
  const [ columns, setColumns ] = useState(null)

  const [ loading, setLoading ] = useState(false)
  const [ allDbs, setAllDbs ] = useState(props.allDbs)
  const [ selectedUser, setSelectedUser ] = useState(null)
  const [ dbUser, setDbUser ] = useState(null)
  const [ usersOptions, setUsersOptions ] = useState(null)
  const [ changeRow, setChangeRow ] = useState(null);
  const [ allRoles, setAllRoles ]= useState(null);
  const [ dbRoles, setDbRoles ]= useState(null);
  const [ dbDetails, setDbDetails ] = useState(null)
  const [ dbSelected, setDbSelected ] = useState(null)
  // const [ userDbDetailsAttr, setUserDbDetailsAttr] = useState(null)
  const [ detailedDBs, setDetailedDBs ] = useState({})

  const [ open, setOpen ] = React.useState(-1);
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ alert, setAlert ] = useState(null)

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: props => props.backgroundColor,
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  }));

  const userFields = () => {
    // return [ 'dbName', 'name' ]
  }

  const fetchSelectedDBDetails = async (dbName, user) => {
    setLoading(true)
    const response = await getDBDetails({ dbName: dbName, detailed: true })
    // console.log('RESPONSE ', response)
    if (response.status === 200 && response.dbs && response.dbs.length > 0) {
      // setDetailedDBs(response.dbs)
      let dbDetailsFetched = {}
      let detailed = {}
      if (response.dbs && response.dbs.length > 0) {
        const db = response.dbs[0].dbName
        dbDetailsFetched[db] = response.dbs

        detailed = dbDetailsFetched[db][0].details
      }

      let newDetailedDBs = detailedDBs
      newDetailedDBs[dbName] = response.dbs[0].details

      setDbDetails(newDetailedDBs)
      setDbSelected(dbName)
      // console.log('SETUP USER ', response.dbs[0].userDbDetailsAttr)
      // const userDbAttr = response.dbs[0].userDbDetailsAttr.map((attr) => attr)
      // setUserDbDetailsAttr(userDbAttr)
      // props.onDetails({ detailed, userDbDetailsAttrUpdate: response.dbs[0].userDbDetailsAttr })
      handleClickOpen(dbName, newDetailedDBs[dbName], user)
    } else {
      setLoading(false)
      props.unAuthCallback(response)
      // setDetailedDBs(null)
    }
    setLoading(false)
  }

  const handleClickOpen = (db, currentDbDetails, selectedUser) => {
    // console.log('DEATLS ??? ', db, currentDbDetails, props.userDbDetailsAttr, props.currentUser.dbUsername, selectedUser)
    // userDbDetails = userDbDetailsAttr ? userDbDetailsAttr : userDbDetails
    let propsDialog = {}
    propsDialog['title'] = db
    propsDialog['content'] = () =>
            ! currentDbDetails
            ?  <Item sx={{ fontWeight: 900, color: 'black' }}>
                  <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginTop: 5 }}>
                    { 'Details unavaiable ' }
                  </Typography>
                </Item>

            : <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{marginTop: 5}}>
                <Grid item xs={4}>
                  <Item sx={{ fontWeight: 900, color: 'black' }}>{ setHeaderTitle('dbUsername') }</Item>
                 </Grid>
                 <Grid item xs={8}>
                   <Item sx={{color: 'black'}}>{selectedUser ? selectedUser.dbUsername : props.currentUser.dbUsername }</Item>
                 </Grid>
                { Object.keys(currentDbDetails).map((key) =>
                   <>{ props.userDbDetailsAttr.includes(key) && <><Grid item xs={4}>
                     <Item sx={{ fontWeight: 900, color: 'black' }}>{ setHeaderTitle(key) }</Item>
                    </Grid>
                    <Grid item xs={8}>
                      <Item sx={{color: 'black'}}>{currentDbDetails[key] }</Item>
                    </Grid></>}
                  </>
               )}
             </Grid>


    setDialogProps(propsDialog)
    setOpen(db);
  };
  const handleClose = () => {
    setOpen(-1);
    setDialogProps(null)
  };

  const onSelectDb = (dbName, user = null) => () => {
    // console.log('SELECT AGAIN ??? ', selectedUser)

    if (detailedDBs && detailedDBs[dbName] && props.userDbDetailsAttr) {

      handleClickOpen(dbName, detailedDBs[dbName], user)
    } else {
      // if (!detailedDBs || !detailedDBs[dbName]) {
      fetchSelectedDBDetails(dbName, user)
      // }

    }
  }

  const DBCell = (params) => {
    const { value } = params;
    // console.log('PARAMS ', params)
    return <Button variant="text" onClick={onSelectDb(value, params.row.user)}>{value}</Button>

  }

  const RoleCell = (props) => {
    const { value } = props;
    return (
      value === 'unsupported'
        ? <></>
        :value === true
        ? <IconButton aria-label="CheckIcon" >
           <CheckIcon  color={'primary'}/>
         </IconButton>
        : <IconButton aria-label="CloseIcon" >
           <CloseIcon  color={'error'}/>
         </IconButton>
    )
  }
  const  RoleEditInputCell = (props) => {

    const { id, value, field, row } = props;
    const [ rowVal, setRowVal ] = useState(row[field]);

     // const currentRoleVal = rows.filter((row) => row.id === id).field
     const apiRef = useGridApiContext();

     const handleChange = async (event) => {
       // setChangeRow({[id]: { [field]: event.target.value}})

       setRowVal(event.target.value);
       // row[field] = event.target.value
       // await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
       // apiRef.current.stopCellEditMode({ id, field });
     };
     useEffect(() => {
       if (rowVal !== row[field]) {
         setChangeRow({[id]: { [field]: rowVal}})
       }
     })

     return (
       <Select
         value={rowVal}
         onChange={handleChange}
         size="small"
         sx={{ height: 1 }}
         native
         autoFocus
       >
         <option value={true}>True</option>
         <option value={false}>False</option>
       </Select>
     );
  }
  const renderRoleEditInputCell = (params) => {
    return <RoleEditInputCell {...params} setChangeRow={setChangeRow}/>;
  };
  const setupGrid = ({ user, dbRoles, allRoles, userDbRoles, selectedUser }) => {

    let dbGridRows = []
    let newRows = new Map();

    (userDbRoles).forEach((dbAccess) => {
      if (!newRows[dbAccess.dbName]) {
        newRows[dbAccess.dbName] = {}
        newRows[dbAccess.dbName]['id'] = dbAccess.dbName
        newRows[dbAccess.dbName]['dbName'] = dbAccess.dbName
        newRows[dbAccess.dbName]['user'] = selectedUser

      }


      // console.log('ROLES ??? ', dbAccess)
      allRoles.forEach((dbRole) => {
          newRows[dbAccess.dbName][dbRole] =  dbRoles[dbAccess.dbName].includes(dbRole)
            ? newRows[dbAccess.dbName][dbRole] || dbAccess.role === dbRole
            : 'unsupported'
      })

    })

    dbGridRows = Object.keys(newRows).map((db) => newRows[db])


    setRows(dbGridRows)

    let newColumns = [
      { field: 'dbName',
        align: 'left',
        headerName: 'Database Name',
        width: 280,
        renderCell: DBCell,
        editable: false }
    ]
    allRoles.forEach((role) => {

          newColumns.push({
            field: role,
            align: 'left',
            headerName: role,
            width: 180,
            editable: true,
            renderCell: RoleCell ,
            renderEditCell: renderRoleEditInputCell

          })
    })
    // console.log('SET COL', newColumns)
    setColumns(newColumns)

  }


  useEffect(() => {
    if (!rows && !usersOptions && props.users && props.allRoles && props.dbRoles) {
      setRows([])
      let newUsersOptions = []
      props.users.forEach((user) => {
        if (user.dbUsername) {
          newUsersOptions.push({ id: user.id, value: `${user.firstName} ${user.lastName} - ${user.email}`, option: user })
        }
      })
      setUsersOptions(newUsersOptions)
      // onSelectDbUser(props.users[1])

    }
      // console.log('ME!!!!', props, rows , usersOptions , props.users , props.allRoles , props.dbRoles , props.myConfigs)
    if (!rows && !usersOptions && props.users && props.allRoles && props.dbRoles && props.myConfigs) {
      const me = props.users.filter((user) => props.currentUser.id === user.id)[0]
      onSelectDbUser(currentUser)
    }


  })

  const fetchDBPGUser = async (user) => {

    setLoading(true)
    const response = props.me ? await getPgDbUsersAccess({me: true}) : await getPgDbUsersAccess({ userId: user.id })
    // console.log('response ME ', response)
    if (response.status === 200) {
      setDbUser(response.dbUserAccess)
      setupGrid({ user,
        dbRoles: props.dbRoles,
        allRoles: props.allRoles,
        userDbRoles: response.dbUserAccess,
        selectedUser: user })
    } else {
      if (response.status !== 401) {
        props.unAuthCallback(response)

      } else {
      }
      setDbUser(null)
    }
    setLoading(false)
  }

  const onSelectDbUser = (user) => {
    if (!user) {
      setDbUser(null)
      setRows([])
      setColumns(null)

    } else {
      // console.log('SELECT USER', user)
      setSelectedUser(user)
      fetchDBPGUser(user)
    }

  }

  const onAddRow = async (params) => {

  }
  const onDelete = (user) => {

  }

  const editUserRow =  async (editUserAppParams) => {
// // { id, email, name, role, disabled, restrictedRole, countryRoleAccess, applicationId, applicationName, personalInformationRestriction }
//     editUserAppParams['applicationName'] = applicationName
//     const response = await editAppUser(editUserAppParams)
//
//     const message = response.error ? response.error : 'Edit User successfully'
//
//     return { message, ...response  }
  }

  const handleEditClick = (id) => () => {
    // const current = props.users.filter((user) => user.id === id)[0]
    // setEditCurrentUser(current)
    // // setEditDialogOpen(true)
    //
    // // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const onClear = () => {
    // console.log('ON CLEAR')
    setSelectedUser(null)
  }

  const onUpdatePassword = async ({ user, password }) => {
    setOpen(-1);
    setDialogProps(null)

    setLoading(true)
    const response = await updateDBPassword({ user, password })
    // console.log('RSPONSE ', response)
    const message = response.error ? response.error : response.success
    if (response.status === 200) {
      // update notifications....
      props.notificationsRefreshed(response.notifications)
    } else {
    }

    setAlert({error: response.error, sevirity: response.error ? 'error' : 'success', message })

    setLoading(false)
    // setAlertMessage(message)
  }
  const resetPasswordDialogOpened = () => {
    setDialogProps({
      title: 'Reset My DB Password',
      handleSubmit: onUpdatePassword,
      hideActionBtns: true,
      // submitlTitle: 'Update Password',
      content: () => <MyDBConfigs
          dbUsername={props.currentUser.dbUsername}
          currentUser={props.currentUser}
          notificationsRefreshed={props.notificationsRefreshed}
          onUpdatePassword={onUpdatePassword}
        />,
      titleIcon: () => <SyncLockIcon />
    })

  }


  return (
    <Styles >

      <Box
        sx={{ display: 'block', height: '100%', width: '100%',
        display: 'block',
        float: 'left' }}
      >
      {dialogProps && <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: '#24292e',
            color: 'white'
          }}
          id={'editViewDialog'}>
          {dialogProps.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {dialogProps.content()}
          </DialogContentText>
        </DialogContent>
        {!dialogProps.hideActionBtns && <DialogActions>
          <Button color="success" onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>}
      </Dialog>}
      {!props.myConfigs && <AsyncSearch
          key={`asyncSearchForDBUser`}
          width={'100%'}
          onClear={onClear}
          onSelected={onSelectDbUser}
          options={usersOptions}
          searchTitle={'Search User Database Access'}/>}
      <React.StrictMode>
        {
          loading || !rows
          ? <IMLoader message={'Loading DB Details...'} mini/>
          : <>
          {props.myConfigs && <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary">
            <Toolbar>
               <Button component="label" variant="contained" startIcon={<SyncLockIcon />} onClick={resetPasswordDialogOpened}>
                 My DB Password
               </Button>

              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                {`My DB Username:  ${props.currentUser.dbUsername}`}
              </Typography>


            </Toolbar>

           </AppBar>
          </ThemeProvider>}
          {columns && <IMDataGrid
              rows={rows}
              currentUser={props.currentUser}
              users={rows}
              editAll={true}
              columns={columns}
              refresh={loading}
              color={props.color}
              userFields={userFields}
              editUser={editUserRow}
              ipermRoles={[]}
              actions={props.myConfig}
              internalEditing={true}
              changeRow={changeRow}
              gridTitle={selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : null}

            />}</>

        }
        </React.StrictMode>
      </Box>

    </Styles>
  );
}
