import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function IMDialog(props) {
  const [open, setOpen] = React.useState(props.open);

  const [ fields, setFields ] = React.useState([]);
  const [ defaultFieldsValues, setDefaultFieldsValues ] = React.useState(null);

  const [ fieldsValues, setFieldsValues ] = useState(props.fieldsValues);
  const [ refresh, setRefresh ] = React.useState(props.refreshDialog)
  const [ nonEditable, setNonEditable ] = React.useState(props.nonEditable || [])
  const [ fieldsOptionsSelect, setFieldsOptionsSelect] = React.useState(props.fieldsOptionsSelect)
  const [ title, setTitle ] =  useState(props.title)
  const [ subTitle, setSubTitle ] =  useState('')
  const [ showSubTitle, setShowSubTitle ] = useState(props.showSubTitle)
  const [ focused, setFocused ] = useState(false)

  React.useEffect(() => {

    if ((props.open !== open || props.refreshDialog !== refresh) && fieldsValues && fieldsValues.id === props.fieldsValues.id) {
      setOpen(props.open)
      setRefresh(props.refreshDialog)
      setFieldsValues(props.fieldsValues)
    }

    if (open && fields && fields.length === 0) {
      const filteredFields = props.hiddenFields && props.hiddenFields.length > 0 ?
        props.fields.filter((field) => !props.hiddenFields.includes(field))
        : props.fields
      setFields(filteredFields)
      setFocused(filteredFields[0])
      setFieldsValues(props.fieldsValues)
      setDefaultFieldsValues(props.fieldsDefaultValues)
    }


  })



  const handleChange = (name) => (event) => {
    // console.log('NAME', name, event.target.value, event.target.checked)
    let newName = typeof fieldsValues[name] === 'boolean' ? event.target.checked : event.target.value


    const updatedFieldsValues = Object.assign({}, fieldsValues)
    updatedFieldsValues[name] = newName
    setFieldsValues(updatedFieldsValues)

    if (props.onFieldChange) {
      props.onFieldChange(name, newName)
    }

    const newRoleRestricted = updatedFieldsValues.restrictedRole
      ? '_restricted'
      : ''
    const countryRole = updatedFieldsValues.countryRoleAccess && updatedFieldsValues.countryRoleAccess.length > 0
    ? `_${updatedFieldsValues.countryRoleAccess.join('_')}`
    : ''
    setFocused(name)
    setSubTitle(`New Role Permissions: ${updatedFieldsValues.role}${newRoleRestricted}${countryRole}`)
  };
  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(fieldsValues)
    }
    handleClose();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFieldsValues(props.fieldsDefaultValues)
    setOpen(false);
    if (props.onClose) {
      props.onClose()
    }
  };
  const setHeaderTitle = (header) => {
    return header.split(/(?=[A-Z])/).map((word) => word = word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
  }

  const getFieldValue = (field) => {

    return fieldsValues[field]
  }
  const disableSubmit = () => {
    let disable = false

    if (fields && fields.length > 0) {

      if (props.invalidOptions && Object.keys(props.invalidOptions).length > 0) {
        fields.forEach((field) => {
          disable = disable || props.invalidOptions[field] && props.invalidOptions[field]['values'].includes(fieldsValues[field])
        })
      }
    }
    return disable
  }
  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  return (
    <div>
      {props.headerIcon}
      <Dialog open={open} onClose={handleClose} fullScreen={props.fullScreen}>

        <DialogTitle
        sx={{
          backgroundColor: props.headerColor ? props.headerColor : '#24292e',
          color: props.titleColor ? props.titleColor : 'white'
        }}
        >
        {title}
        <Box
            component="form"
            sx={{
              float: 'right',
            }}
            noValidate
            autoComplete="off"
          ><AccountCircle  />
        </Box>
        </DialogTitle>
      {showSubTitle &&  <DialogTitle sx={{fontSize: '14px', fontWeight: 600, color: 'white'}}>
        {subTitle}
        </DialogTitle>}

        <DialogContent sx={{marginTop: 3}}>
          <DialogContentText>
            {props.description}
          </DialogContentText>
          {props.newRowCustomDialogField
            ? props.newRowCustomDialogField 
            : <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {  width: '34ch' },
                '& .MuiInputBase-root': {
                  height: '5ch',
                  marginBottom: '15px'

                },
                '& .MuiInputBase-input': {
                  marginLeft: '2ch'
                }
              }}
              noValidate
              autoComplete="off"
            >
            <FormControl sx={{ m: 3, marginBottom: 5, marginTop: 2, '& .MuiFormControl-root':{
              margin: '5px !important'
            }}} component="fieldset" variant="standard">
              <FormGroup>
              {fields && fields.length > 0
                ? fields.map((field) => (
                 (typeof fieldsValues[field] === 'boolean')
                  ?  <FormControlLabel
                        id={`${field}-id`}
                        key={`${field}-idKey`}
                        sx={{marginBottom: 2}}
                        control={
                          <Checkbox
                          disabled={nonEditable.includes(camelize(field)) }
                          checked={fieldsValues[field]}
                          onChange={handleChange(field)}
                          checkedIcon={<CheckCircleIcon color="error"/>}
                          icon={<HighlightOffIcon />}
                          name={field} />
                        }
                        label={fieldsValues[field] ? `${setHeaderTitle(field)}` : `Not ${setHeaderTitle(field)}`}
                      />


                : fieldsOptionsSelect && fieldsOptionsSelect[field]
                 ? <FormControl sx={{ m: 1,

                       '& .MuiInputBase-input': {
                         display: 'flex !important',

                       } }} >
                       <InputLabel
                         id={`${field}-labelInputId`}>
                         {setHeaderTitle(field)}
                       </InputLabel>
                       <Select
                         labelId={`${field}-labelId`}

                         id={`${field}-id`}
                         disabled={nonEditable.includes(camelize(field)) }
                         value={fieldsValues[field]}
                         label={setHeaderTitle(field)}
                         onChange={handleChange(field)}
                         sx={{
                           '& .MuiInputBase-root': {
                             display: 'flex !important',

                           }
                         }}
                       >
                       {
                         fieldsOptionsSelect[field].map((option) =>
                           <MenuItem
                           key={`key${option.label}${field}`}
                           sx={{
                                 '&.MuiInputBase-input': {
                                   display: 'flex !important'
                                 } }}
                           disabled={option.disabled} value={option.value}>
                             <ListItemIcon>
                                {option.icon}
                              </ListItemIcon>
                            <Typography >{option.label}</Typography>

                           </MenuItem>
                         )
                       }
                       </Select>
                     </FormControl>




               :
                props.lookupOptions && Object.keys(props.lookupOptions).includes(field)
                ?  <FormControl sx={{ m: 1,
                      '& .MuiInputBase-input': {
                        display: 'flex !important',
                        justifyContent: 'space-evenly'
                      } }} >
                      <InputLabel
                        id={`${field}-labelInputId`}>
                        {setHeaderTitle(field)}
                      </InputLabel>
                      <Select
                        labelId={`${field}-labelId`}
                        id={`${field}-id`}
                        disabled={nonEditable.includes(camelize(field)) }
                        multiple={props.lookupOptionsMultiple && props.lookupOptionsMultiple.includes(field)}
                        value={fieldsValues[field]}
                        label={setHeaderTitle(field)}
                        onChange={handleChange(field)}
                        sx={{
                          '& .MuiInputBase-root': {
                            display: 'flex !important',
                            justifyContent: 'space-evenly'
                          }
                        }}

                      >
                      {
                        props.lookupOptions[field].map((option) =>
                          <MenuItem
                          key={`key${option.label}${field}`}

                          disabled={option.disabled} value={option.value}>
                            <ListItemIcon
                            sx={{
                              minWidth: '25px',
                              alignItems: 'flex-end'
                            }}
                              >
                               {option.icon}
                             </ListItemIcon>
                           <Typography >{option.label}</Typography>

                          </MenuItem>
                        )
                      }
                      </Select>
                    </FormControl>

                : <TextField
                      id={`${field}-id`}
                      key={`${field}-idKey`}
                      variant="standard"
                      disabled={nonEditable.includes(camelize(field)) }
                      error={props.invalidOptions && props.invalidOptions[field] && props.invalidOptions[field]['values'].includes(getFieldValue(field))}
                      label={props.invalidOptions && props.invalidOptions[field] && props.invalidOptions[field]['values'].includes(getFieldValue(field)) ? props.invalidOptions[field]['message'] : setHeaderTitle(field)}
                      value={getFieldValue(field)}
                      onChange={handleChange(field)}
                      autoFocus={focused === field}
                    />
              )): <></>}
              </FormGroup>
            </FormControl>

          </Box>}
        </DialogContent>
        {!props.hideActionBtns && <DialogActions>
          <Button
            variant="outlined" color="error"
            onClick={handleClose}>{props.cancelTitle || 'Cancel'}</Button>
          <Button variant="outlined"
                disabled={disableSubmit()}
                color="success"
                onClick={handleSubmit}>{props.submitlTitle || 'Submit'}</Button>
        </DialogActions>}
      </Dialog>
    </div>
  );
}
