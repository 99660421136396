import { getPgUsersCall,
         // getPgDbUsersRolesCall,
         updateDBPasswordCall,
         newPgUserCall,
         syncDBSecretCall,
         editDBRolesCall,
         updateUserDBRolesCall,
         getPgDbUsersAccessCall,
         getPgDbApplicationAccessCall,
         updateApplicationDBRolesCall,
         getAllDBDetailsCall,
         syncDBUsersRolesCall,
         updateDbUsersPermissionsCall,
         getDBDetailsCall } from '../api/DatabaseAccess'

import { toCamelCase, camelToUnderscore, processParamsToServer, processResponse, processComplexResponse } from './Utils'


export async function getPgUsers() {
  const response = await getPgUsersCall();
  if (response.status === 200 && response.data ) {
    if (response.data && !response.data.error) {

      return { defaultUserValues: defaultUserValues(), ...processPgUserResponse(response.data), status: response.status }
    } else {
        return { error: `ERROR! ${response.data.error.message}`, status: response.status};
    }
  } else {
    // console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function syncDBSecret(secretKey, newRolesSync, defaultRolesIds) {
  const response = await syncDBSecretCall(secretKey, newRolesSync, defaultRolesIds)
  if (response.status === 200 ) {
    let res = {}
    if (response.dbs && response.dbs.length > 0) {
      let responseBack = {}
      responseBack.db = processPgUserResponse(response.dbs)
      return { success: response.message || `New DB was successfully synced`, message: response.message || `New DB was successfully synced`, status: response.status, ...responseBack};

    } else {
      return { error: `${response.message || 'Failed to sync DB'}`, status: response.status };

    }

  } else {
    // console.log('ERROR', response)
    return { error: `${response.message || 'Failed to sync DB'}`, status: response.status };
  }
}

export async function editDBRoles({dbName, roles, defaults}) {
  const response = await editDBRolesCall({ dbName, roles, defaults });
  if (response.status === 200  && response.dbs && response.dbs.length > 0) {
    let res = {}
    let responseBack = {}
    responseBack.dbs = processPgUserResponse(response.dbs)
    return { success: response.message || ` DB Roles were successfully synced`, message: response.message || ` DB Roles were successfully synced`, status: response.status, ...responseBack};
  } else {
    // console.log('ERROR', response)
    return { error: `${response.message || 'Failed to sync DB roles'}`, status: response.status };
  }
}

export async function newPgUser({ defaultPermissions, dbUserName, email }) {
  const response = await newPgUserCall({ email, dbUserName, defaultPermissions });
  if (response.status === 200 ) {

    return { success: response.message || `PG User successfully created`, message: response.message, status: response.status };
  } else {
    // console.log('ERROR', response)
    return { error: `${response.message || 'Failed to create PG user'}`, status: response.status };
  }
}



export async function updateDBPassword({ user, password }) {
  const response = await updateDBPasswordCall({ user, password });

  if (response.status === 200 ) {
    return { status: 200, success: response.message || `Password successfully updated`, message: response.message };

  } else {
    // console.log('ERROR', response)
    return { error: `${response.message}`, status: response.status };
  }
}

// export async function getPgDbUsersRoles(pgUser) {
//
//   const response = await getPgDbUsersRolesCall(pgUser);
//
//   if (response.status === 200 && response.data ) {
//     if (response.data && !response.data.error) {
//       return { ...processPgUserResponse(response.data), status: response.status }
//     } else {
//         return { error: `ERROR! ${response.data.error.message}`, status: response.status};
//     }
//   } else {
//     // console.log('ERROR', response)
//     return { error: `ERROR! ${response}`, status: response.status};
//   }
// }


export async function updateUserDBRoles({ dbRoles, searchedUser, token }) {

  const pgUser = searchedUser
  const data = processParamsToServer(dbRoles)


  const response = await updateUserDBRolesCall({ pgUser, data, token});

  if (response.status === 200 ) {

    return { success: response.message || `PG User successfully updated`,
       message: response.message, ...processPgUserResponse(response), status: response.status };
  } else {
    // console.log('ERROR', response)
    return { error: `ERROR! ${response.message || 'Failed to update PG user'}`, status: response.status };
  }
}


export async function syncDBUsersRoles(syncUsers) {

  const response = await syncDBUsersRolesCall(syncUsers)
  if (response.status === 200 ) {

    return { success: response.message || `Synching PG users roles`,
       message: response.message, ...processPgUserResponse(response), status: response.status };
  } else {
    // console.log('ERROR', response)
    return { error: `ERROR! ${response.message || 'Failed to Sync PG user'}`, status: response.status };
  }
}

export async function updateDbUsersPermissions({updatedPermissions, dbName}) {

  const response = await updateDbUsersPermissionsCall({updatedPermissions, dbName})
  if (response.status === 200 ) {

    return { success: response.message || `PG Users updated`,
       message: response.message, ...processPgUserResponse(response), status: response.status };
  } else {
    // console.log('ERROR', response)
    return { error: `ERROR! ${response.message || 'Failed to update PG user'}`, status: response.status };
  }
}

export async function getPgDbUsersAccess({dbName = '', me = false, userId = ''}) {
  const response = await getPgDbUsersAccessCall({dbName, me, userId} );
  const responseBack = processResponse(response)
  return responseBack
}
export async function getPgDbApplicationAccess(applicationName) {
  const response = await getPgDbApplicationAccessCall(applicationName);

  if (response.status === 200 ) {
    const responseBack = processPgUserResponse(response.data)


    return { success: response.message || `PG DB Application successfully fetched`,
       message: response.message || `PG DB Application successfully fetched`, ...responseBack, status: response.status };


  } else {
    console.log('ERROR', response)
    return { error: `${response.message}`, status: response.status };
  }
}

export async function updateApplicationDBRoles({ dbRoles, searchedApplication }) {

  const pgUser = searchedApplication
  const data = processParamsToServer(dbRoles)


  const response = await updateApplicationDBRolesCall({ pgUser, data });

  if (response.status === 200 ) {

    return { success: response.message || `PG User successfully updated`,
       message: response.message, ...processPgUserResponse(response), status: response.status };
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response.error || 'Failed to update PG user'}`, status: response.status };
  }
}


export async function getDBDetails({dbName, detailed}) {
  const response = await getDBDetailsCall({dbName, detailed});

  if (response.status === 200 ) {
    const responseBack = processPgUserResponse(response)


    return { success: response.message || `PG DB successfully fetched`,
       message: response.message || `PG DB successfully fetched`, ...responseBack, status: response.status };


  } else {
    console.log('ERROR', response)
    return response;
  }
}

export async function getAllDBDetails({detailed}) {
  const response = await getAllDBDetailsCall({detailed});

  if (response.status === 200 ) {
    const responseBack = processPgUserResponse(response)


    return { success: response.message || `PG DB successfully fetched`,
       message: response.message || `PG DB successfully fetched`, ...responseBack, status: response.status };


  } else {
    console.log('ERROR', response)
    return response;
  }
}

const mapPgUser = (pgUser) => {
  return {
    name: pgUser.usename,
    canCreateDatabases: pgUser.usecreatedb,
    isSuperuser: pgUser.usesuper,
    password: pgUser.passwd,
  }
}
const toServerPgUser = (pgUser) => {
  return {
    usename: pgUser.name,
    usecreatedb: pgUser.canCreateDatabases || false,
    usesuper: pgUser.isSuperuser || false ,
    db_user_name: pgUser.dbUserName,
  }
}
const defaultUserValues = () => {
  return {
    dbUserName: '',
    name: '',
    canCreateDatabases: false,
    isSuperuser: false,
  }
}
export const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value))
    } else {
      if (Array.isArray(value) && value[0] && value[0].type && Object.keys(value[0]).includes('userName') ) {
        value.forEach((val) => {

          if (!flattened[toCamelCase(val.type)]) {
            flattened[toCamelCase(val.type)] = []
          }
          flattened[toCamelCase(val.type)].push(flattenObject(val))
        })
      } else {
        flattened[key] = value
      }
    }
  })

  return flattened
}
export const processPgUserResponse = (pgUserResponse, prevKey) => {
  let user = {}
  try {
    if (!pgUserResponse || pgUserResponse == null) {
      return null
    }
    if (Array.isArray(pgUserResponse)) {
      user = []
      pgUserResponse.forEach((item) => {
        if (typeof item === 'object') {
          user.push(processPgUserResponse(item))
        } else {
          user.push(item)
        }
      })
    } else {
      Object.keys(pgUserResponse).forEach((key) => {

        if ( typeof pgUserResponse[key] === 'number' ||
              typeof pgUserResponse[key] === 'string' ||
                typeof pgUserResponse[key] === 'boolean') {

            user[toCamelCase(key)] = pgUserResponse[key]
          } else {
            if ( toCamelCase(key) === 'pgUsers' ) {

              user[toCamelCase(key)] = {}
              pgUserResponse[key].forEach((pguser) => {
                user[toCamelCase(key)][pguser.usename] = mapPgUser(pguser)
               }
             )
            } else {
              if ( key === 'attributes' ) {
                  Object.assign(user, processPgUserResponse(pgUserResponse[key]))
              } else {
                user[toCamelCase(key)] = processPgUserResponse(pgUserResponse[key])
              }
            }
          }
      })
    }
    return user;
  } catch (err) {
    console.log('ERROR ???? ', err)
     return user;
  }
}
