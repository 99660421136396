import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts'
import LimitedPermissionPage from '../common/LimitedPermissionPage'

import { fetchResourcesPermissions, updatePermissionsRoles } from '../../models/InternalConfigs'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const theme = createTheme({
  typography: {
    // Tell MUI what the font-size on the html element is.
    htmlFontSize: 10,
  },
});
const Styles = styled.div`
  padding: 1rem;

  .MuiPaper-root {

    background-color: #fff !important
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

`

const PermissionsRolesSettings = (props) => {

  const [ resourcesPermissions, setResourcesPermissions ] = useState(null)
  const [ responsePermissions, setResponsePermissions ] =  useState([])
  const [ loading, setLoading ] = useState(false)
  const [ mount, setMount ] = useState(false)
  const [ headers, setHeaders ] = useState([])
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(false)
  const [ token, setToken ] = useState(props.token)
  const [ edit, setEdit ] = useState(false)
  const [ editAll, setEditAll ] = useState(false)
  const [ read, setRead ] = useState(false)
  const [ readAll, setReadAll ] = useState(false)

  const [ currentUser, setCurrentUser ] = useState(props.currentUser)

  const isDate = (date) => {
      return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
  }
  const processFields = (responseResourcesPermissions) => {
    let viewPermissions = []
    responseResourcesPermissions.forEach((resource) => {
      let filtered = {}
      Object.keys(resource).forEach((key) => {
        if (typeof resource[key] === 'string' && !isDate(resource[key])) {
          filtered[key] = resource[key]
        }
      }
      )
      viewPermissions.push(filtered)
    })

    setResourcesPermissions(viewPermissions)
    setHeaders(Object.keys(viewPermissions[0]))
  }
  const setupResourcesPermissions = async () => {
    setLoading(true)
    const response = await fetchResourcesPermissions()
    if (response.error || response.status === 401) {

    } else {
      setEdit(response.edit.self)
      setEditAll(response.edit.all)
      setRead(response.read.self)
      setReadAll(response.read.all)
      setResponsePermissions(response.resourcesPermissions)
      processFields(response.resourcesPermissions)

    }
    setLoading(false)
  }
  const setHeaderTitle = (header) => {
    return header.split(/(?=[A-Z])/).map((word) => word = word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
  }

  useEffect(() => {
    if (!mount) {
      setLoading(true)
      setMount(true)
      setupResourcesPermissions()

    }
  })
  const getTableCell = (key, resourcesPermission) => {
    const handleChange = (name) => (event) => {
      const newName = event.target.value
      let resourcesPermissionsUpdated = []
      resourcesPermissions.forEach((resource) => {
        let newResource = resource
        newResource[key] = resource.name ===  resourcesPermission.name
          ? newName
          : resource[key]
        resourcesPermissionsUpdated.push(resource)
      })
      setResourcesPermissions(resourcesPermissionsUpdated)

    }
    return <TextField
       id={`resourcesPermission-${key}-id`}
       key={`resourcesPermission-${key}-keyid`}
       select
       disabled={key === 'master' || !edit }
       label={''}
       value={resourcesPermission[key]}
       onChange={handleChange(key)}
       SelectProps={{
         native: true,
       }}
     >
       {[{
             value: 'R',
             label: 'R',
           },
           {
             value: 'RP',
             label: 'RP',
           },
           {
             value: 'RE',
             label: 'RE',
           },
           {
             value: 'RPEP',
             label: 'RPEP',
           },
           {
             value: 'REP',
             label: 'REP',
           },
           {
             value: 'D',
             label: 'D',
           }
          ].map((option) => (
             <option key={`key-${key}-id`} value={option.value}>
               {option.label}
             </option>
       ))}
     </TextField>
  }
  const handleSubmit = async () => {
    setLoading(true)
    const response  = await updatePermissionsRoles({ resourcesPermissions, token })
    const message = response.error ? response.error : response.success

    if (response.error) {

    } else {

    }
    setAlertMessage(message)
    setErrorMessage(response.error)

    setLoading(false)
    setTimeout( () => {
      window.location.reload()
    }, 1000)
  }
  const onCloseAlert = () => {
    setAlertMessage('')
    setErrorMessage(false)

  }
  return (
    <Styles>
      <Box sx={{ width: '100%' }}>
      {alertMessage &&
        <IMAlerts
          message={alertMessage}
          onClose={onCloseAlert}
          success={!errorMessage}
          severity={errorMessage ? 'error' : 'success'}

          error={errorMessage}/>}
      // { loading  && <IMLoader message={'Loading Permissions Roles Settings...'}/>}

        <Paper sx={{ width: '100%', mb: 2, bgColor: 'white' }}>

          <ThemeProvider theme={theme}>
            <Typography sx={{color: 'black'}}>Resources Permissions Roles Settings</Typography>
          </ThemeProvider>
        { !loading && currentUser && (!read)
          ? <LimitedPermissionPage
              user={currentUser.name}
          />
        :  resourcesPermissions && <TableContainer component={Paper}>
             <Table sx={{ minWidth: 650 }} aria-label="caption table">
               <caption>* R (Read) * E (Edit) * D (Denied) * P (Personal info)</caption>
               <TableHead>
                 <TableRow>
                   <Button variant="contained" onClick={handleSubmit}>Update</Button>
                 </TableRow>
                 <TableRow>
                 {
                   headers.map((header) =>
                      <TableCell>{setHeaderTitle(header)}</TableCell>
                    )
                 }
                 </TableRow>
               </TableHead>
               <TableBody>
                 {resourcesPermissions.map((resourcesPermission) => (
                   <TableRow key={resourcesPermission.name}>
                   {Object.keys(resourcesPermission).map((key) =>
                     key === 'name'
                     ? <TableCell component="th" scope="row">
                          {resourcesPermission.name}
                        </TableCell>
                     : <TableCell align="left">{getTableCell(key, resourcesPermission)}</TableCell>
                    )}
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>}
          </Paper>

        </Box>
    </Styles>
  );
}

export default PermissionsRolesSettings;
