import React, { useEffect, useState } from 'react';
import { styled as styledMUI } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { setHeaderTitle } from '../../common/utils'

const Item = styledMUI(Paper)(({ theme }) => ({

  backgroundColor: 'transparent !important',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'white',
}));


export default function EditDBForm({ db, allRoles, onRoleChange, onDefaultRolesChange }) {
  const [ roles, setRoles ] = useState(db['availableRoles'])
  const [ defaultRoles, setDefaultRoles ] = useState(db['defaultRoles'])
  const [ refresh, setRefresh ] = useState(false)

  const roleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onRoleChange({newRoles: value, newDefaultRoles: defaultRoles,db})
  };

  const defaultRole = (role) => () => {
    let newDefRoles = defaultRoles
    if (newDefRoles.includes(role)) {
      newDefRoles = newDefRoles.filter((roleDef) => roleDef !== role)
    } else {
      newDefRoles.push(role)
    }
    setRefresh(!refresh)
    setDefaultRoles(newDefRoles)
    onDefaultRolesChange({newDefaultRoles: newDefRoles, newRoles: roles,db})
  }

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      { Object.keys(db).map((key) =>
         <><Grid item xs={4}>
           {key !== 'defaultRoles' && <Item sx={{ fontWeight: 900, color: 'black' }}>{ setHeaderTitle(key) }</Item>}
          </Grid>
          <Grid item xs={8}>
{    key === 'availableRoles'
            ? <Select
              variant="standard"
              labelId="pgroles-id-label"
              id="pgroles-id-label-helper"
              key="pgroles-key-label-helper"

              value={roles}
              defaultValue={roles}

              label="PG Roles"
              onChange={roleChanged}
              sx={{minWidth: '100%'}}
              multiple
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(roles).map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
          )}
            >
              {
               allRoles.map((role) =>
                 <MenuItem
                 key={role.userRole}
                 value={role.userRole}
                 >
                 {`${role.userRole} - ${role.description}`}
                 </MenuItem>
               )
              }
            </Select>
            : key !== 'defaultRoles' && <Item sx={{color: 'black'}}>{ db[key] }</Item>
}        </Grid>
        </>
     )}
     <Grid item xs={12}>
     <FormControl variant="standard">
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {roles.map((value) => (
          <Chip key={value} label={value} onClick={defaultRole(value)}

          color="primary" variant={defaultRoles.includes(value) ? "" : "outlined"}/>
        ))}
      </Box>
      <FormHelperText id="component-helper-text">
        Mark the default role permissions a new user can be granted
      </FormHelperText>
    </FormControl>
     </Grid>
   </Grid>

  )
}
