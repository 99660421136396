import { grey } from '@mui/material/colors';

export function setHeaderTitle(header) {
  return header.split(/(?=[A-Z])/).map((word) => word = word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
}
export function formatDate(newDate) {
  const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const d = newDate
  const year = d.getFullYear()
  const date = d.getDate()
  const monthIndex = d.getMonth()
  const monthName = months[d.getMonth()]
  const dayName = days[d.getDay()] // Thu
  const formatted = `${monthName} ${d.getDate()}th, ${year}`
  return formatted.toString()
}

export function formatTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
}
const theme = (color) => {
    return {primary: [ color[50], color[100],
    color[200], color[300],
    color[400], color[500],
     color[600], color[700],
     color[800], color[900] ],
    secondary: [ color[100],
     color[100],
    color[200],
    color[200],
    color[200],
    color[200],
    color[300],
    color[400],
    color[400],
    color[400],
     color[500],
     color[500],
     color[600],
     color[600],
     color[700],
     color[800], color[900] ],}
}
export function headerColor(color) {

  return theme(color || grey)
}
