import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import {

  withStyles
} from "@material-ui/core";
import { setHeaderTitle, headCells, headerColor } from '../Utils'
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


export default function EnhancedTableHead(props) {
  const { order, orderBy, headersTooltips ,
          rowCount, onRequestSort,
          rows, permissionsGroupedHeaders } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const setTooltip = (info) => {

    return info && <React.Fragment>
    { Object.keys(info).map((item) =>
        <Typography color="inherit">{`${item}: ${info[item]}`}</Typography>
      )
    }
    </React.Fragment>

  }
  const StickyTableCell = withStyles((theme) => ({
    head: {
      left: 0,
      position: "sticky",
      backgroundColor: props.mainColor[100],
      zIndex: theme.zIndex.appBar + 2,

    },
    body: {
      backgroundColor: props.mainColor[100],

      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 1
    }
  }))(TableCell);
  return (
    <TableHead>
    <TableRow>
    {
      permissionsGroupedHeaders.map((header, i) =>
      i === 1
      ? <StickyTableCell align="center"

          sx={{color: i > 1 ? i > 2 ? 'white' : 'white' : 'transparent',
          backgroundColor: headerColor(props.mainColor).primary[i]
        }}
          colSpan={header[Object.keys(header)[0]].length > 0 ? header[Object.keys(header)[0]].length : 1}>

          {setHeaderTitle(Object.keys(header)[0])}
      </StickyTableCell>
      : <TableCell align="center"

          sx={{color: i > 1 ? i > 2 ? 'white' : 'white' : 'transparent',
          backgroundColor: headerColor(props.mainColor).primary[i]
        }}
          colSpan={header[Object.keys(header)[0]].length > 0 ? header[Object.keys(header)[0]].length : 1}>
        {setHeaderTitle(Object.keys(header)[0])}
      </TableCell>
      )
    }
    </TableRow>
      <TableRow>

        { permissionsGroupedHeaders.map((header, i) =>
            Object.keys(header)[0] === 'user'
            ? <StickyTableCell
              key={'user'}

              sx={{textAlign: 'center',
              color: i > 1 ? 'white' : 'black' ,

              backgroundColor: props.mainColor[100]
            }}

              align={'center'}
              sortDirection={orderBy === 'user' ? order : false}
            >
              <TableSortLabel
            active={orderBy === 'user'}
            direction={orderBy === 'user' ? order : 'asc'}
            onClick={createSortHandler('user')}

          >
            {setHeaderTitle('user')}
            {orderBy === 'user' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>

            </StickyTableCell>
            :
               headersTooltips && Object.keys(header)[0] !== 'pending' && header[Object.keys(header)[0]].length > 0
              ? header[Object.keys(header)[0]].map((headCell, j) =>
                <TableCell
                    key={headCell}

                    sx={{textAlign: 'center',

                    color: i > 1 ? 'white' : 'black' ,

                    backgroundColor: i > 1 ? headerColor(props.mainColor).primary[i] : props.mainColor[50]
                  }}

                    align={'center'}
                    sortDirection={orderBy === headCell ? headCell : false}
                  >
                   <Tooltip title={setTooltip(headersTooltips[setHeaderTitle(headCell)])}>
                    <TableSortLabel
                    active={orderBy === headCell}
                    direction={orderBy === headCell ? order : 'asc'}
                    onClick={createSortHandler(headCell)}
                  >
                    {setHeaderTitle(headCell)}
                    {orderBy === headCell ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel></Tooltip></TableCell>
                )

            : Object.keys(header)[0] === 'pending' ? <TableCell
              key={`${Object.keys(header)[0]}Key`}

              sx={{textAlign: 'center',

              color: i > 1 ? 'white' : 'black' ,

              backgroundColor: i > 1 ? headerColor(props.mainColor).secondary[i] : props.mainColor[50]
            }}

              align={'center'}
              sortDirection={orderBy === 'pending' ? 'user' : false}
            ><TableSortLabel
            active={orderBy === 'pending'}
            direction={orderBy === 'pending' ? order : 'asc'}
            onClick={createSortHandler('pending')}

          >
            {setHeaderTitle('pending')}
            {orderBy === 'pending' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel></TableCell> : null



        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  permissionsGroupedHeaders: PropTypes.array.isRequired
};
