import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { headerColor } from '../Utils'
import { indigo } from '@mui/material/colors';

const MAIN_COLOR = indigo

const headerTitle = (title) => {
  return title.split(/(?=[A-Z])/).join(' ').toUpperCase()
}
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const PermissionHeader = styled(Button)(({ theme }) => ({
  textAlign: 'left',
  height: 'fit-content',
  fontWeight: 800,
  color: 'black',
  minWidth: '200px'

}));
const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#1A2027 !important',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 900,
  borderRadius: 5,
  marginRight: '5px !important'

}));

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle, i, includedInOriginList) => ({
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : includedInOriginList ? headerColor(MAIN_COLOR).primary[i+1] : "lightgreen",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : 'rgb(231, 235, 240)',
  padding: grid,
  width: '-webkit-fill-available',
  display: 'flex'
});

export default function DragAndDropGroups(props) {
  const [ permissionsLists, setPermissionsLists ] = useState([]);
  const [ togglePermissionGroupMenu, setTogglePermissionGroupMenu ] = useState(null)
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ disabledUpdate, setDisabledUpdate ] = useState(true)
  const [ groups, setGroups ] = useState([])
  const [ toggleRefresh, setToggleRefresh ] = useState(props.toggleRefresh)


  const permissionGroupMenu = (group) => (event) => {
    if (group === togglePermissionGroupMenu) {
      setTogglePermissionGroupMenu(null)
      setAnchorEl(null)
    } else if (!togglePermissionGroupMenu || togglePermissionGroupMenu !== group ) {
      setTogglePermissionGroupMenu(group)
      setAnchorEl(event.currentTarget);
    }
  }
  const handleCloseMenu = () => {
    setTogglePermissionGroupMenu(null)
    setAnchorEl(null)
  }

  const handleNewSG = (group) => () => {
    props.handleNewSGRequest(group)
  }

  const handleRename = (group) => () => {
    props.handleRenameGroupRequest(group)
  }


  useEffect(() => {
    if (props.permissionsGroups && (permissionsLists.length === 0 || toggleRefresh !== props.toggleRefresh)) {
      let lists = []
      Object.keys(props.permissionsGroups).map((permission) => {
        let list = []
        Object.keys(props.permissionsGroups[permission]).map((sg) =>
          list.push({
            id: `item-${sg}`,
            title: sg,
            group: permission,
            content: props.permissionsGroups[permission][sg]})
        )
        lists.push(list)
      })
      setPermissionsLists(lists)
      setGroups(Object.keys(props.permissionsGroups))
    }
  })
  const update = () => {
    props.updatePermissions({permissionsLists, groups})
  }

  function onDragEnd(result) {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    // dragging item between lists ...
    if (draggableId.includes('item')) {


      if (sInd === dInd) {
        const items = reorder(permissionsLists[sInd], source.index, destination.index);
        const newPermissionsLists = [...permissionsLists];
        newPermissionsLists[sInd] = items;
        setPermissionsLists(newPermissionsLists);
        setDisabledUpdate(false)

      } else {
        const result = move(permissionsLists[sInd], permissionsLists[dInd], source, destination);
        const newPermissionsLists = [...permissionsLists];
        newPermissionsLists[sInd] = result[sInd];
        newPermissionsLists[dInd] = result[dInd];

        setPermissionsLists(newPermissionsLists);

        setDisabledUpdate(false)
      }
    } else {
      // dragging entire list...
      if (sInd !== dInd) {
        const newPermissionsLists = [...permissionsLists];
        const newGroups = groups
        const moveRow = newPermissionsLists[sInd];
        const moveGroup = newGroups[sInd];

        // console.log('MOVE ROW!', moveRow)
        newPermissionsLists[sInd] = newPermissionsLists[dInd];
        newGroups[sInd] = newGroups[dInd];

        newPermissionsLists[dInd] = moveRow;
        newGroups[dInd] = moveGroup;

        setPermissionsLists(newPermissionsLists);
        setGroups(newGroups);
        setDisabledUpdate(false)
      }
    }

  }

  return (
    <Box sx={{
        display: 'block',
        alignItems: 'baseline',
        padding: 'inherit',
        width: '100%',
        borderRadius: 5,
        height: '100%', backgroundColor: 'rgb(231, 235, 240)' }}>
          <Box sx={{ '& > :not(style)': { m: 1 }, display: 'flex' }} >
            <Tooltip followCursor={true} title={'Add New Permissions Group'} >
              <Fab size={"small"} color="secondary" aria-label="add" onClick={props.handleNewGroupRequest}>
                <AddIcon />
              </Fab>
            </Tooltip>
            <Button variant="contained" disabled={disabledUpdate} onClick={update}>Update</Button>
          </Box>




      <div >
        <DragDropContext onDragEnd={onDragEnd}>
        {!props.permissionsGroups
          ? <></>
          : permissionsLists.map((el, ind) => (
            <Droppable key={ind} droppableId={`${ind}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}

                >
                <Draggable
                  key={`${ind}Drag`}
                  draggableId={`${ind*10}DragId`}
                  index={ind}
                >
                  {(provided, snapshot) => (
                    <Item
                    elevation={ind}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      ind,
                      true
                    )}
                    sx={{width: '100%', background: '#e0e0e0'}}

                    >
                    <Box sx={{display: 'flex', background: '#e0e0e0', marginTop: '1em'}}>
                      <PermissionHeader onClick={permissionGroupMenu(groups[ind])}>{ headerTitle(groups[ind])}</PermissionHeader>
                      <Menu
                         id={`permissionGroupMenu${groups[ind]}`}
                         anchorEl={anchorEl}
                         open={togglePermissionGroupMenu === groups[ind]}
                         onClose={handleCloseMenu}
                         MenuListProps={{
                           'aria-labelledby': 'basic-button',
                         }}
                       >
                         <MenuItem onClick={handleRename(groups[ind])}>Rename</MenuItem>
                         <MenuItem onClick={handleNewSG(groups[ind])}>Add New SG</MenuItem>

                       </Menu>
                       {el.map((item, index) => (
                         <Draggable
                           key={item.id}
                           draggableId={item.id}
                           index={index}

                         >
                           {(provided, snapshot) => (
                             <Item
                               ref={provided.innerRef}
                               {...provided.draggableProps}
                               {...provided.dragHandleProps}
                               style={getItemStyle(
                                 snapshot.isDragging,
                                 provided.draggableProps.style,
                                 ind,
                                 props.permissionsGroups[groups[ind]] ? props.permissionsGroups[groups[ind]][item.title] : null
                               )}
                               sx={{marginTop: 'inherit !important' }}
                             >
                               <Box
                                 style={{
                                   display: "flex",

                                 }}
                               >
                               {headerTitle(item.title)}
                               </Box>
                             </Item>
                           )}
                         </Draggable>
                       ))}
                       </Box>
                    </Item>
                  )}
                </Draggable>

                  {provided.placeholder}
                </div>
              )
              }
            </Droppable>
          )
          )
        }
        </DragDropContext>
      </div>
    </Box>
  );
}


//
// <div >
//   <DragDropContext onDragEnd={onDragEnd}>
//     {!props.permissionsGroups
//     ? <></>
//     : permissionsLists.map((el, ind) => (
//       <Droppable key={ind} droppableId={`${ind}`}>
//         {(provided, snapshot) => (
//           <div
//             ref={provided.innerRef}
//             style={getListStyle(snapshot.isDraggingOver)}
//             {...provided.droppableProps}
//           > <Box>
//               <PermissionHeader onClick={permissionGroupMenu(groups[ind])}>{ headerTitle(groups[ind])}</PermissionHeader>
//               <Menu
//                  id={`permissionGroupMenu${groups[ind]}`}
//                  anchorEl={anchorEl}
//                  open={togglePermissionGroupMenu === groups[ind]}
//                  onClose={handleCloseMenu}
//                  MenuListProps={{
//                    'aria-labelledby': 'basic-button',
//                  }}
//                >
//                  <MenuItem onClick={handleRename(groups[ind])}>Rename</MenuItem>
//                  <MenuItem onClick={handleNewSG(groups[ind])}>Add New SG</MenuItem>
//
//                </Menu>
//             </Box>
//             {el.map((item, index) => (
//               <Draggable
//                 key={item.id}
//                 draggableId={item.id}
//                 index={index}
//               >
//                 {(provided, snapshot) => (
//                   <Item
//                     ref={provided.innerRef}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                     style={getItemStyle(
//                       snapshot.isDragging,
//                       provided.draggableProps.style,
//                       ind,
//                       props.permissionsGroups[groups[ind]] ? props.permissionsGroups[groups[ind]][item.title] : null
//                     )}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                       }}
//                     >
//                     {headerTitle(item.title)}
//                     </div>
//                   </Item>
//                 )}
//               </Draggable>
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     ))}
//   </DragDropContext>
// </div>
