import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function ConfirmUpdateDialog({ title, messages, handleConfirm, handleCancle, rows, permissionsGroupedHeaders }) {
  const [page, setPage] = React.useState(0);

  const RenderBool = (props) => {
    const { value } = props;
    return (
      value === true
      ? <IconButton disabled  aria-label="CheckIcon">
         <CheckIcon  />
       </IconButton>
      : <IconButton  disabled aria-label="CloseIcon">
         <CloseIcon  />
       </IconButton>
    )
  }

  const RenderCell = (props) => {
    const { value } = props;
    return (<Typography>
              {value}
            </Typography>)
  }
  const getColumns = () => {
    let columns = []

    permissionsGroupedHeaders.forEach((key) => {
      let header = Object.keys(key)[0]

      if (key[header].length === 0) {
        columns.push({ field: header, headerName: header, renderCell: RenderCell })
      } else {
        key[header].forEach((group) =>
          columns.push({ field: group, headerName: group, renderCell: RenderBool })
        )
      }

    })


    return columns

  }
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#B0BEC5',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{maxWidth: '1000px', bgcolor: 'background.paper' }}>
        <Dialog
          sx={{ '& .MuiDialog-paper': { minWidth: '1000px', maxHeight: 'fit-content' } }}
          maxWidth="xs"
          open
          >
          <DialogTitle
          sx={{
            backgroundColor: '#24292e',
            color: 'white'
          }}
          >{title}</DialogTitle>
            <IconButton
              key={'closeBtn'}
              aria-label="close"
              onClick={handleCancle}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

          <DialogContent dividers>
            { messages
              ? <DialogContentText>
                  The following errors prevenetd user IP permission to update on AWS :
                  {
                    messages.map((message) =>
                    <Typography gutterBottom>
                    {message}
                   </Typography>
                    )
                  }
                </DialogContentText>
              : <></>}
            <DataGrid
            initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              getRowId={(row: any) =>  Math.random()}
              rows={rows}
              page={page}
              disableSelectionOnClick
              columns={getColumns()}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={5}
              rowsPerPageOptions={[5]}
              pagination
            />
          </DialogContent>

          <DialogActions >
          <Stack spacing={{ xs: 1, sm: 2 }}
              sx={{display: 'contents'}}
              direction="row"
              useFlexGap
              flexWrap="wrap">
            <Box sx={{justifyContent: 'right', display: 'flex', width: '50%'}}>
              <Button
                key={'cancelBtn'}
                onClick={handleCancle}
                sx={{ mt: 1, mr: 1, height: 42 }}
                >
                Cancel
              </Button>
                <Button
                  key={'confrmBtn'}
                  variant="contained"
                  onClick={handleConfirm}
                  sx={{ mt: 1, mr: 1, height: 42  }}
                  >
                  Confirm
                </Button>
              </Box>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  )
}
