import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import BrowserRouterApp from './BrowserRouterApp'
import SamlSignIn from './components/Auth/SamlSignIn'


const App = (props) => {
  if (typeof(window) === 'undefined') {
  return <SamlSignIn {...props}/>
  } else {
    return (
      <BrowserRouter>
        <BrowserRouterApp {...props}/>
      </BrowserRouter>
    )
  }
}

export default App;
