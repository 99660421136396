import React, { useEffect, useState } from 'react';
import { fetchUserPermissions, updatePermissionsCall } from '../../../../models/UserAccess'
import AsyncSearch from '../../../common/AsyncSearch'
import { getComparator, stableSort, headCells, headerColor } from '../Utils'
import IMAlerts from '../../../common/IMAlerts'
import IMLoader from '../../../common/IMLoader'
import {
  withStyles
} from "@material-ui/core";

import HeaderActions from './HeaderActions'
import EnhancedTableHead from './EnhancedTableHead'
import SingleUserPermissions from './SingleUserPermissions'

import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { grey, orange, green, blueGrey, teal, red, yellow, purple, pink, deepPurple, indigo, amber, lightBlue, cyan, blue } from '@mui/material/colors';
import ConfirmUpdateDialog from './ConfirmUpdateDialog'

export default function UsersNetworkpermissionsTable(props) {
  const [ order, setOrder ] = useState('asc');
  const [ orderBy, setOrderBy ] = useState('user');
  const [ page, setPage ] = useState(0);
  const [ dense, setDense ] = useState(true);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ rows, setRows ] = useState(props.rows);
  const [ preGlobalFilteredRows, setPreGlobalFilteredRows ] = useState(props.rows);
  const [ refreshToggle, setRefreshToggle ] = useState(props.refreshToggle)
  const [ globalFilter, setGlobalFilter ] = useState(undefined)
  const [ filteredRows, setFilteredRows ] = useState([])
  const [ updatedPermissions, setUpdatedPermissions ] = useState([])
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ infoMessage, setInfoMessage ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ token, setToken ] = useState(props.token)
  const [ headersTooltips, setHeadersTooltips ] = useState(null)
  const [ usersById, setUsersById ] = useState(props.usersById)
  const [ retryPermission, setRetryPermission ] = useState(false)
  const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
  const [ confirmDialogProps, setConfirmDialogProps ] = useState(null);

  const MAIN_COLOR = indigo
  const DISABLED_COLOR = grey


  const onCancleDialog = () => {
    setPreGlobalFilteredRows(props.rows)
    setRows(props.rows)
    setUpdatedPermissions([])

    setOpenConfirmDialog(false)
    setConfirmDialogProps(null)
  }
  const onCloseDialog = () => {
    setUpdatedPermissions([])

    setOpenConfirmDialog(false)
    setConfirmDialogProps(null)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (!headersTooltips) {
      let newHeadersTooltips = {}
        Object.keys(props.securityInfo).forEach((sg) => {
          const sgTitle = sg.toLowerCase()
        		.trim()
        		.split(/[ -_]/g)
        		.map(word => word.replace(word[0], word[0].toString().toUpperCase()))
        		.join(' ')
          newHeadersTooltips[sgTitle] = {}
          newHeadersTooltips[sgTitle]['Aws Region'] = props.securityInfo[sg]['awsRegion']
          newHeadersTooltips[sgTitle]['Security Group Id'] = props.securityInfo[sg]['securityGroupId']
          newHeadersTooltips[sgTitle]['Protocol'] = props.securityInfo[sg]['protocol']
          newHeadersTooltips[sgTitle]['Port'] = props.securityInfo[sg]['port']
          newHeadersTooltips[sgTitle]['Account Name'] = props.securityInfo[sg]['awsAccount']['accountName']
        })
      setHeadersTooltips(newHeadersTooltips)
    }
    if (refreshToggle !== props.refreshToggle ) {
      setRefreshToggle(props.refreshToggle)
      // setPermissionsPending(props.permissionsPending)
      setPreGlobalFilteredRows(props.rows)
    }
  })


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onItemToggle = (updatedRow, key) => () => {
    if (!props.edit) {
      return
    }
    let updatedRows = []
    let updatedFilteredRows = []
    let newUpdatedPermissions = updatedPermissions

    rows.forEach((row) => {
        let newRow = Object.assign({}, row)
        if (newRow.user === updatedRow.user) {
          newRow[key] = !newRow[key]
          newRow['pending'] = true

          if (!newUpdatedPermissions.includes(updatedRow.user)) {
            newUpdatedPermissions.push(updatedRow.user)
          }
        }
        updatedRows.push(newRow)
      }
    )
    let updatedPreFilteredRows = []
    preGlobalFilteredRows.forEach((row) => {
        let newRow = Object.assign({}, row)
        if (newRow.user === updatedRow.user) {
          newRow[key] = !newRow[key]
          if (!newUpdatedPermissions.includes(updatedRow.user)) {
            newUpdatedPermissions.push(updatedRow.user)
          }

        }
        updatedPreFilteredRows.push(newRow)
      }
    )
    setUpdatedPermissions(newUpdatedPermissions)
    setPreGlobalFilteredRows(updatedPreFilteredRows)

    setRows(updatedRows)
  }
  const onRefreshRequest = async () => {
    setRetryPermission(true)
    await props.refeshPermissions({ disableLoader: true })
    // setPreGlobalFilteredRows(props.rows)
    setRetryPermission(false)
    // const repsponse = await synchPermissions()

  }
  const refreshPermission = () => {
    return retryPermission
            ? <Box sx={{ display: 'flex' }}>
                <CircularProgress  sx={{ color: orange[900], p: '1px', fontSize: '20px !important', width: '20px !important', height: '20px !important' }}/>
              </Box>
            : <ErrorOutlineIcon  sx={{ color: orange[900], p: '1px', fontSize: '20px !important' }}/>
  }
  const getPermissionMessage = (name) => {
    return `${Object.keys(name)[0]} : ${name[Object.keys(name)[0]] && typeof name[Object.keys(name)[0]] === 'string' ? name[Object.keys(name)[0]] : 'Aws Pending!'}`
  }
  const rowCellContent = (content, row, key) => {
    const disabled = props.userDisbaledByEmail[row.user] || !props.editAll || loading

    if (key === 'messages') { return }

    const userId = Object.keys(usersById).find((user) => usersById[user].email === row.user)

    if (key === 'pending') {
      const tooltipMessage = row['pending'] === true && row['messages']
            ? (row['messages']).map((name) => getPermissionMessage(name)).join(', ')
            : 'AWS pending'
      return content === true
        ? <Tooltip title={tooltipMessage}>
            <IconButton onClick={resyncUser(row)} sx={{ color: 'transparent', p: '1px' }} aria-label="CheckIcon">
              {refreshPermission()}
           </IconButton>
         </Tooltip>
        : <IconButton  sx={{ color: 'white', p: '1px', fontSize: '1px' }} aria-label="CloseIcon">
           f
         </IconButton>
    }


    return (typeof content === 'boolean')
      ? content === true
        ? <IconButton disabled={disabled} onClick={onItemToggle(row, key)} sx={{ color: 'transparent', p: '1px', fontSize: '1px' }} aria-label="CheckIcon">
           t<CheckIcon  sx={{ color: '#060808' }}/>
         </IconButton>
        : <IconButton  disabled={disabled} onClick={onItemToggle(row, key)} sx={{ color: 'transparent', p: '1px', fontSize: '1px' }} aria-label="CloseIcon">
           f<CloseIcon  sx={{ color: '#060808' }}/>
         </IconButton>
      : content

  }



  const calcRowsPerPage = () => {
    return stableSort(preGlobalFilteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  }

  const onUpdatePermissionsDialog = () => {

    const dialogProps = {
      title: `Update Users Permissions on AWS`,
      handleClose: onCloseDialog,
      handleConfirm: onSubmitNewPermissions,
      handleCancle: onCancleDialog,
      rows: rows.filter((row) => updatedPermissions.includes(row.user))

    }

    setConfirmDialogProps(dialogProps)
    setOpenConfirmDialog(true)
  }

  const resyncUser = (user) => () => {
    const onResynchUserPermissions = async () => {
      onCloseDialog()
      setLoading(true)
      const response = await updatePermissionsCall({ newPermissions: [user], token })
      const message = response.error ? response.error : response.success
      //
      setLoading(false)
      setAlertMessage(message)

      if (response.error) {
        setErrorMessage(true)
      } else {
        // onRefreshRequest()
      }



    }

    const dialogProps = {
      title: `Resync ${user.user} Permissions on AWS`,
      handleClose: onCloseDialog,
      handleCancle: onCancleDialog,
      handleConfirm: onResynchUserPermissions,
      rows: [user],
      messages: user['messages'].map((name) => getPermissionMessage(name))
    }


    setConfirmDialogProps(dialogProps)
    setOpenConfirmDialog(true)
  }



  const onResynchPermissions = async () => {

    setLoading(true)
    const synchPermissions = rows.filter((row) => row.pending === true)

    setLoading(false)


  }

  const onSubmitNewPermissions = async () => {
    if (updatedPermissions.length === 0) {
      return
    }

    setLoading(true)
    onCloseDialog()
    const newPermissions = rows.filter((row) => updatedPermissions.includes(row.user))
    setInfoMessage(true)
    setAlertMessage('Updating permissions in process')
    const response = await updatePermissionsCall({ newPermissions, token })
    const message = response.error ? response.error : response.success
    setInfoMessage(false)
    if (response.error) {
      setErrorMessage(true)
    } else {
      // onRefreshRequest()
    }
    setLoading(false)

    setAlertMessage(message)


  }
  const onCloseAlert = () => {
    setAlertMessage('')
    setErrorMessage(false)

  }


  const StickyTableCell = withStyles((theme) => ({
    head: {
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 2
    },
    body: {
      backgroundColor: MAIN_COLOR[100],
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 1
    }
  }))(TableCell);
  return (
    <Box sx={{ marginTop: '80px', position: 'relative' }}>
    {alertMessage &&
      <IMAlerts
        message={alertMessage}
        onClose={onCloseAlert}
        autoHideDuration={infoMessage ? 8000 : 6000}
        severity={errorMessage ? 'error' : infoMessage ? 'info' : 'success'}
      />}
      <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
        {rows.length === 1
        ? <SingleUserPermissions
            permissionsGroupedHeaders={props.permissionsGroupedHeaders}
            permissions={rows[0]}
          />

        : <TableContainer>(
          { openConfirmDialog && confirmDialogProps && Object.keys(confirmDialogProps).length > 0 &&
            <ConfirmUpdateDialog
              rows={confirmDialogProps.rows}
              handleConfirm={confirmDialogProps.handleConfirm}
              handleCancle={confirmDialogProps.handleCancle}
              title={confirmDialogProps.title}
              messages={confirmDialogProps.messages}
              permissionsGroupedHeaders={(props.permissionsGroupedHeaders).filter((header) => Object.keys(header)[0] !== 'pending')}
            />}
          {props.readAll && <HeaderActions
            onUpdatePermissions={onUpdatePermissionsDialog}
            refreshEnable={true}
            synchEnable={false}
            onRefreshPermissions={onRefreshRequest}
            onSynchPermissions={onResynchPermissions}
            disbaleUpdatePermissions={updatedPermissions.length === 0}
            rows={rows}
            enableUpdate={props.edit}
            filteredRows={filteredRows}
            setFilteredRows={setFilteredRows}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setPreGlobalFilteredRows={setPreGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />}
          <Table
            sx={{ maxWidth: 750,
            '& .MuiIconButton-root': {
              fontSize: '1px'
            }}}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              mainColor={MAIN_COLOR}
              headersTooltips={headersTooltips}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              rows={rows}
              permissionsGroupedHeaders={props.permissionsGroupedHeaders}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {calcRowsPerPage().map((row, index) => {
                  // const isItemSelected = isSelected(row.user);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Tooltip followCursor={true} title={loading ? 'Update Permissions in process' : !props.editAll ? '' : props.userDisbaledByEmail[row.user] ? 'User must be Enabled! ': ''}><TableRow
                      hover
                      sx={{ cursor: 'not-allowed', display: filteredRows.length === 0 || filteredRows.includes(row.user) ? 'table-row': 'none',
                      '&:hover': {
                                backgroundColor:  MAIN_COLOR[50],
                                opacity: [0.9, 0.8, 0.7],
                              },
                      }}


                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                    <TableCell

                      sx={{textAlign: 'center',

                        backgroundColor: MAIN_COLOR[50]
                      }}
                      align="left">
                      {rowCellContent(row['pending'], row, 'pending')}
                    </TableCell>

                      {
                        props.permissionsGroupedHeaders.map((header, i) =>
                          Object.keys(header)[0] === 'user'
                          ?<StickyTableCell
                              sx={{textAlign: 'center',

                                backgroundColor: MAIN_COLOR[100]
                              }}

                              align="left">
                              {rowCellContent(row.user, row, 'user')}
                          </StickyTableCell>
                          : header[Object.keys(header)[0]].map((headCell, j) =>
                            <TableCell

                              sx={{textAlign: 'center',

                                backgroundColor: props.userDisbaledByEmail[row.user] ?  MAIN_COLOR[50] : headerColor(MAIN_COLOR).primary[i]
                              }}
                              align="left">
                              {rowCellContent(row[headCell], row, headCell)}
                            </TableCell>
                          )
                        )
                      }
                    </TableRow>
                    </Tooltip>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  hover
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>}
        {rows.length > 1 && <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </Paper>
    { rows.length > 1 &&  false && <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />}
    </Box>
  );
}
