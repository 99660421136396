import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export default function RenameGroupForm(props) {
  const [ open, setOpen ] = React.useState(true);
  const [ renamedGroup, setRenamedGroup ] = React.useState('');

  const headerTitle = (title) => {
    return title.split(/(?=[A-Z])/).join(' ').toUpperCase()
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onCloseDialog()
  };

  const handleChange = (event) => {
   setRenamedGroup(event.target.value);
  };

   const onRenameGroup = () => {
     props.renamePermissionGroup({ permissionName: props.permissionGroup, newPermissionName: renamedGroup })
   }

  return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
        sx={{
          backgroundColor: '#24292e',
          color: 'white'
        }}
        >{`Rename Permission Group: ${headerTitle(props.permissionGroup)}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <Stack
            sx={{width: '-webkit-fill-available'}}
            divider={<Divider orientation="horizental" flexItem />}
            spacing={2}
          >
            <TextField
              focused
              autoFocus={true}
              margin="dense"
              id={`GroupIdField`}
              label={`Renamed Group Name`}
              fullWidth
              variant="standard"
              onChange={handleChange}
            />
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onRenameGroup}>Rename Group</Button>
        </DialogActions>
      </Dialog>
  );
}
