import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(sg, sgAttached, theme) {
  return {
    fontWeight:
      sgAttached.indexOf(sg.groupName) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function NewGroupForm(props) {
  const theme = useTheme();

  const [ open, setOpen ] = useState(true);
  const [ group, setGroup ] = useState('');
  const [ sgAttached, setSgAttached ] = useState([]);
  const [ disableConfirm, setDisableConfirm ] = useState(true)


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onCloseDialog()
  };

  const handleChange = (event) => {
    const val = event.target.value
    const timer = setTimeout(() => {
      setGroup(val);
      setDisableConfirm(val.length < 3 || sgAttached.length === 0)
    }, 1000);
    return () => clearTimeout(timer);


  };

   const onNewGroupCreated = () => {
     props.onNewGroupCreated({ group, sgAttached })
   }

   const handleSGChange = (event) => {
    const {
      target: { value },
    } = event;
    setSgAttached(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setDisableConfirm(group.length < 3 || value.length === 0)

  };


  return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
        sx={{
          backgroundColor: '#24292e',
          color: 'white'
        }}
        >{`New Permission Group`}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <Stack
            sx={{width: '-webkit-fill-available'}}
            spacing={2}
          >
            <TextField
              focused
              autoFocus={true}
              margin="dense"
              id={`GroupIdField`}
              label={`Group Name`}
              fullWidth
              variant="standard"
              onChange={handleChange}
            />
            <FormControl sx={{ m: 1 }}>
             <InputLabel
               sx={{
                 '&.MuiInputLabel-outlined': {
                   transform: 'translate(19px, 10px) scale(1)'
                 }
               }}
               id="multiple-chip-label-sgAttached">
              Secuirty Groups</InputLabel>
             <Select
               labelId="multiple-chip-label-sgAttached"
               id="gAttached-chip"
               multiple
               value={sgAttached}
               onChange={handleSGChange}
               input={<OutlinedInput id="select-multiple-chip-sgAttached" label="Secuirty Groups" />}
               renderValue={(selected) => (
                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                   {selected.map((value) => (
                     <Chip key={value} label={value} />
                   ))}
                 </Box>
               )}
               MenuProps={MenuProps}
             >
               {props.permissionGroups.map((sg) => (
                 <MenuItem
                   key={sg.groupName}
                   value={sg.groupName}
                   style={getStyles(sg, sgAttached, theme)}
                 >
                   {sg.groupName}
                 </MenuItem>
               ))}
             </Select>
             <FormHelperText>Must attach at least one Permission</FormHelperText>
           </FormControl>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onNewGroupCreated} disabled={disableConfirm}>Create New Group</Button>
        </DialogActions>
      </Dialog>
  );
}
