import React, { useEffect, useState } from 'react';
import { getPgDbUsersAccess, newPgUser } from '../../../models/DatabaseAccess'

import styled from 'styled-components'
import AsyncSearch from '../../common/AsyncSearch'

import NewPGUser from './NewPGUser'
import IMAlerts from '../../common/IMAlerts'
import IMDataGrid from '../../common/IMDataGrid'
import IMLoader from '../../common/IMLoader'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useGridApiContext } from '@mui/x-data-grid';
import Select from '@mui/material/Select';

const Styles = styled.div`
  padding: 1rem;

  .MuiPaper-root {
    background-color: #fff !important
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

`

export default function AllDBUsers(props) {

  const [ currentUser, setCurrentUser ] = useState(props.currentUser)

  const [ rows, setRows ] = useState(null);
  const [ columns, setColumns ] = useState(null)

  const [ loading, setLoading ] = useState(true)
  const [ usersDBAccess, setUsersDBAccess] = useState(null)
  const [ allDbs, setAllDbs ] = useState(props.allDbs)
  const [ selectedDb, setSelectedDb ] = useState(null)
  const [ changeRow, setChangeRow ] = useState(null);
  const [ newPGUser, setNewPGUser ] = useState({});
  const [ dbsOptions, setDbsOptions ] = useState(props.dbsOptions)

  const addNewPGUser = () => {
    console.log('newPGUser', newPGUser)
    setNewPGUser({})
  }
  const onEditingNewUser = (fieldVal) => {
    console.log('onEditingNewUser', newPGUser)
    let pgUser = newPGUser
    const field =
    Object.keys(fieldVal).forEach((field) =>  {
      pgUser[field] = fieldVal[field]
    })

    setNewPGUser(pgUser)
  }

  const userFields = () => {
    return [ 'dbName', 'name' ]
  }
  const onItemToggle = (updatedRow) => () => {
    console.log('CLICK ', updatedRow)
  }
  const RoleCell = (props) => {
    const { value } = props;

    return (
      value === true
      ? <IconButton aria-label="CheckIcon" >
         <CheckIcon  color={'primary'}/>
       </IconButton>
      : <IconButton aria-label="CloseIcon" >
         <CloseIcon  color={'error'}/>
       </IconButton>
    )
  }
  const  RoleEditInputCell = (props) => {

    const { id, value, field, row } = props;
    const [ rowVal, setRowVal ] = useState(row[field]);

     // const currentRoleVal = rows.filter((row) => row.id === id).field
     const apiRef = useGridApiContext();

     const handleChange = async (event) => {
       // setChangeRow({[id]: { [field]: event.target.value}})

       setRowVal(event.target.value);
       // row[field] = event.target.value
       // await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
       // apiRef.current.stopCellEditMode({ id, field });
     };
     useEffect(() => {
       if (rowVal !== row[field]) {
         setChangeRow({[id]: { [field]: rowVal}})
       }
     })

     return (
       <Select
         value={rowVal}
         onChange={handleChange}
         size="small"
         sx={{ height: 1 }}
         native
         autoFocus
       >
         <option value={true}>True</option>
         <option value={false}>False</option>
       </Select>
     );
  }
  const renderRoleEditInputCell = (params) => {
    return <RoleEditInputCell {...params} setChangeRow={setChangeRow}/>;
  };
  const setupGrid = ({ users, selected }) => {

    console.log('ALL USERS ', users)
    let dbGridRows = []
    Object.keys(users).forEach((user_id) => {

      let row = {}
      row['id'] = users[user_id]['dbName']
      row['name'] = users[user_id]['name']
      row['dbName'] = users[user_id]['dbName']
      selected.dbRoles.forEach((role) => {
        row[role] = users[user_id]['roles'].includes(role)
      })
      dbGridRows.push(row)
    })
    setRows(dbGridRows)

    let newColumns = [
      { field: 'name',
        align: 'left',
        headerName: 'Name',
        width: 180,
        editable: false },
      { field: 'dbName',
        align: 'left',
        headerName: 'DB User Name',
        width: 180,
        editable: false }
    ]
    selected.dbRoles.forEach((role) => {
      newColumns.push({
        field: role,
        align: 'left',
        headerName: role,
        width: 180,
        editable: true,
        renderCell: RoleCell ,
        renderEditCell: renderRoleEditInputCell

      })

    })
    setColumns(newColumns)


  }


  useEffect(() => {
    if (!rows && allDbs && allDbs[1]) {
      setRows([])
      onSelectDb(allDbs[1].dbName)
    }

  })

  const fetchDBPGUsers = async (db) => {

    setLoading(true)
    const response = await getPgDbUsersAccess({ dbName: db.dbName })
    if (response.status === 200) {
      setUsersDBAccess(response.allUsersAccess)
      setupGrid({ users: response.allUsersAccess, selected: db })
    } else {
      if (response.status !== 401) {
        props.unAuthCallback(response)
      } else {
        // ERROR FETCHING....
      }
      setRows(null)
    }
    // setDialogProps(null)
    setLoading(false)
  }

  const onSelectDb = (db) => {
    if (!db) {
      setSelectedDb(null)
      setRows([])
      setColumns(null)
    } else {
      // console.log('ON SEL ', db)
      const selected = allDbs.filter((dbProps) => dbProps.dbName == db)[0]
      setSelectedDb(selected)
      fetchDBPGUsers(selected)
    }

  }

  const onAddRow = async (params) => {

  }
  const onDelete = (user) => {

  }

  const editUserRow =  async (editUserAppParams) => {
// // { id, email, name, role, disabled, restrictedRole, countryRoleAccess, applicationId, applicationName, personalInformationRestriction }
//     editUserAppParams['applicationName'] = applicationName
//     const response = await editAppUser(editUserAppParams)
//
//     const message = response.error ? response.error : 'Edit User successfully'
//
//     return { message, ...response  }
  }

  const handleEditClick = (id) => () => {
    // const current = props.users.filter((user) => user.id === id)[0]
    // setEditCurrentUser(current)
    // // setEditDialogOpen(true)
    //
    // // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const onClear = () => {
    setSelectedDb(null)
  }


  return (
    <Styles >

      <Box
        sx={{ display: 'block', height: '100%', width: '100%',
        display: 'block',
        float: 'left' }}
      >
      <AsyncSearch
          key={`asyncSearchForDB`}
          width={'100%'}
          onClear={onClear}
          onSelected={onSelectDb}
          options={dbsOptions}
          searchTitle={'Search Database Access'}/>
      <React.StrictMode>
        {
          loading || !rows
          ? <IMLoader message={'Loading DB Details...'} mini/>
          : <>{selectedDb && <IMDataGrid
              rows={rows}
              currentUser={props.currentUser}
              users={rows}
              editAll={true}
              columns={columns}
              actions={false}
              refresh={loading}
              color={props.color}
              userFields={userFields}
              editUser={editUserRow}
              addNew={onAddRow}
              ipermRoles={[]}
              actions={true}
              internalEditing={true}
              changeRow={changeRow}
              gridTitle={selectedDb.dbName}
              newRowCustomDialogField={<NewPGUser
                  onChange={onEditingNewUser}
                  users={props.users}

              />}
              newRowCustomDialogConfirm={addNewPGUser}
            />}</>

        }
        </React.StrictMode>
      </Box>
    </Styles>
  );
}
