import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import styled from 'styled-components'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { styled as styledMUI } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import PGSrc from '../../../assets/postgres-icon.png';
import RedshiftSrc from '../../../assets/redshift.png';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import CloudSyncIcon from '@mui/icons-material/CloudSync';

import NewEditDBForm from './NewEditDBForm'
import ReactiveButton from 'reactive-button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import EditDBForm from './EditDBForm'

import { grey, orange, green, blueGrey, teal, red, yellow, purple, pink, deepPurple, indigo, amber, lightBlue, cyan, blue } from '@mui/material/colors';
const MAIN_COLOR = indigo


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, action, theme) {
  return {
    fontWeight:
      action.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const Item = styledMUI(Paper)(({ theme }) => ({

  backgroundColor: 'transparent !important',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'white',
}));
const BtnContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}))

function NewRole(props) {
  const [ validRole, setValidRole ] = useState(false)
  const [ newRole, setNewRole ] = useState('')
  const [ actions, setActions ] = React.useState([]);
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setActions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleValidation = (e) => {

    const val = e.target.value

    setValidRole(/^[a-z]+_*[a-z]+$/.test(val))

    setNewRole(e.target.value);
    props.setNewRole(e.target.value);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={5}
      sx={{width: 500, height: 300}}
    >
    <TextField
        required
        key={'dbRole'}
        id="dbrole-required"
        label="DB Role"
        variant="standard"
        onChange={handleValidation}
        error={!validRole}
        helperText="Valid Role name must be in snakecase"
      />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
       <InputLabel
       sx={{
          display: 'flex',
          position: 'inherit',
          justifyContent: 'center',
       }}
       variant="standard"
       id="role-action-label">Actions</InputLabel>
       <Select
         labelId="actions-label"
         id="action-multiple-name"
         multiple
         value={actions}
         onChange={handleChange}
         MenuProps={MenuProps}
         variant="standard"
       >
         {['SELECT','INSERT','DELETE','UPDATE'].map((action) => (
           <MenuItem
             key={action}
             value={action}
             style={getStyles(action, actions, theme)}
           >
             {action}
           </MenuItem>
         ))}
       </Select>
     </FormControl>
      </Stack>
  )
}

export default function DBInfo(props) {
  const [ mount, setMount ] = useState(false);
  const [ refresh, setRefresh ] = useState(props.refresh);
  const [ open, setOpen ] = React.useState(-1);
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ validRole, setValidRole ] = useState(false)
  const [ newRoles, setNewRoles ] = useState([])
  const [ defaultRoles, setDefaultRoles ] = useState([])
  const [ disableUpdate, setDisableUpdate ]  = useState(true)
  const [ dbUpdate, setDbUpdate ] = useState('')

  const onRoleChange = ({newRoles, newDefaultRoles, db}) => {
    setDbUpdate(db)
    setNewRoles(newRoles)
    setDefaultRoles(newDefaultRoles)
    setDisableUpdate(newRoles.length === 0 || newDefaultRoles.length === 0)
  }

  const onDefaultRolesChange = ({newRoles, newDefaultRoles, db}) => {
    setDbUpdate(db)
    setDefaultRoles(newDefaultRoles)
    setNewRoles(newRoles)
    setDisableUpdate(newRoles.length === 0 || newDefaultRoles.length === 0)
  }

  const handleClickOpen = (item, add = false) => (event) => {


     event.stopPropagation();
      if (add) {
        let propsDialog = {}
        propsDialog['title'] = `Add New Role : ${item}`
        propsDialog['content'] = () =>  <NewRole setNewRole={setNewRoles}/>


        setDialogProps(propsDialog)
        setOpen(item);
      } else {
        let propsDialog = {}
        propsDialog['title'] = item


        propsDialog['content'] = () => <EditDBForm
          db={props.dbs[item]}
          allRoles={props.dbRoles}
          onDefaultRolesChange={onDefaultRolesChange}
          onRoleChange={onRoleChange}/>


        setDialogProps(propsDialog)
        setOpen(item);

      }

  };


  const handleClose = () => {
    setOpen(-1);
    setDialogProps(null)
    setDbUpdate('')
    setNewRoles([])
    setDefaultRoles([])
    setDisableUpdate(true)

  };

  const handleConfirm = () => {
    props.confirmUpdateDb({ newRoles, defaultRoles, dbUpdate })
    handleClose()
  }


  useEffect(() => {
    if (!mount || refresh !== props.refresh) {
      setMount(true)
    }
  })
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size/2 * cols}&h=${
        size/2 * rows
      }&fit=crop&auto=format&dpr=2 2x`,
      'max-width': '50px'
    };
  }

  return (
    <Box sx={{ height: 600, marginTop: 10 }}>
    <Grid container spacing={0.5} >
      {Object.keys(props.dbs).map((item, i) =>

        <Grid item xs={3} key={`gridItemKey${item}-${i}`}>

        <ReactiveButton
         key={`btnAwesomeReactBtn${item}`}
         buttonState={'idle'}

         idleText={
            <BtnContainer onClick={handleClickOpen(item)}>
              <Box sx={{marginRight: '4px', display: 'flex'}}>{
                props.dbs[item].engine === 'redshift'
                ? <img
                srcSet={`${RedshiftSrc}?w=50&h=50&fit=crop&auto=format&dpr=2 8x`}
                src={`${RedshiftSrc}?w=50&h=50&fit=crop&auto=format`}
                alt={item}
                loading="lazy"
              />
                : <img
                srcSet={`${PGSrc}?w=50&h=50&fit=crop&auto=format&dpr=2 4x`}
                src={`${PGSrc}?w=50&h=50&fit=crop&auto=format`}
                alt={item}
                loading="lazy"
              />}</Box><span> {item}</span>
              <Fab
                onClick={handleClickOpen(item, true)}
               variant="standard" aria-label="add"
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: '40px',
                  height: '40px',
                  zIndex: 999999
                }}>
                <AddIcon />
              </Fab>
            </BtnContainer>
          }
         type={'button'}
         style={{
           borderRadius: '5px',
           // height: 44

         }}
         color={'blue'}
         outline={true}
         shadow={true}
         rounded={false}
         size={'normal'}
         block={false}
         messageDuration={2000}
         disabled={false}
         buttonRef={null}
         width={null}
         height={null}
         animation={true}
       />
    </Grid>
    )

    }
      </Grid>
      {dialogProps && <Dialog
        open={open !== -1}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
              backgroundColor: '#24292e',
              color: 'white'
          }}
          id={'editViewDialog'}>
          {dialogProps.title}
          {dialogProps.titleIcon && <Fab

           color="primary" aria-label="add"
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              width: '40px',
              height: '40px',
              zIndex: 999999
            }}>{dialogProps.titleIcon()}</Fab>}
        </DialogTitle>
        <DialogContent>

          <DialogContentText id="alert-dialog-description">
          {dialogProps.content()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>Cancel</Button>
          <Button disabled={disableUpdate} color="primary" onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>}

     </Box>
  );
}
