import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import styled from 'styled-components';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Container = styled.div`
  color: transparent;
`;

export default function AsyncSearch(props) {
  const [ open, setOpen ] = useState(false);
  const [ options, setOptions ] = useState([]);
  const [ refresh, setRefresh ] = useState(false)
  const loading = open && (options.length === 0 || refresh !== props.refresh);


  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setRefresh(props.refresh)
        setOptions(props.options);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChange = (event, value, reason) => {
    if (props.onSelected && reason !== 'clear') {
      props.onSelected(value.option)
    }
  }
  const onInputChange = (event, value, reason) => {
    if (reason === 'clear' && props.onClear) {
      props.onClear()
      props.onSelected(null)
    }
  }
  return (
    <Autocomplete
      includeInputInList
      id="asynchronous-search"
      sx={props.sx ? props.sx : { width: props.width ?  props.width : 500 }}
      onInputChange={onInputChange}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      multiple={false}
      groupBy={(option) => option.groupBy}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

          {option.value}   <Container> {`${option.id} ${option.groupBy}`}</Container>
        </Box>
      )}
      getOptionLabel={(option) => `${option.value} `}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', '& .MuiTextField-root': { m: 1, width: '100%' } }}
          >
          <TextField
            fullWidth
            {...params}
            label={props.searchTitle || 'Search...'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
         <IconButton sx={{ p: '10px' }} aria-label="searchIcon">
            <SearchIcon />
          </IconButton>
     </Paper>
      )}
    />
  );
}
