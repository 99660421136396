import React, { useEffect } from 'react';
import {
  Link as RouterLink,
  Route,
  Routes,
  MemoryRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client'
import useToken from "./hooks/useToken";
import SamlSignIn from './components/Auth/SamlSignIn'
import { signSamlIn } from './models/Auth'

import UserPermissions from './components/UserPermissions'

function BrowserRouterApp(props) {
  const { token, setToken, clearToken } = useToken();
  const [ jwt, setJwt ] = React.useState('');
  const [ usersResponse, setUsersResponse ] = React.useState(null);
  const [ loggedIn, setLoggedIn ] = React.useState(false)
  const navigate = useNavigate();

  const location = useLocation();


  useEffect(() => {

  }, []);

  const callbackSaml = (response) => {
    if (response.user) {
      setLoggedIn(true)
      setUsersResponse(response)
    }
  }
  const callBackLoggedIn = () => {
    setLoggedIn(true)
  }

  const UserPermissionsRoutes = (props) => (
    <main>
      <Routes>
        <Route  path='/' element={<UserPermissions {...props} token={token} clearToken={clearToken} usersResponse={usersResponse} loggedIn={loggedIn}/>}/>
        <Route  path='/saml' element={<SamlSignIn {...props} token={token} setToken={setToken} clearToken={clearToken} jwt={jwt} callbackSaml={callbackSaml} callBackLoggedIn={callBackLoggedIn}/>} />
        <Route  path="*" element={<UserPermissions {...props}  usersResponse={usersResponse} clearToken={clearToken}/>} />
        <Route  path='*path' element={<UserPermissions {...props}  usersResponse={usersResponse} clearToken={clearToken}/>}/>
      </Routes>
    </main>
  )

  const UserSamlRoutes = (props) => (
    <main>
      <Routes>
        <Route  path='/saml' element={<SamlSignIn {...props} token={token} setToken={setToken} clearToken={clearToken} jwt={jwt} callbackSaml={callbackSaml} callBackLoggedIn={callBackLoggedIn}/>} />
        <Route  path='/' element={<UserPermissions {...props} token={token} clearToken={clearToken} usersResponse={usersResponse}/>}/>
        <Route  path="*" element={<UserPermissions {...props}  usersResponse={usersResponse} clearToken={clearToken} />} />
        <Route  path='*path' element={<UserPermissions {...props}  usersResponse={usersResponse} clearToken={clearToken}/>}/>
      </Routes>
    </main>
  )

  return (  <div>
    {  window.location.hostname.includes('localhost')
        ? <UserPermissionsRoutes {...props}  token={token} clearToken={clearToken} setToken={setToken}/>
        : <UserSamlRoutes {...props}  token={token} clearToken={clearToken} setToken={setToken}/>
}    </div>)
}
export default BrowserRouterApp;
