
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IMHeader from './common/IMHeader'
import SideExpandedTabs from './common/SideExpandedTabs'
// import UsersAccessLevels from './UserAccess/Users/UsersAccessLevels'
import AccessLevels from './UserAccess/Users/AccessLevels'

import UserNetworkNavigation from './UserAccess/NetworkPermissions/UserNetworkNavigation'
// import PGUsers from './DatabaseAccess/PGUsers'
// import PGApplicationsUsers from './DatabaseAccess/PGApplicationsUsers'
// import PGAccess from './DatabaseAccess/PGAccess'
import PostgresUsersAccess from './DatabaseAccess/PGUsersAccess/MainContainer'
import{ default as UsersApplicationAccess} from './ApplicationAccess/UsersAccess'
// import ApplicationsAccess from './ApplicationAccess/ApplicationsAccess'
import SSOUnauth from './Auth/SSOUnauth'

import {

  useLocation,
} from 'react-router-dom';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PermissionsRolesSettings from './InternalConfigs/PermissionsRolesSettings'
import { assumeRole, getUsers } from '../models/UserAccess'
import { signIn } from '../models/Auth'
import { getUserNotifications } from '../models/UserNotifications'
import { useNavigate } from "react-router-dom";

// import IMLoader from './common/IMLoader'
import IMAlerts from './common/IMAlerts'


const TabsContainer = styled.div`
  width: 100%;
  z-index: -9 !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 1100;
  position: fixed;
  top: 81px;
  outline: 0px;
  // left: 8px;
  // background-color: rgb(17, 24, 39);
  border-right: 0px solid rgb(230, 232, 240);
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  .MuiPaper-root {
    background-color: inherit;
    form {
      background-color: #fff;
  }


  }
  .MuiAlert-standardSuccess {
    color: rgb(30, 70, 32) !important;
    background-color: rgb(237, 247, 237) !important;
  }
  .MuiAlert-standardError {
    color: rgb(97, 26, 21) !important;
    background-color: rgb(253, 236, 234) !important;
  }
  .MuiAlert-standardInfo {
    color: rgb(13, 60, 97) !important;
    background-color: rgb(232, 244, 253) !important;
  }
  .MuiAlert-standardWarning {
    color: rgb(102, 60, 0) !important;
    background-color: rgb(255, 244, 229) !important;
  }
  .MuiInputBase-root {
    height: 5ch;
  }
`




// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const UserPermissions = (props) => {
  const ALLOWED_TESTER = props.env === 'production'
    ? [ 'sharona@influencemobile.com' ]
    : [ 'sharona@influencemobile.com', 'dev@influencemobile.com', 'bill@influencemobile.com', 'megan@influencemobile.com', 'jozias@influencemobile.com' ]
  const V2_ALLWOED = props.env === 'production'
    ? [ 'sharona@influencemobile.com' ]
    : [ 'sharona@influencemobile.com', 'dev@influencemobile.com', 'jozias@influencemobile.com', 'bill@influencemobile.com', 'megan@influencemobile.com' ]


  const [ loading, setLoading ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(false)
  const [ edit, setEdit ] = useState(false)
  const [ editAll, setEditAll ] = useState(false)
  const [ readAll, setReadAll ] = useState(false)
  const [ read, setRead ] = useState(false)
  const [ encrypt, setEncrypt ] = useState(false)
  const [ loggedInIp, setLoggedInIp ] = useState('')
  const [ encryptAll, setEncryptAll ] = useState(false)
  const [ users, setUsers ] = useState([])
  const [ currentUser, setCurrentUser ] = useState(null)
  const [ usersById, setUsersById ] = useState({})
  const [ refreshToggle, setRefreshToggle ] = useState(false)
  const [ notifications, setNotifications ] = useState([])
  const [ env, setEnv ] = useState('')
  const [ excludedTabs, setExcludedTabs ] = useState(null)
  const [ csrf, setCsrf ] = useState(null)
  const [ unAuth, setUnAuth ] = useState(false)
  const [ testSaml, setTestSaml ] = useState(null)
  const [ ipermRoles, setIpermRoles ] = useState([])


  const navigate = useNavigate();

  const tabsVisibility = () => {
    return currentUser && ALLOWED_TESTER.includes(currentUser.email)
     ? {
      'MasterConfigs': editAll,
      // && !excludedTabs['Internal Master Configs'],
      'Users': true,
      'DatabaseAccess': V2_ALLWOED.includes(currentUser.email),
      'ApplicationAccess': V2_ALLWOED.includes(currentUser.email)
    }
    : {
     'MasterConfigs': false,
     // && !excludedTabs['Internal Master Configs'],
     'Users': true,
     'DatabaseAccess': false,
     'ApplicationAccess': false
   }
  }

  const tabsTitles = () => {

    let titles =  {
      'MasterConfigs': ['PermissionsRolesSettings'],
      'Users': ['UserAccount', 'NetworkAccess'],
      'DatabaseAccess': ['PostgresUserAccess'],
      'ApplicationAccess': ['Users'],

    }
    let premitted = {}
    Object.keys(titles).forEach((title) => {
      const titleExclude = title.charAt(0).toLowerCase() + title.slice(1);

      if (!excludedTabs[titleExclude]) {
        titles[title].forEach((inner) => {
          const innerTitleExclude = inner.charAt(0).toLowerCase() + inner.slice(1);

          if (!excludedTabs[innerTitleExclude]) {
            if (!premitted[title]) {
              premitted[title] = []
            }
            premitted[title].push(inner)
          }
        })
      }
    })
    return premitted;
  }

  const onUpdatingUsers = (newUsers) => {
    setUsers(newUsers)
    setRefreshToggle(!refreshToggle)
  }
  const onEditUser = (newUsers) => {
    setUsers(newUsers)
    setRefreshToggle(!refreshToggle)
  }

  const refresh = () => {
    setupUsers()
  }


  const tabsTitlesContent = (props) => {
    return {
      'UserAccount': <AccessLevels
                              users={users}
                              onUpdatingUsers={onUpdatingUsers}
                              currentUser={currentUser}
                              onEditUser={onEditUser}
                              edit={edit}
                              editAll={editAll}
                              readAll={readAll}
                              encrypt={encrypt}
                              loggedInIp={loggedInIp}
                              usersById={usersById}
                              refreshUsers={refresh}
                              refreshToggle={refreshToggle}
                              unAuthCallback={unAuthResponse}
                              env={env}
                              setAlertMessage={setAlertMessage}
                              onCloseAlert={onCloseAlert}
                              setErrorMessage={setErrorMessage}
                              ipermRoles={ipermRoles}
                              notificationsRefreshed={notificationsRefreshed}
                              token={props.token}/>,
      'NetworkAccess': <UserNetworkNavigation
                                env={env}
                                token={props.token}
                                unAuthCallback={unAuthResponse}
                                users={users}
                                edit={edit}
                                editAll={editAll}
                                encrypt={encrypt}
                                loggedInIp={loggedInIp}
                                refreshToggle={refreshToggle}
                                usersById={usersById}
                                setAlertMessage={setAlertMessage}
                                onCloseAlert={onCloseAlert}
                                setErrorMessage={setErrorMessage}
                                notificationsRefreshed={notificationsRefreshed}
                                currentUser={currentUser} />,
            'PostgresUserAccess': <PostgresUsersAccess
                                users={users}
                                currentUser={currentUser}
                                edit={edit}
                                editAll={editAll}
                                encrypt={encrypt}
                                loggedInIp={loggedInIp}
                                usersById={usersById}
                                token={props.token}
                                refreshToggle={refreshToggle}
                                refreshPage={refresh}
                                unAuthCallback={unAuthResponse}
                                env={env}
                                setAlertMessage={setAlertMessage}
                                onCloseAlert={onCloseAlert}
                                setErrorMessage={setErrorMessage}
                                notificationsRefreshed={notificationsRefreshed}
                                />,


'PermissionsRolesSettings':  <PermissionsRolesSettings
                                token={props.token}
                                currentUser={currentUser}
                                unAuthCallback={unAuthResponse}
                                env={env}
                                setAlertMessage={setAlertMessage}
                                setErrorMessage={setErrorMessage}
                                onCloseAlert={onCloseAlert}
                                notificationsRefreshed={notificationsRefreshed}
                                />,

              'Users': <UsersApplicationAccess
                                currentUser={currentUser}
                                ipermRoles={ipermRoles}
                                token={props.token}
                                edit={edit}
                                read={read}
                                editAll={editAll}
                                unAuthCallback={unAuthResponse}
                                env={env}
                                setAlertMessage={setAlertMessage}
                                setErrorMessage={setErrorMessage}
                                onCloseAlert={onCloseAlert}
                                notificationsRefreshed={notificationsRefreshed}
                              />,


    }
  }
  const icons = () => {
    return {
      'Tables Connections':<PersonPinIcon />,
      'Password Setting':<PersonPinIcon />
    }
  }

  useEffect(() => {
    if (!props.usersResponse && users.length === 0) {
      const timer = setTimeout(() => {
        setupUsers()

    }, 100);
    return () => clearTimeout(timer);
    }
  }, []);


  const onCloseAlert = () => {
    setAlertMessage('')
    setErrorMessage(false)

  }

  const unAuthResponse = async (response = null) => {
    setLoading(true)
    if ( currentUser && currentUser.jti) {
      const reAuthUser = await signIn({ jti: currentUser.jti });
      navigate('/')
    } else {
      if (response && (response.development_test_saml || response.developmentTestSaml) && response.env === 'development') {
        setTestSaml(response.development_test_saml)
      }

      setUnAuth(true)
    }
    setLoading(false)

  }



  const setupUsers = async () => {

    // setLoading(true)
    const  response = await getUsers({ token: props.token, unAuthCallback: unAuthResponse, clearToken: props.clearToken })
    if (!response || response.error) {
      unAuthResponse(response)
    } else {
      setUsers(response.users)
      setEdit(response.edit)
      setEditAll(response.edit.all)
      setRead(response.read.self)
      setReadAll(response.read.all)
      setEncrypt(response.encrypt)
      setEncryptAll(response.encryptAll)
      setCurrentUser(response.user)
      setNotifications(response.notifications)
      setLoggedInIp(response.loggedInIp)
      setUsersById(response.usersById)
      setEnv(response.env)
      setExcludedTabs(response.exclude)
      setCsrf(response.csrf)
      setIpermRoles(response.ipermRoles)
      setUnAuth(false)
    }
    setRefreshToggle(!refreshToggle)

    // setLoading(false)
  }

  const notificationsRefreshed = (newNotifications) => {
    setNotifications(newNotifications)
  }

  const changeUserRole = async (role) => {
    if (role && role !== currentUser.role) {
      setLoading(true)
      const response = await assumeRole({ token: props.token, role, unAuthCallback: unAuthResponse })
      const message = response.error ? response.error : response.success

      setAlertMessage(message)
      setErrorMessage(response.error)
      setLoading(false)
      setTimeout( () => {
        window.location.reload()
      }, 1000)
    }
  }



  const headerMenu = () => {
    return {
      'Assume User loggedIn Role': { items: ipermRoles.map((role) => role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()), onSelect: changeUserRole}
    }
  }
  const getSelectedHeader = () => {
    return currentUser
    ? { 'Assume User loggedIn Role': currentUser.role}
    :  {}
  }
  // { loading  && <IMLoader message={'Loading Main Users...'}/>}
  const headerTitle = currentUser ? `Internal Permissions - ${currentUser.role} Home Page` : `Internal Permissions - Home Page`
  return (<>
    {unAuth && !loading
      ? <SSOUnauth testSaml={testSaml}/>
      : <>
        {<IMHeader
            title={headerTitle}
            env={env}
            enableDevMenu={env !== 'production'}
            headerMenu={headerMenu()}
            selectedHeaderMenu={getSelectedHeader()}
            user={currentUser}
            allowedTesters={ALLOWED_TESTER}
            notifications={notifications}
            ipermRoles={ipermRoles}
        />}


         <TabsContainer>
           <React.StrictMode>
            {!loading && currentUser && excludedTabs && <SideExpandedTabs
                env={env}
                tabsTitles={tabsTitles()}
                icons={icons()}
                tabsVisibility={tabsVisibility()}
                tabsTitlesContent={tabsTitlesContent(props)}/>}
                {alertMessage &&
                    <IMAlerts
                      message={alertMessage}
                      onClose={onCloseAlert}
                      success={!errorMessage}
                      severity={errorMessage ? 'error' : 'success'}

                      error={errorMessage}/>}
            </React.StrictMode>

        </TabsContainer>
        </>
    }

  </>)

};
UserPermissions.propTypes = {
  token: PropTypes.string,
  clearToken: PropTypes.func.isRequired
};

export default UserPermissions;
