import axios from 'axios';
import { getHeaders, baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export async function getPgUsersCall() {
  try {
    const response = await axios.get(`${baseUrl}/database_access/pg_users`,{
       method: "GET",
       withCredentials: true,
         headers: getHeaders(),
       });
    return response;
  } catch (err) {
    return { err };

  }
}



export async function newPgUserCall({ email, dbUserName, defaultPermissions = false }) {
  try {
    const response  = await fetch(`${baseUrl}/database_access/db_user_accesses/new`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify({ db_username: dbUserName, default_permissions: defaultPermissions, email })
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };
  }
}


export async function syncDBSecretCall(secretKey, newRolesSync, defaultRolesIds) {
  try {
    const response  = await fetch(`${baseUrl}/database_access/db_accesses/new`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify({ secret_key_name: secretKey, roles: newRolesSync, default_roles: defaultRolesIds })
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };
  }
}
export async function updateDBPasswordCall({ user, password }) {
  const data = { new_password: password }
  try {
    const response  = await fetch(`${baseUrl}/database_access/update_pg_user_password/${user.id}`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { err };
  }
}

// export async function getPgDbUsersRolesCall(pgUser) {
//   try {
//     const response = await axios.get(`${baseUrl}/database_access/pg_db_users_roles/read/${pgUser.name}`,{
//        method: "GET",
//        withCredentials: true,
//          headers: getHeaders(),
//        });
//
//     return response;
//   } catch (err) {
//     console.log('ERROR ', err)
//     return { err };
//
//   }
// }

export async function updateUserDBRolesCall({ pgUser, data }) {
  try {

    const response  = await fetch(`${baseUrl}/database_access/pg_db_users_roles/edit/${pgUser.name}`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };
  }
}

export async function  updateDbUsersPermissionsCall({updatedPermissions, dbName}) {
  try {
    let data = {}
    Object.keys(updatedPermissions).forEach((item, i) => {
      console.log('UPADTED ', updatedPermissions[item], JSON.stringify(updatedPermissions[item]))
      data[item] = Object.keys(updatedPermissions[item])
    });
    console.log('updateDbUsersPermissionsCall', updatedPermissions, JSON.stringify({ updated_permissions: data }))

    const response  = await fetch(`${baseUrl}/database_access/db_user_accesses/edit`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify({ updated_permissions: data, db_name: dbName })
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };
  }
}

export async function syncDBUsersRolesCall(syncUsers) {
  try {

    const response  = await fetch(`${baseUrl}/database_access/db_user_accesses/sync`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify({ users: syncUsers })
       }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };
  }
}

export async function getPgDbUsersAccessCall({me = false, dbName = '', userId = ''}) {
  try {
    const response = await fetch(`${baseUrl}/database_access/db_user_accesses/read/${dbName}`,{
      method: "POST",
      credentials: 'include',
        headers: getHeaders(),
        body: JSON.stringify({ me, db_name: dbName, user_id: userId })
      })
      const responseBack = await response.json()
      // console.log('updateUserDBRolesCall', responseBack)

      return responseBack
  } catch (err) {
    console.log('ERROR ', err)
    return { err };

  }
}

export async function getPgDbApplicationAccessCall(imApplicationName) {
  try {
    const response = await axios.get(`${baseUrl}/database_access/db_im_applications/read/${imApplicationName}`,{
       method: "GET",
       withCredentials: true,
         headers: getHeaders(),
       });

    return response;
  } catch (err) {
    console.log('ERROR ', err)
    return { err };

  }
}

export async function updateApplicationDBRolesCall({ pgUser, data }) {
  try {

    const response  = await fetch(`${baseUrl}/database_access/db_im_applications/edit/${pgUser.dbUsername}`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify(data)
       }
    )
    const responseBack = await response.json()
    // console.log('updateUserDBRolesCall', responseBack)

    return responseBack
  } catch (err) {
    return { err };
  }
}

export async function editDBRolesCall({dbName, roles, defaults}) {
  try {
    const response = await fetch(`${baseUrl}/database_access/db_accesses/edit/${dbName}`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify({ roles, default_roles: defaults })
       }
    )
    const responseBack = await response.json()
    // console.log('updateUserDBRolesCall', responseBack)

    return responseBack
  } catch (err) {
    console.log('ERROR ', err)
    return { err };

  }
}
export async function getDBDetailsCall({dbName, detailed}) {
  try {
    const response = await fetch(`${baseUrl}/database_access/db_accesses/read/${dbName}`,{
       method: "POST",
       credentials: 'include',
         headers: getHeaders(),
         body: JSON.stringify({detailed})
       }
    )
    const responseBack = await response.json()
    // console.log('updateUserDBRolesCall', responseBack)

    return responseBack
  } catch (err) {
    console.log('ERROR ', err)
    return { err };

  }
}

export async function getAllDBDetailsCall({detailed}) {
  try {
    const response = await fetch(`${baseUrl}/database_access/db_accesses/read/`,{
      method: "POST",
      credentials: 'include',
        headers: getHeaders(),
        body: JSON.stringify({detailed})
      }
   )
   const responseBack = await response.json()
   // console.log('updateUserDBRolesCall', responseBack)

   return responseBack
  } catch (err) {
    console.log('ERROR ', err)
    return { err };

  }
}
