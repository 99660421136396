import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import ReactiveButton from 'reactive-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faThumbsUp, faBomb, faPlus } from '@fortawesome/free-solid-svg-icons'

const BtnContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex !important',
  justifyContent: 'space-between',
}))
export default function IMReactiveBtn(props) {
  const [ btnState, setBtnState ] = useState('idle')

  const onClickHandler = async () => {
    if (!props.disableIdle) {
      setBtnState('loading')
    }
    const success  = await props.onClick(props.title)
    const nextState = success ? 'success' : 'error'

    if (!props.disableIdle) {
      setBtnState(nextState);

     setTimeout(() => {
       setBtnState('idle');
     }, 2000);
   }
  }
  return (
    <Box >

      <ReactiveButton
         key={`btnAwesomeReactBtn${props.title}`}
         buttonState={btnState}
         onClick={onClickHandler}
         color={props.colorBtnType}
         idleText={
            <BtnContainer>
              <Box sx={{marginLeft: '4px', display: 'flex', fontWeight: 700, fontSize: 25}}>
                <img src={props.icon} />
                <span> {props.icon ? '' : props.title}</span>
              </Box>
            </BtnContainer>
          }
         loadingText={
           <>
             <FontAwesomeIcon icon={faCircleNotch} spin /> Loading
           </>
         }
         successText={
           <>
             <FontAwesomeIcon icon={faThumbsUp} /> Success
           </>
         }
         errorText={
           <>
             <FontAwesomeIcon icon={faBomb} /> Error
           </>
         }
         type={'button'}
         style={{
           borderRadius: '5px',
           height: 44

         }}
         outline={false}
         shadow={true}
         rounded={props.rounded}
         size={props.size || 'large'}
         block={false}
         messageDuration={2000}
         disabled={false}
         buttonRef={null}
         width={props.width || null}
         height={props.height || 70}
         animation={true}
       />

    </Box>
  );
}
