import axios from 'axios';
import { baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;



export async function signInCall({ jti }) {

  try {

    const response  = await fetch(`${baseUrl}/sign_in`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
       body: JSON.stringify({
         user: jti
       })
      }
    )

    return response
  } catch (err) {
    return { err };

  }
}
export async function signSamlICall({jwt}) {
  try {
    const response  = await fetch(`${baseUrl}/saml/signin_saml`,{

      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      // credentials: 'include',
      headers: {
        // 'Authorization': sessionStorage.getItem("token"),
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
       body: JSON.stringify({
         jwt
       })
      }
    )
    const responseBack = await response.json()
    if (responseBack.message === 'success') {
      const res = await signInCall({ jti: responseBack.jti })
      return res
    }
    return { status: 401 }


  } catch (err) {
    return { err };

  }
}
