import { fetchResourcesPermissionsCall, updatePermissionsRolesCall } from '../api/InternalConfigs'
import { toCamelCase, camelToUnderscore } from './Utils'

function processParamsToServer(params) {
  let paramsToServer = {}
  Object.keys(params).forEach((key) => {
    paramsToServer[camelToUnderscore(key)] = params[key]
  })
  return paramsToServer;
}

const processResponse = (responseData) => {
  let user = {}
  try {
    if (!responseData || responseData == null) {
      return null
    }

     Object.keys(responseData).forEach((key) => {

       if ( typeof responseData[key] === 'number' ||
             typeof responseData[key] === 'string' ||
               typeof responseData[key] === 'boolean') {

           user[toCamelCase(key)] = responseData[key]
         } else {

           if (Object.keys(responseData[key]) &&  Object.keys(responseData[key]).length > 0
             &&  Object.keys(responseData[key])[0] === '0') {
             let arr = []
             responseData[key].forEach((item) => arr.push(processResponse(item)))

             user[toCamelCase(key)] = arr
           } else {
             user[toCamelCase(key)] = processResponse(responseData[key])
           }
         }
     })
     return user;
   } catch (err) {
     console.log('ERROR ???? ', err)
      return user;
   }
}

export const fetchResourcesPermissions = async () => {
  const response = await fetchResourcesPermissionsCall();
  if (response.status === 200 && response.data ) {
    if (response.data && !response.data.error) {
      const responseProcessed = processResponse(response.data)
      return responseProcessed
    } else {
      return response.data
    }
  } else {
    console.log('ERROR', response)
    return {error: `ERROR! ${response}`};
  }
}

export const updatePermissionsRoles = async ({ resourcesPermissions, token }) => {
  let resources = []
  resourcesPermissions.forEach((res) => resources.push(processParamsToServer(res)))
  const response = await updatePermissionsRolesCall({ resourcesPermissions: resources, token });
  // console.log('RESPONSE', response)

  if (!response.error ) {
    // if (response.resources_permissions) {
      const responseProcessed = processResponse(response.resources_permissions)
      return { success: `Roles successfully updated`, ...responseProcessed };
    // }
  } else {
    console.log('ERROR', response)
    return {error: `ERROR! ${response.message}`, message: 'Failed to update'};
  }
}
