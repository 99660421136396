import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { setHeaderTitle, headerColor } from '../Utils'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { grey, orange, green, blueGrey, teal, red, yellow, purple, pink, deepPurple, indigo, amber, lightBlue, cyan, blue } from '@mui/material/colors';
const MAIN_COLOR = indigo

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: props => props.backgroundColor,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}));



export default function SingleUserPermissions(props) {
  const [ permissions, setPermissions ] = useState(props.permissions)
  const [ permissionsGroupedHeaders, setPermissionsGroupedHeaders ] = useState(null)
  const [ pending, setPending ] = useState(null)

  useEffect(() => {

    if (!permissionsGroupedHeaders) {
      const tooltipMessage = permissions['pending'] === true && permissions['messages']
            ? 'Permissions needs to by synched with AWS. Please contact admin for support'
            : ''
      setPending(tooltipMessage)
      let headers = []
      props.permissionsGroupedHeaders.forEach((header, i) =>
        props.permissionsGroupedHeaders[i][Object.keys(header)[0]].forEach((group) =>{
         if (permissions[group] === true) {
           if (!headers[Object.keys(header)[0]]) {
             headers[Object.keys(header)[0]] = []
           }
           headers[Object.keys(header)[0]].push(group)
         }}
        )
      )
      setPermissionsGroupedHeaders(headers)
    }
  })
  return (
    permissionsGroupedHeaders && <Box sx={{ flexGrow: 1, p: 2 }}>
    {pending && <Box sx={{ flexGrow: 1, p: 2 }}>
        <Typography variant="body2" gutterBottom sx={{lineHeight: '20px', textAlign: 'left', color: orange[900]}}>
          {pending}
        </Typography>

        </Box>
    }
    <Grid
      container
      columns={3}
      sx={{
        '--Grid-borderWidth': '1px',
        borderTop: 'var(--Grid-borderWidth) solid',
        borderLeft: 'var(--Grid-borderWidth) solid',
        borderColor: 'divider',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
      }}
    >

       {Object.keys(permissionsGroupedHeaders).map((header, i) =>


        <Grid container >
         <Grid item xs={4} sx={{ backgroundColor: MAIN_COLOR[100], lineHeight: permissionsGroupedHeaders[header].length === 0 ? '50px' : `${50 * permissionsGroupedHeaders[header].length}px` }}>
          {  setHeaderTitle(header)}
          </Grid>

          <Grid container item xs={8} >
            { header !== 'pending'
              && (permissionsGroupedHeaders[header]).map((permGroup) =>
              <Grid container xs={12} sx={{backgroundColor: headerColor(MAIN_COLOR).primary[i]}}>
                <Grid item xs={6} sx={{lineHeight: '50px'}}>
                  {setHeaderTitle(permGroup)}
                </Grid>
                <Grid item xs={6} sx={{lineHeight: '50px'}}>
                  {permissions[permGroup] === true
                     ? <IconButton aria-label="CheckIcon">
                        <CheckIcon  sx={{ color: blueGrey[900] }}/>
                      </IconButton>
                     : <IconButton aria-label="CloseIcon">
                        <CloseIcon   sx={{ color: blueGrey[300] }}/>
                      </IconButton>
                  }
                </Grid>
              </Grid>

            )}
          </Grid>

        </Grid>

      )}
      </Grid>
    </Box>



  );
}
