import React, { useEffect, useState } from 'react';
import AsyncSearch from '../../common/AsyncSearch'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function NewPGUser(props) {

  const [ selectedUser, setSelectedUser ] = useState(null)
  const [ dbUsername, setDbUsername ] =  useState(null)
  const [ defaultPermissions, setDefaultPermissions ] = useState(null)
  const [ valid, setValid ] = useState(true)

  const usersOptions = () => {
    if (!props.users) {
      return []
    } else {
      let fiteredUesrs = props.users.filter((user) => user.role && user.role !== 'unauthorized' && !user.disabled)

      let arr = fiteredUesrs.map((user) => ({ id: user.id, option: user, value: `${user.firstName} ${user.lastName} - ${user.email}`, groupBy: user.role || 'unauthorized'}))

      return arr.sort( (a, b) => a.groupBy.localeCompare(b.groupBy, 'en', {'sensitivity': 'base'}));

    }
  }

  const onClear = () => {
    setSelectedUser(null)
    setDbUsername('')
  }

  const onSelectUser = (user) => {
    setSelectedUser(user)
    const newDBUserName = `${user.firstName.toLowerCase()}_${user.lastName.toLowerCase()}`
    setValid(/^[a-z]+_*[a-z]+_*[a-z]+$/.test(newDBUserName))
    props.onChange({ dbUserName: newDBUserName,  user: user })
    setDbUsername(newDBUserName)
  }


  const dbUserNameChanged = (event) => {
    const newName = event.target.value
    setValid(/^[a-z]+_*[a-z]+_*[a-z]+$/.test(newName))
    props.onChange({ dbUserName: newName, user: selectedUser  })
    setDbUsername(newName)
  }

  const defualtPermissionsCheck = (event) => {
    const defaultP = event.target.checked
     setDefaultPermissions(defaultP)
     props.onChange({defualtPermissions: defaultP})
  }

  return (
    <Box sx={{ width: '500px',
        '& .MuiPaper-root': {
        backgroundColor: 'white'}
      }}>
      <Grid container spacing={3} sx={{marginTop: 1}}>
        <Grid item xs={12}>
          <AsyncSearch
            key={`asyncSearchForUser`}
            width={'100%'}
            onClear={onClear}
            onSelected={onSelectUser}
            options={usersOptions()}
            searchTitle={'Search User with missing DB username'}/>
        </Grid>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-around'}}>
          <FormControl variant="standard">
            <TextField
               error={!valid}
               disabled={!selectedUser}
               key={'dbUsername'}
               id="dbUsername-required"
               onChange={dbUserNameChanged}
               value={dbUsername || ''}
               variant="standard"
               sx={{color: "green", fontWeight: 700}}
             />
             <FormHelperText id="component-helper-text">
             {valid ? 'DB Username' : 'A Valid DB User name must be snake case'}
           </FormHelperText>
         </FormControl>
         <FormControl variant="standard">
           <FormControlLabel control={<Checkbox disabled={!selectedUser || !selectedUser.role || selectedUser.role === 'unauthorized'} checked={defaultPermissions} onChange={defualtPermissionsCheck}/>} label="Set With Default DB Permissions" />
         </FormControl>
       </Grid>
      </Grid>
    </Box >

  )
}
