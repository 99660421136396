import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import IMLoader from '../common/IMLoader'
import {
  useLocation,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import { signSamlIn } from '../../models/Auth'
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
        padding: '55px'
    },
  },
  login: {
    justifyContent: 'center',
    minHeight: '90vh',
    padding: '55px'
  },
  btnBlock: {
    width: '100%'
  },
  loginBackground: {

    justifyContent: 'center',
    // minHeight: '30vh',
    // padding: '50px'
  }

}));

function SamlSignIn({token, setToken, clearToken, jwt, callbackSaml, callBackLoggedIn}) {

  const [ loading, setLoading ] = useState(true);
  const [ mount, setMount ] = useState(false);
  const [currentJWT, setCurrentJWT] = useState('')
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const signIn = async (jwt) => {
    if (!jwt || mount) return;
    const response = await signSamlIn({jti: jwt});
    if (response.status === 200) {
      const newToken = response.headers.get("Authorization");
      setToken(newToken);
      navigate('/');

    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const jwt = queryParams.get('jwt')
    if (!mount) {
      setMount(true)
      if (jwt && currentJWT !== jwt) {
        setCurrentJWT(jwt);
        signIn(jwt);

      }
    }

  })

  return (
    <div className={classes.root}>
      <IMLoader message={'Signing In'}/>
    </div>
  );
}

SamlSignIn.propTypes = {
  token: PropTypes.string,
  setToken: PropTypes.func.isRequired,
  jwt: PropTypes.string,
  callbackSaml: PropTypes.func.isRequired,
  callBackLoggedIn: PropTypes.func.isRequired,
};

export default SamlSignIn;
