import { getUsersCall, editUserCall, addUserCall, deleteUserCall, fetchUserPermissionsCall, updatePermissions, assumeRoleCall } from '../api/UserAccess'
import { toCamelCase, camelToUnderscore, processParamsToServer, processResponse as processResponseUtil } from './Utils'

export const processUserResponse = (userResponse) => {
  let user = {}
   Object.keys(userResponse).forEach((key) => {

     if (typeof userResponse[key] === 'string' || typeof userResponse[key] === 'number' || typeof userResponse[key] === 'boolean' || userResponse[key] === 'string' || !userResponse[key] ) {

       user[toCamelCase(key)] = userResponse[key]
     } else {
       if (typeof userResponse[key] === 'object') {

         const arrayToCamel = userResponse[key].map((val) => toCamelCase(val))
         user[toCamelCase(key)] = arrayToCamel
       }

     }
   })
   return user;
}

const processIpSecurityGroupResponse = (ipSecurity, ipSecurityResponse = {}) => {
  if (!ipSecurity) {
    return {}
  }

  Object.keys(ipSecurity).forEach((key) => {

    if (typeof ipSecurity[key] === 'string' || typeof ipSecurity[key] === 'number' || typeof ipSecurity[key] === 'boolean') {
      ipSecurityResponse[toCamelCase(key)] = ipSecurity[key]
    }
    else {
      if (typeof ipSecurity[key] === 'array') {
        ipSecurityResponse[toCamelCase(key)]  = []
        ipSecurity[key].forEach((item, i) => {
          ipSecurityResponse[toCamelCase(key)][i] = processIpSecurityGroupResponse(item, ipSecurityResponse[toCamelCase(key)][i])
        })

      } else {
        ipSecurityResponse[toCamelCase(key)]  = {}
        ipSecurityResponse[toCamelCase(key)] = processIpSecurityGroupResponse(ipSecurity[key], ipSecurityResponse[toCamelCase(key)])

      }
    }
  })

  return ipSecurityResponse;

}
const processResponse = (response) => {
  let responseBack = {}
  Object.keys(response).forEach(res => {
    responseBack[res] = []
    if (Object.keys(response[res]).length === 0) {
      responseBack[res] = []
    } else {
      (response[res]).forEach(item => {
        responseBack[res].push(processIpSecurityGroupResponse(item, {}))
      })
    }
  })
  return responseBack
}
const processPermissionsCallback = (permissionsResponse, message = '') => {
  let permissions = []
  let permissionsGroup = {}
  let allPermissions = {}
  let securityInfo = {}

  if (!permissionsResponse || !permissionsResponse.all) {
    return { permissions, permissionsGroup, allPermissions, securityInfo }
  }

  if (permissionsResponse.all) {
    permissionsResponse.all.forEach((perm) => {
      if (!allPermissions[perm.permission_name]) {
        allPermissions[perm.permission_name] = {}
        allPermissions[perm.permission_name]['granted'] = false
        allPermissions[perm.permission_name]['permissions'] = []
        allPermissions[perm.permission_name]['securityInfo'] = {}

      }
      allPermissions[perm.permission_name]['permissions'].push(perm.ip_permission_group.group_name)
      securityInfo[perm.ip_permission_group.group_name] = processIpSecurityGroupResponse(perm.ip_permission_group.ip_security_group)
    })
  }


  if (permissionsResponse.permissions) {
    permissionsResponse.permissions.forEach((permission) => {
      const clientPermission = {
        id: permission.id,
        granted: permission.granted,
        groupName: permission.group_permission.ip_permission_group.group_name,
        permissionName: permission.group_permission.permission_name,
        securityGroup: permission.group_permission.ip_permission_group.ip_security_group
      }
      if (!permissionsGroup[permission.group_permission.permission_name]) {
        permissionsGroup[permission.group_permission.permission_name] = {}
        permissionsGroup[permission.group_permission.permission_name]['permissions'] = []
      }
      allPermissions[permission.group_permission.permission_name]['granted'] = permission.granted
      permissionsGroup[permission.group_permission.permission_name]['permissions'].push(permission.group_permission.ip_permission_group.group_name)
      permissionsGroup[permission.group_permission.permission_name]['granted'] = permission.granted
      permissions.push(clientPermission)
    })
  }


  const user = processUserResponse(permissionsResponse.user)
  const edit = permissionsResponse.edit
  const read = permissionsResponse.read
  const encrypt = permissionsResponse.encrypt
  const allUsersPermissions = processResponse(permissionsResponse.all_users_permissions)
  return { permissions, permissionsGroup, allPermissions, securityInfo, user, edit, success: message, allUsersPermissions, read, encrypt };
}

export async function updatePermissionsCall({ newPermissions, token }) {
  const response = await updatePermissions({ newPermissions, token });
  if (response.status === 200) {
    const message =  `User successfully updated`
    return processPermissionsCallback(response, message)

  } else {
    return { error: `ERROR! ${response.error || 'Failed to update'}` };
  }

}

export async function fetchUserPermissions({ token, unAuthCallback }) {
  const response = await fetchUserPermissionsCall({ token });
  // console.log('PERMISSIONS RESPONSE', response)

  if (response.status === 200 && response.data ) {
    const data = response.data
    if (data.permissions ) {
      return processPermissionsCallback(data)
    } else {
      if (data.status == 401) {
        return  { error: data.message || 'Unauthorized' }

      }
    }
  } else {

    return { error: `ERROR! ${response.error || 'Failed to retrieve'}` };
  }
}


export async function assumeRole({token, role, unAuthCallback}) {
  const response = await assumeRoleCall({token, role: role.split(' ').join('_').toLowerCase()});
  if (response.status === 401 || response.err ) {
    unAuthCallback()
    return ;
  }
  if (response.status === 200  ) {
    return { success: `User successfully updated`, user: response.user };

  } else {
    return { error: `ERROR! ${response.error || 'Failed to update'}` };
  }
}
export async function editUser(params) {
  const paramsToServer = processParamsToServer(params)
  // console.log('EDIT USER CALL ', params)

  const response = await editUserCall(paramsToServer);

  if (response.status === 200  ) {
    return { success: `User successfully updated` };

  } else {
    return { error: `ERROR! ${response.error || 'Failed to update'}` };
  }
}

export async function addUser(params) {
  const paramsToServer = processParamsToServer(params)
  const response = await addUserCall(paramsToServer);
  if (response.status === 200  && response.users) {
    let users = []
    response.users.forEach((user) => users.push(processUserResponse(user)))
    return { success: `User successfully Added`, users };

  } else {
    return { error: `ERROR! ${response.error || 'Failed to Add user'}` };
  }
}

export async function deleteUser({ id, token }) {
  const response = await deleteUserCall({ id, token });
  if (response.status === 200  && response.users) {
    let users = []
    response.users.forEach((user) => users.push(processUserResponse(user)))
    return { success: `User successfully Deleted`, users };
  } else {
    return { error: `ERROR! ${response.error || 'Failed to Delete user'}` };
  }
}

export async function getUsers({ unAuthCallback, clearToken }) {

  const response = await getUsersCall();
  if (response.status === 500 ) {

    return response;
  }
  clearToken();

  let usersById = {}
  let responseBack = {}
  const res = Object.assign({}, response)
  if (res.users) {
    Object.keys(res).forEach((resKey) => {

        if (resKey === 'users') {
          const users = Object.assign({}, res.users)

          responseBack['users'] = []
          Object.keys(users).forEach((user, i) => {

            const userBack = processUserResponse(users[user])
            usersById[users[user].id] = userBack
            responseBack[resKey].push(userBack)
          })
        } else if (resKey === 'user') {
          responseBack['user'] = processUserResponse(res['user'])
        }  else {
          responseBack[toCamelCase(resKey)] = typeof res[resKey] === 'object'
            ? processResponseUtil(res[resKey])
            : res[resKey]
        }
      }
    )
    responseBack['usersById'] = usersById
    // console.log('RE SPONSE ', responseBack)

    return responseBack;


  } else {
    console.log('ERROR', response)
    return response

  }
}
