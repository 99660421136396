import axios from 'axios';
import { getHeaders, baseUrl } from './utils';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export async function getUserNotificationsCall({token}) {
  try {
    const responseBack = await axios.get(`${baseUrl}/user_notifications/`,{
       method: "GET",
       withCredentials: true,
         headers: getHeaders(),
       });

    return responseBack.data
  } catch (err) {
    return { err };

  }
}
