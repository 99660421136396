import * as React from 'react';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { indigo } from '@mui/material/colors';
import { setHeaderTitle, headerColor } from '../common/utils'

const MAIN_COLOR = indigo

export default function UserForm(props) {

  const [ fields, setFields ] = React.useState(props.fields);
  const [ defaultFieldsValues, setDefaultFieldsValues ] = React.useState(props.setDefaultFieldsValues);

  const [ fieldsValues, setFieldsValues ] = React.useState(props.fieldsValues);
  const [ refresh, setRefresh ] = React.useState(props.refresh)
  const [ focused, setFocused ] = React.useState(props.fields[0])
  const [ nonEditable, setNonEditable ] = React.useState(props.nonEditable || [])

  const Item = styled(Paper)(({ theme }) => props => ({
    backgroundColor: `${headerColor(MAIN_COLOR).primary[props.index]} !important`,
    // ba ckgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    margin: 5,
    color: theme.palette.text.secondary,
    fontWeight: 700
  }));

  React.useEffect(() => {

    let change =  fields && fields.length > 0 ? fields.filter((field) => fieldsValues[field] !== props.fieldsValues[field]) : false

    if (change && change.length > 0 && props.refresh !== refresh){

      setRefresh(props.refresh)
      setFieldsValues(props.fieldsValues)
      setDefaultFieldsValues(props.defaultFieldsValues)
    }


  })



  const handleChange = (name) => (event) => {
    const newName = event.target.value

    let newName2 = (typeof fieldsValues[name] === 'boolean')
        ? newName === 'true' ? true : false
        : newName
    const updatedFieldsValues = Object.assign({}, fieldsValues)
    updatedFieldsValues[name] = newName2
    setFieldsValues(updatedFieldsValues)
    setFocused(name)
    // if (props.onFieldChange) {
    //   props.onFieldChange(name, newName, updatedFieldsValues)
    // }
  };
  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(fieldsValues)
    }
  }

  const setHeaderTitle = (header) => {
    return header.split(/(?=[A-Z])/).map((word) => word = word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
  }

  const getFieldValue = (field) => {

    return fieldsValues[field]
  }
  const disableSubmit = () => {
    let disable = false

    if (fields.length > 0) {

      if (props.invalidOptions && Object.keys(props.invalidOptions).length > 0) {
        fields.forEach((field) => {
          disable = disable || props.invalidOptions[field] && props.invalidOptions[field]['values'].includes(fieldsValues[field])
        })
      }
    }
    return disable
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        fontWeight: 700,
        '& > :not(style)': {
          m: 1,
          // width: 128,
          // height: 128,
        },
      }}
    >

        <Paper elevation={3}>
          <Paper elevation={0}>
            {props.description}
          </Paper>
          <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '32ch' },
              }}
              noValidate
              autoComplete="off"
            >
            { <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                { fields && fields.map((field, index) => (
                  <Grid item xs={2} sm={4} md={4} key={`${field}GridKey`} >
                    <Item index={index} elevation={3}>
                    {(typeof fieldsValues[field] === 'boolean')
                     ? <TextField

                        key={`${field}txt-key`}
                        select
                        disabled={nonEditable.includes(field.toLowerCase()) }
                        label={setHeaderTitle(field)}
                        value={fieldsValues[field]}
                        onChange={handleChange(field)}
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                      >
                        {[{
                              value: 'true',
                              label: 'TRUE',
                            },
                            {
                              value: 'false',
                              label: 'FALSE',
                            }].map((option) => (
                              <option key={`key${option.label}${field}`} value={option.value}>
                                {option.label}
                              </option>
                        ))}
                        </TextField>
                         : props.lookupOptions && props.lookupOptions[field]
                         ? <TextField

                            key={`${field}txt-key`}
                            select
                            disabled={nonEditable.includes(field.toLowerCase()) }
                            label={setHeaderTitle(field)}
                            value={fieldsValues[field] || ''}
                            onChange={handleChange(field)}
                            SelectProps={{
                              native: true,
                            }}
                            variant="standard"
                          >
                             <option aria-label="None" value="" />
                            {props.lookupOptions[field].map((option) => (
                                  !option.disabled && option.label && <option key={`key${option.label}${field}`} value={option.value}>
                                    {option.label}
                                  </option>
                            ))}
                          </TextField>
                         : <TextField
                               autoFocus={focused === field}
                               key={`${field}txt-key`}
                               disabled={nonEditable.includes(field) }
                               error={props.invalidOptions && props.invalidOptions[field] && props.invalidOptions[field]['values'].includes(fieldsValues[field])}
                               label={props.invalidOptions && props.invalidOptions[field] && props.invalidOptions[field]['values'].includes(fieldsValues[field]) ? props.invalidOptions[field]['message'] : setHeaderTitle(field)}
                               value={fieldsValues[field] || ''}
                               onChange={handleChange(field)}
                               variant="standard"
                             />
                       }
                    </Item>
                  </Grid>
                ))}
              </Grid>
            }

          </Box>
        </Paper>
        <Paper elevation={2}>

          <Button variant="outlined"
                disabled={disableSubmit()}
                color="success"
                onClick={handleSubmit}>{props.submitlTitle || 'Submit'}</Button>
        </Paper>
    </Box>
  );
}
