import React, { useEffect, useState } from 'react';
import { getPgDbUsersAccess, getDBDetails, syncDBSecret, editDBRoles } from '../../../models/DatabaseAccess'
import IMLoader from '../../common/IMLoader'
import Paper from '@mui/material/Paper';
import AsyncSearch from '../../common/AsyncSearch'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import Fab from '@mui/material/Fab';

import { AwesomeButton } from 'react-awesome-button';
import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss';
import PGSrc from '../../../assets/db-add-icon.png';
import Button from '@mui/material/Button';

import DBInfo from './DBInfo'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactiveButton from 'reactive-button';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IMAlerts from '../../common/IMAlerts'

import NewEditDBForm from './NewEditDBForm'

const BtnContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}))
export default function DBConfigs(props) {

  const [ loading, setLoading ] = useState(false)
  const [ selectedDbsDetails, setSelectedDbsDetails ] = useState({})
  const [ detailedDBs, setDetailedDBs ] = useState(props.dbDetails)
  const [ open, setOpen ] = React.useState(-1);
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ newDBKeyName, setNewDBKeyName ] = useState(null)
  const [ syncBackground, setSyncBackground ] = useState(false)
  const [ newRolesSync, setNewRolesSync ] = useState(null)
  const [ defaultRoles, setDefaultRoles ] = useState(null)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ infoMessage, setInfoMessage ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState(false)
  const [ dbsOptions, setDbsOptions ] = useState(props.dbsOptions)
  const [ refreshDbs, setRefreshDbs ] = useState(false)

  const syncNewDB = async () => {
    setSyncBackground(true)
  }

  const handleCloseSyncBackground = () => {
    setSyncBackground(false)
  }
  const openNewDBDialog = () => {
    setDialogProps({
      title: 'Sync New DB',
      handleSubmit: syncNewDB,
      content: () => <NewEditDBForm
        env={props.env}
        newRolesSync={setNewRolesSync}
        dbNameChange={setNewDBKeyName}
        defaultRoles={setDefaultRoles}
        dbRoles={props.dbRoles}/>,
      titleIcon: () => <CloudSyncIcon />
    })
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(-1);
    setDialogProps(null)
    setNewDBKeyName(null)
    setNewRolesSync(null)
  };

  const performSync = async (newDBKeyName, newRolesSync) => {
    if (!newRolesSync || newRolesSync.length === 0 || !newDBKeyName ) {
      setSyncBackground(false)
      return
    }

    const rolesIds = newRolesSync.map((role) => role.id)
    let defaultRolesIds = []
    if (defaultRoles && defaultRoles.length > 0) {
      const filtered = props.dbRoles.filter((dbRole) =>  defaultRoles.includes(dbRole.userRole))
      defaultRolesIds = filtered.map((role) => role.id)
    }


    const response  = await syncDBSecret(newDBKeyName, rolesIds, defaultRolesIds)
    const message = response.error ? response.error : response.message

    if ( response && response.status === 200 ) {

      if (response.db && response.db.length > 0) {
        let newDetails = {}
        if (detailedDBs && Object.keys(detailedDBs).length > 0) {
          Object.keys(detailedDBs).forEach((db) => newDetails[db] = detailedDBs[db])
        }
        newDetails[response.db[0].dbName] = response.db[0].details
        const dbDetailsFetched = response.db[0].details
        let newDbs = dbsOptions
        const db = response.db[0].details.dbclusteridentifier

        newDbs.push({ id: `${db}Id`, value: `${db}`, groupBy: '' , option: db })
        setDbsOptions(newDbs)

        let updatedDbs = props.allDbs

        response.db.forEach((dbRole) => updatedDbs.push(dbRole))

        props.onFetchDB({ updatedDbs, dbsOptions })



        setDetailedDBs(newDetails)
        // setSelectedDb(null)
        setRefreshDbs(!refreshDbs)
      }
    } else {
      setErrorMessage(true)
    }
    handleClose()
    setSyncBackground(false)
    setAlertMessage(message)
  }
  useEffect(() => {

    if (props.dbDetails && (!detailedDBs
      || Object.keys(detailedDBs).length !== Object.keys(props.dbDetails).length) ) {
      setDetailedDBs(props.dbDetails)

    }
    if (syncBackground) {
      performSync( newDBKeyName, newRolesSync )
    }
  })
  const onClear = () => {
    // setSelectedDb(null)
  }

  const fetchSelectedDBDetails = async (dbName) => {
    setLoading(true)
    const response = await getDBDetails({ dbName: dbName, detailed: true })
    if (response.status === 200 && response.dbs && response.dbs.length > 0) {
      setDetailedDBs(response.dbs)
      let detailed = detailedDBs
      let selectedDbsDetailsUpdate = {}

      selectedDbsDetailsUpdate[response.dbs[0].dbName] = response.dbs[0].details
      detailed[response.dbs[0].dbName] = response.dbs[0].details

      setDetailedDBs(detailed)
      setSelectedDbsDetails(selectedDbsDetailsUpdate)
    } else {
      setLoading(false)
      props.unAuthCallback(response)
      setDetailedDBs(null)
    }
    setLoading(false)
  }

  const onSelectDb = (db) => {

    if (!detailedDBs || !detailedDBs[db]) {

      fetchSelectedDBDetails(db)

    } else {

      let newSelectedDbsDetails = {}
      Object.keys(detailedDBs).forEach((dbName) => {
        if (dbName.includes(db)) {
          newSelectedDbsDetails[dbName] = detailedDBs[dbName]
        }
      }
      )
      setSelectedDbsDetails(newSelectedDbsDetails)
    }
  }
  const onCloseAlert = () => {
    setAlertMessage('')
    setErrorMessage(false)

  }

  const confirmUpdateDb = async ({ newRoles, defaultRoles, dbUpdate }) => {
    setLoading(true)
    const response = await editDBRoles({dbName: dbUpdate.dbclusteridentifier, roles: newRoles, defaults: defaultRoles})

    const message = response.error ? response.error : response.message

    if (response && response.status === 200 && response.dbs) {
      setDetailedDBs(response.dbs)
      props.onUpdateDb(response.dbs)
      setRefreshDbs(!refreshDbs)
    } else {
      setErrorMessage(true)
    }
    setAlertMessage(message)
    setLoading(false)
  }

  return (
    <>
     {loading
      ? <IMLoader message={'Loading DB Details...'} />
      :  <Box sx={{
            '& .MuiPaper-root': {
            backgroundColor: 'white'}
          }}>
          {
            alertMessage &&
             <IMAlerts
               message={alertMessage}
               onClose={onCloseAlert}
               autoHideDuration={infoMessage ? 8000 : 6000}
               severity={errorMessage ? 'error' : infoMessage ? 'info' : 'success'}
             />
          }
          <Box sx={{ width: '100%', mb: 2, bgColor: 'white' }}>
            <Grid container spacing={0.5} sx={{alignItems: 'end'}}>

             <Grid item xs={10}>
              { !dialogProps && dbsOptions && <AsyncSearch
                 key={`asyncSearchForDB`}
                 width={'100%'}
                 refresh={refreshDbs}
                 onClear={onClear}
                 onSelected={onSelectDb}
                 options={dbsOptions}
                 searchTitle={'Search Database Access'}/> }
             </Grid>
             <Grid item xs={2} sx={{display: 'flex',
                                    height: '65px'}}>
             <ReactiveButton
              key={`addNewDbKetBtn`}
              buttonState={'idle'}
              onClick={openNewDBDialog}
              idleText={
                 <BtnContainer>
                   <img
                     srcSet={`${PGSrc}`}
                     src={`${PGSrc}`}
                     loading="lazy"
                     style={{width: '50px', height: '50px'}}
                   />
                 </BtnContainer>
               }
              type={'button'}
              style={{
                borderRadius: '5px',
                width: 'fit-content',

              }}
              outline={false}
              shadow={true}
              rounded={false}
              size={'normal'}
              block={false}
              messageDuration={2000}
              disabled={false}
              buttonRef={null}
              width={null}
              height={null}
              animation={true}
              outline
            />
             </Grid>
            </Grid>

            {
              detailedDBs
              ? <Grid container spacing={2}>
                  {
                  <Grid item xs={12} >
                    <DBInfo
                      dbs={selectedDbsDetails}
                      dbRoles={props.dbRoles}
                      confirmUpdateDb={confirmUpdateDb}
                    />
                  </Grid>
                }

              </Grid>
              : <></>
            }

          </Box>
          {dialogProps && <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              sx={{
                backgroundColor: '#24292e',
                color: 'white'
              }}
              id={dialogProps.title}>
              {dialogProps.title}
              {dialogProps.titleIcon && <Fab

               variant="standard" aria-label="add"
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: '40px',
                  height: '40px',
                  zIndex: 999999,
                  margin: '12px'
                }}>{dialogProps.titleIcon()}</Fab>}
            </DialogTitle>
            <DialogContent>
              {dialogProps.content()}
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={handleClose}>Cancel</Button>
              <Button color="primary" disabled={!newDBKeyName || !newRolesSync} onClick={dialogProps.handleSubmit} autoFocus>
                SYNC
              </Button>
            </DialogActions>
          </Dialog>}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={syncBackground}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        </Box>

    }
    </>
  )

}
