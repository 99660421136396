import {
   signInCall,
   signSamlICall } from '../api/Auth'
import { processUserResponse } from './UserAccess'
import { toCamelCase, camelToUnderscore, processParamsToServer, processResponse as processResponseUtil } from './Utils'

export async function signIn({ jti }) {

  const response = await signInCall({ jti });
  if (response ) {
    if (response && !response.error) {
      const newToken = response.headers.get("Authorization");
      // localStorage.setItem("token", newToken);
      return response
    } else {
        return { status: 401 }
    }
  } else {
    // console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}


export async function signSamlIn({ jti }) {

  const response = await signSamlICall({ jwt: jti });

  return response
}
