/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { fetchUserPermissions, updatePermissionsCall } from '../../../models/UserAccess'
import { signIn } from '../../../models/Auth'
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import styled from 'styled-components'
import LimitedPermissionPage from '../../common/LimitedPermissionPage'
import IMLoader from '../../common/IMLoader'
import UsersNetworkpermissionsTable from './UsersPermissions/UsersNetworkpermissionsTable'
import Settings from './GroupsConfigs/Settings'
import { blue } from '@mui/material/colors';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const Styles = styled.div`
  width: 95%;
  .MuiPaper-root {
    background-color: #fff !important
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

`
export default function UserNetworkNavigation(props) {

  const [ mount, setMount ] = useState(false);
  const [ token, setToken ] = useState(props.token);
  const [ userSetupped, setUserSetupped ] = useState(false);
  const [ edit, setEdit ] = useState(props.edit)
  const [ editAll, setEditAll ] = useState(props.editAll)
  const [ enableGroupsConfigs, setEnableGroupsConfigs ] = useState(false)
  const [ read, setRead ] = useState(false)
  const [ readAll, setReadAll ] = useState(false)
  const [ encrypt, setEncrypt ] = useState(props.encrypt)
  const [ encryptAll, setEncryptAll ] = useState(false)
  const [ user, setUser ] = useState(null)
  const [ permissions, setPermissions ] = useState(null)
  const [ permissionsGroup, setPermissionsGroup ] = useState(null)
  const [ allPermissions, setAllPermissions ] = useState(null)
  const [ securityInfo, setSecurityInfo ] = useState(null)
  const [ refreshToggle, setRefreshToggle ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ users, setUsers ] = useState(props.users)
  const [ usersById, setUsersById ] = useState(props.usersById)
  const [ allUsersPermissions, setAllUsersPermissions ] = useState(null)
  const [ usersPermissionsRows, setUsersPermissionsRows ] = useState([])
  const [ currentUser, setCurrentUser ] = useState(props.currentUser)
  const [ permissionsPending, setPermissionsPending ] = useState(false)
  const [ userDisbaledByEmail, setUserDisbaledByEmail ] = useState({})

  const [ tab, setTab ] = useState('1');


  const handleChangeTab = ( event, newValue ) => {
    setTab(newValue);
  };

  const unAuthResponse = async () => {
    setLoading(true)
    if ( window.location.hostname.includes('localhost')) {
      // navigate('/sign_in');
    } else {
      const reAuthUser = await signIn({ jti: currentUser.jti });
  //
    }
    setUserSetupped(true)
    setLoading(false)

  }


  const setupUsers = async ({ disableLoader }) => {

    const response = await fetchUserPermissions({ token, unAuthCallback: props.unAuthCallback })
    if (response.error) {
      if (response.error === 'unauthorized') {

      } else {
        props.unAuthCallback(response)

      }
    } else {
      setUser(response.user)
      setPermissions(response.permissions)
      setAllUsersPermissions(response.allUsersPermissions)
      setAllPermissions(response.allPermissions)
      setSecurityInfo(response.securityInfo)
      setPermissionsGroup(response.permissionsGroup)
      setEdit(response.edit.self)
      setEditAll(response.edit.all)
      setRead(response.read.self)
      setReadAll(response.read.all)
      setEncrypt(response.encrypt.self)
      setEncryptAll(response.encrypt.all)
      processUsersPermissionsRows({allUsersPermissions: response.allUsersPermissions, securityInfo: response.securityInfo})
      setRefreshToggle(!refreshToggle)
    }
    if (!disableLoader) {
      setLoading(false)
    }
    setUserSetupped(true)
  }


  const onSubmitNewPermissions = ({ newPermissions, allUsersPermissionsUpdated }) => {

    setAllUsersPermissions(allUsersPermissionsUpdated)
    setAllPermissions(newPermissions)
    processUsersPermissionsRows({allUsersPermissions: allUsersPermissionsUpdated, securityInfo})
    setRefreshToggle(!refreshToggle)
  }

  useEffect(() => {

    if (!mount) {
      setMount(true)
      setLoading(true)
      setupUsers({ disableLoader: false })
    }
  })

  const onEditPermissions = () => {
    setupUsers({ disableLoader: true })
  }

  const processUsersPermissionsRows = ({allUsersPermissions, securityInfo}) => {
    let usersPermissionsRows = []
    let disabled = {}
    if (!allUsersPermissions || Object.keys(allUsersPermissions).length === 0 || Object.keys(usersById).length === 0) {
      setUsersPermissionsRows(usersPermissionsRows)

      return
    }
    const headers = Object.keys(securityInfo)
    let callbackPermissionsPending = false
    Object.keys(allUsersPermissions).forEach((userId) => {
      let userPerm = {}
      userPerm['messages'] = []
      userPerm['pending'] = false
      userPerm['user'] = usersById[userId].email
      disabled[usersById[userId].email] = usersById[userId].disabled
      let pending = false
      const userPermissions = allUsersPermissions[userId]
      userPermissions.forEach((permission) => {
        if (headers.includes(permission.groupPermission.ipPermissionGroup.groupName)) {
          userPerm[permission.groupPermission.ipPermissionGroup.groupName] = permission.granted
          pending = pending || (permission.error !== null && permission.error.length > 0) || permission.awsPending
          if (permission.error) {
            userPerm['messages'].push({[permission.groupPermission.ipPermissionGroup.groupName]: permission.error ? permission.error : ''})
          }
        }

      })
      headers.forEach((item, i) => {
        if (!userPerm[item]) {
          userPerm[item] = false
        }
      });

      userPerm['pending'] = pending
      callbackPermissionsPending = callbackPermissionsPending || pending


      usersPermissionsRows.push(userPerm)
    })
    setPermissionsPending(callbackPermissionsPending)
    setUserDisbaledByEmail(disabled)
    setUsersPermissionsRows(usersPermissionsRows)
  }


  const permissionsGroupedHeaders = () => {
    if (!allPermissions) {
      return []
    }
    let groupedHeaders = []
    let header = {}

    header = {}
    header['pending'] = []
    groupedHeaders.push(header)
    header = {}
    header['user'] = []
    groupedHeaders.push(header)

    const sortedPermissions = Object.keys(allPermissions).sort()
    sortedPermissions.forEach((perm) => {
      header = {}
      header[perm] = allPermissions[perm].permissions
      groupedHeaders.push(header)
    })

    return groupedHeaders
  }

  const securityGroupTableHeaders = () => {
    securityInfo
      ? ['user', ...Object.keys(securityInfo)]
      : []
  }

  return (
    <div>


    {loading || !userSetupped
    ?  <Box sx={{marginTop: 15}}><IMLoader mini/></Box>
    :  usersPermissionsRows.length === 0 && userSetupped
      ?  <LimitedPermissionPage
            user={currentUser.name}
          />
      : <>
      {read && !readAll &&
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
             <VerifiedIcon sx={{ color: blue[500],fontSize: 40 }} />
            </Typography>
            <Typography variant="h5" component="div" sx={{marginBottom: 5}}>
                {"You have network access via your configured IP address to these network resources. "}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {"Please see your system administrator for details on how to connect. "}
            </Typography>
            <Typography variant="body2">
            {"If you are having difficulty connecting please verify that your current IP address matches what is on your account and update it if necessary."}
              <br />
            </Typography>
          </CardContent>
        </Card>
        }
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="permissions tabs">
                <Tab label="USER IP PERMISSIONS" value="1" />
                {edit && <Tab label="GROUP CONFIGS" value="2" />}
              </TabList>
            </Box>
            <TabPanel value="1"><UsersNetworkpermissionsTable

                refeshPermissions={setupUsers}
                edit={edit}
                read={read}
                readAll={readAll}
                editAll={editAll}
                encrypt={encrypt}
                encryptAll={encryptAll}
                permissionsGroup={permissionsGroup}
                allPermissions={allPermissions}
                securityInfo={securityInfo}
                allUsersPermissions={allUsersPermissions}
                rows={usersPermissionsRows}
                usersById={usersById}
                token={token}
                headers={securityGroupTableHeaders()}
                usersPermissionsRows={usersPermissionsRows}
                currentUser={currentUser}
                permissionsGroupedHeaders={permissionsGroupedHeaders()}
                refreshToggle={refreshToggle}
                permissionsPending={permissionsPending}
                userDisbaledByEmail={userDisbaledByEmail}
              /></TabPanel>
            <TabPanel value="2">
            {permissionsGroup &&   <Settings
                currentUser={currentUser}
                unAuthCallback={props.unAuthCallback}
                onEditPermissions={onEditPermissions}
              />}
            </TabPanel>
          </TabContext>
        </Box>

        </>
      }

    </div>
  );
}
